import React, { useState, useEffect } from 'react'
import { withRouter  } from 'react-router'
import { Range  } from 'rc-slider'
import 'rc-slider/assets/index.css'
import Button from '../../components/Button'
import PropertyResume from '../../components/PropertyResume'
import { numberWithCommas, useWindowSize  } from '../../common/utils/helpers'
import { destacados  } from '../../common/utils/api-helpers'
import { TABLET_WIDTH  } from '../../common/utils/constants'
import ItemsCarousel from 'react-items-carousel'
import Autocomplete from 'react-google-autocomplete';
import { COLORS } from '../../common/utils/constants';
import { formSubmission } from '../../common/utils/gtm';

import {
  Arrow,
  Bold,
  DestacadosWrapper,
  BudgetWrapper,
  FirstContentWrapper,
  NumbersWrapper,
  Number,
  HousingLandingWrapper,
  ImageCover,
  LandingImage,
  LandingTitle,
  Link,
  LinkWrapper,
  PlaceWrapper,
  PromotionWrapper,
  PromotionBackground,
  PromotionContent,
  PromotionList,
  QuestionWrapper,
  RangeWrapper,
  SearchButtonWrapper,
  TextHighlight,
  SelectionWrapper,
  Selection,
  MaxWidthWrapper,
  WhyElement,
  WhyImage,
  WhyText,
} from './styled'


const landing = require('../../common/images/landing.jpg')
const rightArrow = require('../../common/images/rightArrow.svg')
const property1 = require('../../common/images/property1.png')
const whyImage1 = require('../../common/images/why-image-1.svg')
const whyImage2 = require('../../common/images/why-image-2.svg')
const whyImage3 = require('../../common/images/why-image-3.svg')

const Landing = (props) => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100000);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [destacadosList, setDestacadosList] = useState([]);
  const [isPhone, setIsPhone] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [address, setAddress] = useState(null);
  const [categoryType, setCategoryType] = useState(1);
  const size = useWindowSize();
  const [addressError, setAdressError] = useState(false);

  useEffect(() => {
    destacados()
      .then(res => {
        setDestacadosList(res.data.result)
      })
      .catch(err => console.error(err))

  }, [])

  useEffect(() => {
    setIsPhone(size.width < TABLET_WIDTH ? true : false)
  }, [size])

  useEffect(() => {
    setMax(categoryType === 1 ? 100000 : 100000000)
  }, [categoryType])

  const searchProperties = () => {
    if(!address){
      setAdressError(true);
    }else{
      formSubmission({ address, min, max, categoryType })
      props.history.push({
        pathname: '/lista-propiedades',
        search: `?min=${min}&max=${max}&categoryType=${categoryType}&lat=${lat}&lng=${lng}&address=${address}`,
      })
    }
  }

  const changeRange = (range) => {
    setMin(range[0])
    setMax(range[1])
  }

  const propertyDetail = (id) =>{
    const location = {
      pathname: `/detalle/${id}`
    }
    props.history.push(location)
  }

  return(
    <>
      <FirstContentWrapper>
        <LandingImage src={landing}/>
        <ImageCover />
        <MaxWidthWrapper>
          <LandingTitle>
            La mejor forma de <TextHighlight>rentar o vender</TextHighlight> un inmueble.
          </LandingTitle>
          <QuestionWrapper>
            <SelectionWrapper>
              <Selection
                onClick={() => setCategoryType(1)}
                className={categoryType === 1 ? 'active' : 'inactive'}>
                Quiero rentar
              </Selection>
              <Selection
                onClick={() => setCategoryType(2)}
                className={categoryType === 2 ? 'active' : 'inactive'}>
                Quiero comprar
              </Selection>
              <Selection
                onClick={() => setCategoryType(3)}
                className={categoryType === 3 ? 'active' : 'inactive'}>
                Preventa
              </Selection>
            </SelectionWrapper>
            <PlaceWrapper>
              QUIERO VIVIR EN:
              <Autocomplete
                style={{
                  width: '100%',
                  fontFamily: 'Montserrat, sans-serif',
                  fontSize: 16,
                  padding: '10px 20px',
                  border: `solid 1px ${addressError ? '#FF3429' : '#000'}`,
                  marginTop: 10,
                  boxSizing: 'border-box',
                }}
                onPlaceSelected={(place) => {
                  setAddress(place.formatted_address)
                  setLat(place.geometry.location.lat())
                  setLng(place.geometry.location.lng())
                }}
                onFocus={() => setAdressError(false)}
                types={['address']}
                placeholder={'Introduce una dirección'}
                componentRestrictions={{country: "mx"}}
              />
            </PlaceWrapper>
            <BudgetWrapper>
              MI PRESUPUESTO ES DE:
              <NumbersWrapper>
                <Number>${numberWithCommas(min)}</Number>
                <Number>${numberWithCommas(max)}</Number>
              </NumbersWrapper>
              <RangeWrapper>
                <Range
                  min={0}
                  max={categoryType === 1 ? 100000 : 100000000}
                  value={[min, max]}
                  step={500}
                  onChange={changeRange}
                  railStyle={{ border: 'solid 2px #EEE' }}
                  handleStyle={[
                    { border: `solid 2px ${COLORS.darkBlue}`, width: '16px', height: '16px'  },
                    { border: `solid 2px ${COLORS.darkBlue}`, width: '16px', height: '16px'  }
                  ]}
                  trackStyle={[{ border: `solid 2px ${COLORS.darkBlue}` }]}/>
              </RangeWrapper>
            </BudgetWrapper>
            <SearchButtonWrapper>
              <Button onClick={searchProperties}>BUSCAR</Button>
            </SearchButtonWrapper>
            <LinkWrapper>
              <Link to={'/registrar-propiedad'}>Soy propietario, <TextHighlight>quiero anunciar</TextHighlight></Link>
            </LinkWrapper>
          </QuestionWrapper>
        </MaxWidthWrapper>
      </FirstContentWrapper>
      <HousingLandingWrapper>
        {destacadosList.length > 0 ? (
          <>
            <LandingTitle style={{ textAlign: 'center' }}>Alojamientos en <TextHighlight blue>México.</TextHighlight></LandingTitle>
            <DestacadosWrapper>
              <ItemsCarousel
                requestToChangeActive={(index) => {setActiveIndex(index)}}
                activeItemIndex={activeIndex}
                numberOfCards={isPhone ? 1 : 3}
                gutter={12}
                leftChevron={<Arrow src={rightArrow} inverted />}
                rightChevron={<Arrow src={rightArrow} />}
                chevronWidth={40}
                classes={{
                  itemsWrapper: 'itemsWrapper',
                  rightChevronWrapper: 'rightChevronWrapper',
                }}
              >
                {destacadosList.map(el => (
                  <PropertyResume
                    size={"home"}
                    precio={el.precio}
                    habitaciones={el.numeroHabitaciones}
                    banos={el.numeroBanos}
                    estacionamientos={el.numeroEstacionamiento}
                    mascotas={el.mascotas}
                    areaConstruida={el.areaConstruida}
                    onClick={() => propertyDetail(el.inmuebleID)}
                    image={el.mainImageURL || property1}
                    calle={el.calle}
                    numeroExterior={el.numeroExterior}
                    colonia={el.colonia}
                    ciudad={el.ciudad}
                    inmuebleID={el.inmuebleID}
                    isFavorite={el.favorito}
                  />
                ))}
              </ItemsCarousel>
            </DestacadosWrapper>
          </>
              ) : (
            <PromotionWrapper>
              <PromotionBackground />
              <PromotionContent>
                ¡ Registrate ahora y aparece aquí <Bold>completemente gratis </Bold>!
                <PromotionList>
                  <li> Crea tu cuenta</li>
                  <li>Registra un inmueble</li>
                  <li>Envianos tu correo electronico por mensaje a nuestro FB</li>
                  <li>Tu anuncio aparecera aquí por una semana</li>
                </PromotionList>
              </PromotionContent>
            </PromotionWrapper>
          )}
      </HousingLandingWrapper>
      <HousingLandingWrapper>
        <LandingTitle style={{ textAlign: 'center' }}>¿Por qué <TextHighlight blue>FlasHome?</TextHighlight></LandingTitle>
        <WhyElement>
          <WhyImage src={whyImage1} />
          <WhyText>
            <Bold>Nos adecuamos a tus necesidades.</Bold> Puedes publicar tu inmueble gratis, posicionar tu inmueble con alguno de nuestros paquetes o dejarle todo el trabajo a uno de nuestros brokers especializados.
          </WhyText>
        </WhyElement>
        <WhyElement even>
          <WhyImage src={whyImage2} />
          <WhyText even>
            <Bold>Renta y vende con la asesoría de expertos.</Bold> Nuestros expertos te ayudaran en todo momento y harán mucho mas facil la renta o venta de tu inmueble.
          </WhyText>
        </WhyElement>
        <WhyElement>
          <WhyImage src={whyImage3} />
          <WhyText>
            <Bold>Encuentra el inmueble perfecto para ti.</Bold> Ya sea casa o departamento lo que estas buscando, tenemos algo justo para tu bolsillo y que se adapte a tu estilo de vida.
          </WhyText>
        </WhyElement>
      </HousingLandingWrapper>
    </>
  )
}

export default withRouter(Landing)
