import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from "react-router";
import {
  RegisterWrapper,
  RegisterTitle,
  Step,
  StepWrapper,
  InstructionsWrapper
} from './RegisterProperty.styled'
import GeneralInfo from './GeneralInfo'
import Amenities from './Amenities'
import AdDetails from './AdDetails'
import BuyMembership from './BuyMembership'
import Images from './Images'
import { propertiesActions } from '../../store/properties/reducer'
import { registerAd, getInfoEditAd, editAd } from '../../common/utils/api-helpers'
import { removeCommas } from '../../common/utils/helpers'
import NotFound from '../../components/not-found';
import Loader from '../../components/loader';

const RegisterProperty = ({ generalInfo, amenities, adInfo, resetDataValues, resetData }) => {
  const { ad, buy } = useParams();
  const [ step, setStep ] = useState(buy ? 4 : 0);
  const [savedAdId, setSavedAdId] = useState(null);
  const [notAllowed, setNotAllowed] = useState(false);
  const [isEditValue, setIsEditValue] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const instructions = [
    `Llena el siguiente formulario para ${ad ? 'editar' : 'solicitar'} el anuncio de tu inmueble`,
    'Agrega las amenidades y caracteristicas con las que cuenta tu inmueble',
    '¡Perfecto! Solo necesitamos unos datos más',
    'Por ultimo agrega las fotos que quieres incluir en tu anuncio',
    'Elige con que membresía quieres mejorar la exposición de tu inmueble',
  ];
  const title = [
    ad ? 'Edita tu inmueble' : 'Publica tu inmueble',
    ad ? 'Edita tu inmueble' : 'Publica tu inmueble',
    ad ? 'Edita tu inmueble' : 'Publica tu inmueble',
    ad ? 'Edita tu inmueble' : 'Publica tu inmueble',
    ad ? '¡Tu inmueble ha sido editado con exito!' : '¡Tu inmueble ha sido publicado con exito!',
  ];
  const changeStep = (next = true, nextStep = null) => {
    if(!nextStep){
      setStep(next ? step+1 : step-1)
    }else{
      setStep(nextStep)
    }
  }

  const registerAdFunction = async () => {
    const finalBody = {};
    // TODO crear endpoints estados y agregar a front.
    finalBody['estadoID'] = 1;
    finalBody['calle'] = generalInfo['route'];
    finalBody['codigoPostal'] = generalInfo['postal_code'];
    finalBody['ciudad'] = generalInfo['locality'];
    finalBody['colonia'] = generalInfo['neighborhood'];
    finalBody['numeroExterior'] = generalInfo['street_number'];
    finalBody['tipoInmuebleID'] = generalInfo['propertyType'];
    finalBody['antiguedad'] = generalInfo['age'];
    finalBody['areaConstruida'] = generalInfo['area'];
    finalBody['areaTerreno'] = generalInfo['groundArea'];
    finalBody['numeroHabitaciones'] = generalInfo['bedrooms'];
    finalBody['numeroBanos'] = generalInfo['bathrooms'];
    finalBody['numeroMediosBanos'] = generalInfo['halfBathrooms'];
    finalBody['numeroEstacionamiento'] = generalInfo['parkingLots'];
    finalBody['numeroEstacionamiento'] = generalInfo['parkingLots'];
    finalBody['categoria'] = generalInfo['categoryType'];
    finalBody['latitude'] = generalInfo['lat'];
    finalBody['longitude'] = generalInfo['lng'];
    finalBody['mascotas'] = generalInfo['pets'];
    finalBody['amenidades'] = amenities;
    finalBody['titulo'] = adInfo['title'];
    finalBody['descripcion'] = adInfo['description'];
    finalBody['precio'] = parseFloat(removeCommas(adInfo['price']));
    finalBody['disponibilidad'] = adInfo['availability'];
    if (adInfo['deposit']) {
      finalBody['depositoGarantia'] = parseFloat(removeCommas(adInfo['deposit']));
    } else {
      finalBody['depositoGarantia'] = null;
    }
    if (adInfo['manteinance']) {
      finalBody['precioMantenimiento'] = parseFloat(removeCommas(adInfo['manteinance']));
    } else {
      finalBody['precioMantenimiento'] = null;
    }
    finalBody['periodoMinimo'] = adInfo['miniumPeriod'];
    finalBody['precioNegociable'] = adInfo['negotiable'];
    finalBody['propertyUser'] = adInfo['propertyUser'];
    try {
      if (isEditValue) {
        finalBody['inmuebleID'] = ad;
        await editAd(finalBody);
        console.log('register ad')
        return isEditValue;
      } else {
        const res = await registerAd(finalBody);
        return res.data.registerID;
      }
    } catch (err) {
      console.error(err);
      return null;
    }

  };

  useEffect(() => {
    const getAdData = async() => {
      setIsLoading(true);
      if (ad) {
        try {
          const result = await getInfoEditAd(ad);
          if (result.data && result.data.not_allowed) {
            setNotAllowed(true);
          } else if (result.data){
            result.data.adInfo.availability = new Date(result.data.adInfo.availability);
            resetDataValues(result.data);
            setIsEditValue(ad);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        resetData();
      }
      setIsLoading(false);
    };
    getAdData();
  }, [ad, resetDataValues]);

  if (loading) {
    return (
      <RegisterWrapper>
        <Loader marginTop={150} />
      </RegisterWrapper>
    )
  };

  if (notAllowed) {
    return (
      <RegisterWrapper>
        <NotFound text={'Este anuncio no esta registrado en tu cuenta'} type="notPublished" />
      </RegisterWrapper>
    )
  };

  return (
    <RegisterWrapper>
      <RegisterTitle isOrange={step === 4}> {title[step]} </RegisterTitle>
      <InstructionsWrapper>{instructions[step]}</InstructionsWrapper>
      <StepWrapper>
        <Step active={step === 0} />
        <Step active={step === 1} />
        <Step active={step === 2} />
        <Step active={step === 3} />
        {/*<Step active={step === 4} />*/}
      </StepWrapper>
      {step === 0 && <GeneralInfo changeStep={changeStep} isEditValue={isEditValue}/>}
      {step === 1 && <Amenities changeStep={changeStep} />}
      {step === 2 && <AdDetails changeStep={changeStep} />}
      {step === 3 && (
        <Images
          changeStep={changeStep}
          registerAd={registerAdFunction}
          savedAdId={savedAdId}
          setSavedAdId={setSavedAdId}
          isEdit={isEditValue}
        />
      )}
      {step === 4 && <BuyMembership savedAdId={isEditValue}/>}
    </RegisterWrapper>
  )
}

const mapStateTopProps = ({ properties }) => {
  const { generalInfo, amenities, adInfo  } = properties
  return { generalInfo, amenities, adInfo }
}

const mapDispatchToProps = dispatch => ({
  resetDataValues: propertiesActions.resetDataValues(dispatch),
  resetData: propertiesActions.resetData(dispatch),
})

export default connect(mapStateTopProps, mapDispatchToProps)(RegisterProperty)
