import React, { useState, useEffect } from 'react'
import {
  ProfileWrapper,
  ProfileHeadline,
  FormWrapper,
  InputContainer,
  ConfirmButton
} from './styled'
import InputField from '../../components/InputField'
import { getUsuarioInfo , updateUsuarioInfo, updatePassword } from '../../common/utils/api-helpers'
import { useForm } from 'react-hook-form'
import Loader from '../../components/loader'
import { useAlert } from 'react-alert';
import { update } from 'lodash'

const Profile = _ => {
  const { register, handleSubmit, errors, getValues } = useForm()
  const { register: registerPassword, handleSubmit: handlePasswordSubmit, errors: errorsPassword, getValues: getPasswordValues } = useForm()
  const [loading, setLoading] = useState(true)
  const [userInfo, setUserInfo] = useState(null)
  const alert = useAlert();

  useEffect(() => {
    getUsuarioInfo().then(res => {
      setUserInfo(res.data)
      setLoading(false)
    })
  }, []);

  const submitInfo = async(data) => {
    setLoading(true);
    const newData = { nombre: data.name, apellido: data.lastName, telefono: data.phone};
    await updateUsuarioInfo(newData)
    alert.success('Actualización exitosa');
    setUserInfo({ email: userInfo.email, ...newData })
    setLoading(false);
  }

  const submitPassword = async(data) => {
    setLoading(true);
    await updatePassword({ password: data.password})
    alert.success('Actualización exitosa');
    setLoading(false);
  }

  return (
    <ProfileWrapper>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ProfileHeadline>Tu información</ProfileHeadline>
          <FormWrapper onSubmit={handleSubmit(submitInfo)}>
            <InputContainer>
              <InputField
                 name="name"
                 placeholder="Nombre"
                 type="text"
                 reference={register({
                   required: { value: true, message: "Debes introducir este campo"},
                   minLength: { value: 2, message: "Mínimo 2 caracteres"},
                 })}
                 internalError={errors.name}
                 suffixError={errors.name && errors.name.message}
                 defaultValue={(userInfo && userInfo.nombre) || ''}
              />
            </InputContainer>
            <InputContainer>
              <InputField
                name="lastName"
                placeholder="Apellido"
                type="text"
                reference={register({
                  required: { value: true, message: "Debes introducir este campo"},
                  minLength: { value: 2, message: "Mínimo 2 caracteres"},
                })}
                internalError={errors.lastName}
                suffixError={errors.lastName && errors.lastName.message}
                defaultValue={(userInfo && userInfo.apellido) || ''}
              />
            </InputContainer>
            <InputContainer>
              <InputField
                name="email"
                type="text"
                disabled
                placeholder="Email"
                defaultValue={(userInfo && userInfo.email) || ''}
              />
            </InputContainer>
            <InputContainer>
              <InputField
               name="phone"
               placeholder="Teléfono"
               type="text"
               reference={register({
                 required: { value: true, message: "Debes introducir este campo"},
                 minLength: { value: 10, message: "Número a 10 digitos"},
               })}
                placeholder="Telefono"
                suffixError={errors.phone && errors.phone.message}
                internalError={errors.phone}
                defaultValue={(userInfo && userInfo.telefono) || ''}
              />
            </InputContainer>
            <ConfirmButton type='submit'>Actualizar</ConfirmButton>
          </FormWrapper>
          <FormWrapper onSubmit={handlePasswordSubmit(submitPassword)}>
            <InputContainer>
              <InputField
                name="password"
                placeholder="Contraseña"
                type="password"
                reference={registerPassword({
                  required: { value: true, message: "Debes introducir este campo" },
                  minLength: { value: 6, message: "Mínimo 6 caracteres" },
                })}
                internalError={errorsPassword.password}
                suffixError={errorsPassword.password && errorsPassword.password.message}
              />
            </InputContainer>
            <InputContainer>
              <InputField
                name="confirmPassword"
                placeholder="Confirmar Contraseña"
                type="password"
                reference={registerPassword({
                  required: { value: true, message: "Debes introducir este campo" },
                  minLength: { value: 6, message: "Mínimo 6 caracteres" },
                  validate: (value) => {
                    if (value !== getPasswordValues('password')) {
                      return "Las contraseñas no coinciden";
                    }
                    return true;
                  }
                })}
                internalError={errorsPassword.confirmPassword}
                suffixError={errorsPassword.confirmPassword && errorsPassword.confirmPassword.message}
              />
            </InputContainer>
            <ConfirmButton type='submit'>Actualizar contraseña</ConfirmButton>
          </FormWrapper>
        </>
      )}
    </ProfileWrapper>
  )
}

export default Profile
