import styled from 'styled-components';
import { COLORS } from '../../common/utils/constants';

export const PropertiesListContainer = styled.div`
  top: 50px;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &.favorites { 
    height: calc(100vh - 155px);

    @media (max-width: 768px) {
      min-height: auto;
      height: auto;
    }
  }
`;

export const PropertyDetailContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 10px 20px;
    box-sizing: border-box;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
`

export const MapContainer = styled.div`
  width: 40%;
  height: calc(100vh - ${props => props.sticky ? '69px' : '149px'});
  display: flex;
  top: ${props => props.sticky ? '69px' : '0'};
  position: ${props => props.sticky ? 'fixed' : 'relative'};

  @media (max-width: 1679px) {
    width: 30%;
  }

  @media (max-width: 768px) {
    display: none;
  }

  &.favorites{
    height: calc(100vh - 80px);
    overflow: scroll;
  }
`

export const MapitaImage = styled.img`
  width: 40%;
  height: 100%;
`;

export const PropertiesListWrapper = styled.div`
  margin-left: ${props => props.sticky ? '40%' : '0'};
  margin-top: ${props => props.sticky ? '70px' : '0'};
  overflow-x: hidden;
  padding-bottom: 10px;
  width: 60%
  min-height: calc(100vh - 149px);

  @media (max-width: 1679px) {
    width: 70%;
    margin-left: ${props => props.sticky ? '30%' : '0'};
  }

  @media (max-width: 1366px) {
    width: 70%;
    margin-left: ${props => props.sticky ? '30%' : '0'};
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    z-index: 2;
  }

  &.favorites{
    height: calc(100vh - 90px);
    overflow: scroll;
    margin-top: 10px;

    @media (max-width: 768px) {
      min-height: auto;
      height: auto;
    }
  }
`;

export const PropertiesListResults = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;

  @media (max-width: 768px) {
    height: fit-content;
    justify-content: center;
    padding-right: 15px;
  }
`;

export const PropertiesFilters = styled.div`
  align-items: flex-start;
  background: white;
  border-bottom: 1px solid ${COLORS.darkBlue}4D;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 500;
  padding: 20px 15px 10px 15px;
  position: ${props => props.sticky ? 'fixed' : 'relative'};
  top: 0;
  width: 100%;
  z-index: 100;

  @media (max-width: 768px) {
    height: 230px;
    padding: 0;
    margin-bottom: 16px;
  }
`;

export const CityFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    & > input{
      width: 90%;
      margin-top: 10px;
    }
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  height: 32px;
  color: ${COLORS.white};
  background-color: ${COLORS.darkBlue};
  padding: 0 20px;
  transition: all 0.25s;
  position: absolute;
  right: 15px;

  :hover {
    background-color: ${COLORS.primaryOrange};
  }

  @media (max-width: 768px) {
    font-size: 16px;
    width: calc(100vw - 80px);
    justify-content: center;
    bottom: 20px;
    right: 20px;
  }
`;

export const BudgetWrapper = styled.div`
  width: 350px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  font-size: 18px;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }

  @media (max-width: 1024px) {
    width: 260px;
  }
`;

export const RangeWrapper = styled.div`
  display: flex;
  width: 350px;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-left: 0;
    width: 100%;
  }

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;

export const BudgetSortWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 15px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: calc(100vw - 40px);
    padding: 0;
    margin: 0 auto;
  }
`;

export const BudgetLabel = styled.div`
  width: 50px;
  font-size: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  color: ${COLORS.darkBlue};
`

export const PropertiesBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 51px);

  @media (max-width: 1024px) {
    height: 66vh;
  }

  @media (max-width: 768px) {
    height: fit-content;
  }
`;

export const SortWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const SortLabel = styled.p`
  white-space: nowrap;
`

export const GalleryPreview = styled.div`
  display: flex;
  padding: 20px 0;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0;
  }
`;

export const RelatedPropertyWrapper = styled.div`
  padding: 10px;
`;

export const PropertySummary = styled.div`
  width: calc(100% - 20px);
  left: 65%;
  margin: 20px auto 8px;
  box-sizing: border-box;
  padding: 15px;
  font-weight: 500;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 75%);

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const GalleryImage = styled.img`
  box-sizing: border-box;
  width: 100%;
  height: ${props => props.big ? '500px' : '33.333%'};
  padding: 10px 10px 10px 0;
  object-fit: cover;

  &:first-child {
    padding-top: 0;
    padding-bottom: 0;
  }

  &:last-child {
    padding-top: 0;
    padding-bottom: 0;
  }

  cursor: pointer;

  @media (max-width: 768px) {
    padding-top: 0;
    &:not(.mini) {
      padding: 0;
    }
    &.mini{
      width: 33.333%;
      height: 100%;
      padding-bottom: 0;
      &:last-child {
        padding-right: 0;
      }
    }
  }
`;

export const GalleryMinis = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  position: relative;
  width: 30%;

  @media (max-width: 768px) {
    height: 160px;
    padding: 0;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
  }
`;

export const GalleryBig = styled.div`
  width: ${props => (props.hasOtherImages ? '70%': '100%')};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PriceTag = styled.p`
  color: ${COLORS.primaryOrange};
  font-size: 30px;
  font-weight: 600;
  margin: 0;
`;

export const MainPropertyInfo = styled.div``;

export const DescriptionWrapper = styled.div`
  font-weight: 500;
  padding: 20px 10px 20px 0;
`;

export const DescriptionInnerWrapper = styled.div`
  flex-direction: column;
  display: flex;
`;

export const SectionTitle = styled.p`
    font-size: 35px;
    font-weight: 500;
    margin-top: 0;
    color: ${COLORS.primaryOrange};
    border-bottom: 1px solid ${COLORS.primaryOrange};
    margin-bottom: 5px;
`;

export const Address = styled.p`
  font-size: 16px;
  margin-bottom: 24px;
`;

export const Highlight = styled.span`
  font-weight: 600;
`;

export const LoaderWrapper = styled.div`
    height: 564px;
    display: flex;
    justify-content: center;

    &.detail {
        height: 88vh;
    }
`

export const PaginationButton = styled.button`
    width: 200px;
    height: 30px;
    background-color: ${COLORS.primaryBlue};
    border-radius: 5px;
    color: white;
    border: none;
    &:last-child{
        margin-left: 30px;
    }
    &.disabled {
        background-color: #5e5d5c;
    }

    @media (max-width: 768px) {
        width: 90%;
        &:last-child{
            margin-bottom: 10px;
            margin-left: 0;
        }
    }
`;

export const ButtonWraper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
    }

`;
export const SectionSubtitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${COLORS.primaryOrange};

  &.favorites {
    margin-left: 15px;
  }
`;

export const DetailsContainer = styled.div`
  position: relative;
  min-height: calc(100vh - 100px);
  overflow-x: hidden;
`;

export const RelatedWrapper = styled.div`
    margin: 20px auto;
    width: 100%;
    box-sizing: border-box;
`

export const RelatedPropertiesWrapper = styled.div`
  height: auto;
`

export const AutocompleteWrapper = styled.div`
  width: 350px;
  margin-right: 30px;

  @media (max-width: 1024px) {
    width: 350px;
  }

  @media (max-width: 769px) {
    width: calc(100% - 40px);
    margin: 0 20px;
  }

`

export const SelectionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 30px;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    justify-content: center;
    margin-right: 0;
  }
`

export const Selection = styled.p`
  cursor: pointer;
  font-size: 14px;
  margin: 0;
  border: 2px solid ${COLORS.darkBlue};
  border-right: none;
  padding: 5px 10px;

  &:last-child{
    border-right: 2px solid ${COLORS.darkBlue};
  }

  &.active {
    background: ${COLORS.darkBlue};
    color: ${COLORS.white};
  }

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
`

export const BudgetHighlight = styled.span`
  color: ${COLORS.darkBlue};
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const BudgetNumbers = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
`;

export const FullScreenWrapper = styled.div`
  position: relative;
`

export const FullScreenButton = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: black;
  opacity: 0.7;
  width: 210px;
  height: 20px;
  color: white;
  padding: 5px;
  cursor: pointer;
`

export const FullscreenOverlay = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0,0,0,0.7);
  z-index: 999;
`;

export const GalleryWrapper = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    z-index: 1000;

    & .image-gallery-slide img {
        width: 60vw;
        height: 70vh;
        object-fit: scale-down;

        @media (max-width: 768px) {
            width: 95vw;
            height: 50vh;
        }
    }

    & .image-gallery-icon {
      background: rgba(0,0,0,0.5);
    }
`

export const MoreImageOverlay = styled.div`
  position: absolute;
  color: white;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  height: 33.333%;
  width: calc(100% - 10px);
  font-size: 50px;
  cursor: pointer;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    right: 0;
    width: 33.333%;
    height: 100%;
  }
`;

export const CloseButton = styled.img`
  position: absolute;
  color: white;
  z-index: 4;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 35px;
  top: 0;
  right: -20px;
  cursor: pointer;


  @media (max-width: 768px) {
    right: 0;
    top: -20px;
  }
`;

export const FavoriteWrapper = styled.div`
  position: relative;
`;
