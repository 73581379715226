import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import axios from 'axios'
import Landing from './pages/Landing'
import Login from './pages/Login'
import Profile from './pages/Profile'
import Register from './pages/Login/Register'
import ResetPassword from './pages/Login/ResetPassword'
import Header from './components/Header'
import ContentWrapper from './components/ContentWrapper'
import Footer from './components/Footer'
import PropertiesList from './pages/Properties/PropertiesList'
import PropertyDetail from './pages/Properties/PropertyDetail'
import Favorites from './pages/Properties/Favorites'
import RegisterProperty from './pages/RegisterProperty'
import UsersList from './pages/Admin/Users'
import AdminPropertiesList from './pages/Admin/Properties'
import UserPropertiesList from './pages/User/Properties'
import TC from './pages/TCyPP/TC'
import PP from './pages/TCyPP/PP'
import { userActions } from './store/user/reducer'
import './App.css'
import AuthService from './common/utils/auth.service'
import { validateTokenEndpoint } from './common/utils/api-helpers'
import Loader from './components/loader';
import Contact from './pages/Landing/Contact'

const messenger = require('./common/images/messenger.svg')

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT

axios.interceptors.response.use(response => {
  return response;
}, error => {
 if (error.response.status === 401) {
    AuthService.logout();
    window.location.href = '/login';
    return;
 }
 return error;
});

axios.defaults.headers.common['x-access-token'] = (AuthService.getCurrentUser() && AuthService.getCurrentUser().token) || 'null';

const ShouldBeAdmin = ({ children }) => {
  const user = AuthService.getCurrentUser();
  const isAdmin = user && user.usuario.rol === 'admin';

  if (!user) {
    return (
      <Redirect to='/login' />
    );
  }
  if (isAdmin) {
    return (
      <>
        { children }
      </>
    )
  }
  return(
    <Redirect to='/' />
  )
};

const ShouldBeLogged = ({ children, shouldBeLogged, adminAllowed, validateToken }) => {
  const user = AuthService.getCurrentUser();
  const isAdmin = user && user.usuario.rol === 'admin';
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function validate() {
      setLoading(true);
      await validateTokenEndpoint();
      setTimeout(() => setLoading(false), 500);
    };

    if (validateToken) {
      validate();
    }
  }, []);

  if (loading) {
    return (
      <Loader marginTop={200}/>
    )
  }

  if (adminAllowed && isAdmin) {
    return (
      <>
        {children}
      </>
    );
  }

  if (!shouldBeLogged && user) {
    return (
      <Redirect to='/' />
    );
  }

  if (shouldBeLogged && !user) {
    return (
      <Redirect to='/login' />
    );
  }

  return (
    <>
      {children}
    </>
  )
};

const App = ({ user, setUser }) => {

  useEffect(() => {
    setUser(AuthService.getCurrentUser());
  }, [setUser])

  useEffect(() => {
    if (user) {
      axios.defaults.headers.common['x-access-token'] = user.token
    }
  }, [user])

  return (
    <Router>
      <Header />
      <ContentWrapper>
        <Switch>
          <Route path='/login'>
            <ShouldBeLogged shouldBeLogged={false}>
              <Login />
            </ShouldBeLogged>
          </Route>
          <Route path='/perfil'>
            <ShouldBeLogged shouldBeLogged>
              <Profile />
            </ShouldBeLogged>
          </Route>
          <Route path='/register'>
            <ShouldBeLogged shouldBeLogged={false} adminAllowed>
              <Register />
            </ShouldBeLogged>
          </Route>
          <Route path='/lista-propiedades'>
            <PropertiesList />
          </Route>
          <Route path='/favoritos'>
            <ShouldBeLogged shouldBeLogged>
              <Favorites />
            </ShouldBeLogged>
          </Route>
          <Route path='/detalle/:inmuebleId'>
            <PropertyDetail />
          </Route>
          <Route path='/registrar-propiedad/:ad?'>
            <ShouldBeLogged shouldBeLogged validateToken>
              <RegisterProperty />
            </ShouldBeLogged>
          </Route>
          <Route path='/contratar-membresia/:buy/:ad'>
            <ShouldBeLogged shouldBeLogged>
              <RegisterProperty />
            </ShouldBeLogged>
          </Route>
          <Route path='/admin/usuarios'>
            <ShouldBeAdmin>
              <UsersList />
            </ShouldBeAdmin>
          </Route>
          <Route path='/admin/inmuebles'>
            <ShouldBeAdmin>
              <AdminPropertiesList />
            </ShouldBeAdmin>
          </Route>
          <Route path='/user/inmuebles'>
            <ShouldBeLogged shouldBeLogged>
              <UserPropertiesList />
            </ShouldBeLogged>
          </Route>
          <Route path='/password/reset/:token'>
            <ShouldBeLogged shouldBeLogged={false}>
              <ResetPassword />
            </ShouldBeLogged>
          </Route>
          <Route path='/terminos-y-condiciones'>
            <TC />
          </Route>
          <Route path='/politicas-privacidad'>
            <PP />
          </Route>
          <Route path='/contacto'>
            <Contact />
          </Route>
          <Route path='/'>
            <Landing />
          </Route>
        </Switch>
      </ContentWrapper>
      <Footer />
      <div className='helpText'>
        ¿Necesitas ayuda?
      </div>
      <a style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: '1000',
        background: '#022169',
        width: '50px',
        height: '50px',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        boxShadow: '0px -10px 30px -5px rgb(0 0 0 / 20%)'
      }} 
      target='blank'
      href='http://m.me/102763391861973'>
        <img style={{ width: '25px' }} src={messenger} />
      </a>
    </Router>
  )
}

const mapStateTopProps = ({ currentUser }) => {
  const { user  } = currentUser
  return { user }
}

const mapDispatchToProps = dispatch => ({
  setUser: userActions.setUset(dispatch)
})

export default connect(mapStateTopProps, mapDispatchToProps)(App)
