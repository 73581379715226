import React from 'react'
import styled from 'styled-components'
import { ReactSVG } from 'react-svg'

export const IconInput = styled(ReactSVG)`
  height: 20px;
  width: 20px;
  path {
    fill: #000;
  }
`

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: ${props => props.withMargin && '5px'};
  border: 1px solid ${props => props.error ? '#FF3429' : '#C6C6C6'};
  border-radius: 5px;
`

export const Input = styled.input`
  border: none;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  height: 34px;
  padding: 15px 10px;
  width: 100%;
  border-radius: 5px;
  ${props => props.disabled && 'background: #dadada'}

  &:focus {
    outline: none;
  }
`

export const IconWrapper = styled.div`
  width: 50px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Suffix = styled.p`
  position: absolute;
  right: 10px;
  color: ${props => props.error ? '#FF3429' : '#000'};
  margin: 0;
  height: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;

  &.isTextArea {
    align-items: flex-start;
    margin-top: 10px;
  }
`

export const SuffixError = styled.p`
  position: absolute;
  right: 10px;
  color: ${props => props.error ? '#FF3429' : '#000'};
  margin: 0;
  height: 100%;
  font-size: 10px;
  display: flex;
  align-items: center;

  &.isTextArea {
    align-items: flex-start;
    margin-top: 10px;
    bottom: -15px;
    display: flex;
    height: auto;
    right: 0;
  }

  @media (max-width: 768px) {
    position: static;
  }
`

export const TextArea = styled.textarea`
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  resize: none;
  font-size: 16px;
  border-radius: 4px;
  padding: 10px 10px;
  height: 130px;
  box-sizing: border-box;
  border: none;
`

const InputField = ({
  disabled,
  value,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  icon,
  placeholder,
  suffix,
  suffixError,
  error = false,
  type = 'text',
  regex = null,
  minChar = null,
  maxChar = null,
  internalError = false,
  setInternalError = () => {},
  required = false,
  name = null,
  reference = null,
  withMargin = false,
  isTextArea,
  defaultValue = '',
}) => (
  <InputContainer error={error || internalError} withMargin={withMargin}>
    {icon && <IconWrapper><IconInput src={icon}/></IconWrapper>}
    {isTextArea ? (
      <TextArea
        required={required}
        name={name}
        ref={reference}
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() =>{
          onFocus();
          setInternalError(false)
        }}
        onBlur={(e) => {
          onBlur();
          if(regex){
            if(!regex.test(e.target.value)){
              setInternalError(true);
            }else{
              setInternalError(false);
            }
          }else if (minChar || maxChar) {
            let isValid;
            let inputTotalLength = e.target.value.length;
            if (minChar && !maxChar) {
              isValid = inputTotalLength >= minChar;
            } else if (!minChar && maxChar) {
              isValid = inputTotalLength <= maxChar;
            } else {
              isValid = inputTotalLength <= maxChar && inputTotalLength >= minChar;
            }
            setInternalError(!isValid);
          }else{
            setInternalError(false);
          }
        }}
        placeholder={placeholder}
      />
    ) : (
      <Input
        required={required}
        disabled={disabled}
        name={name}
        ref={reference}
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        defaultValue={defaultValue}
        onFocus={() =>{
          onFocus();
          setInternalError(false)
        }}
        onBlur={(e) => {
          onBlur();
          if(regex){
            if(!regex.test(e.target.value)){
              setInternalError(true);
            }else{
              setInternalError(false);
            }
          }else if (minChar || maxChar) {
            let isValid;
            let inputTotalLength = e.target.value.length;
            if (minChar && !maxChar) {
              isValid = inputTotalLength >= minChar;
            } else if (!minChar && maxChar) {
              isValid = inputTotalLength <= maxChar;
            } else {
              isValid = inputTotalLength <= maxChar && inputTotalLength >= minChar;
            }
            setInternalError(!isValid);
          }else{
            setInternalError(false);
          }
        }}
        placeholder={placeholder}
      />
    )}
    {suffix && !internalError && <Suffix error={error || internalError} className={isTextArea && 'isTextArea'}>{suffix}</Suffix>}
    {suffixError && internalError && <SuffixError error={internalError} className={isTextArea && 'isTextArea'}>{suffixError}</SuffixError>}
  </InputContainer>
);

export default InputField;
