import { useReducer, useEffect } from 'react';
import { debounce } from 'debounce'
import { useAlert } from 'react-alert';
import {
    userProperties,
    userPropertiesMeta,
} from '../../../common/utils/api-helpers';
import { USERSLIMIT } from '../../../common/utils/constants';


const loading = (state, { loading }) => ({
    ...state,
    loading,
});

const requestData = (state, { propertyList, totalPages }) => ({
    ...state,
    propertyList,
    totalPages,
});

const changePage = (state, { offset, actualPage }) => ({
    ...state,
    offset,
    actualPage,
});

const changeFilter = (state, {
    sponsoredFilter, destacadoFilter, statusFilter, inmuebleID
}) => ({
    ...state,
    sponsoredFilter,
    destacadoFilter,
    statusFilter,
    inmuebleID,
    offset: 0
});

const statusChange = (state, { statusChange }) => ({
  ...state,
  statusChange,
});

const reducerActions = {
    LOADING: loading,
    REQUEST_DATA: requestData,
    CHANGE_FILTER: changeFilter,
    CHANGE_PAGE: changePage,
    STATUS_CHANGE: statusChange,
}

const reducer = (state, action) => reducerActions[action.type](state, action.payload);

const getProperties = debounce(async(sponsoredFilter, destacadoFilter, statusFilter, inmuebleID, offset, alert, dispatch) => {
  try {
    dispatch({ type: 'LOADING', payload: { loading: true }});

    const data = await userProperties(inmuebleID, sponsoredFilter, destacadoFilter, statusFilter, 20, offset);

    const metaData = await userPropertiesMeta(inmuebleID, sponsoredFilter, destacadoFilter, statusFilter, 20, offset);

    if (data.data && metaData.data && metaData.data.hasOwnProperty('total')) {
      dispatch({
        type: 'REQUEST_DATA',
        payload: { propertyList: data.data, totalPages: Math.ceil(metaData.data.total/USERSLIMIT) }
      });
    }else{
      alert.error('Ocurrió un error, intenta de nuevo más tarde');
    }
  } catch(err) {
    alert.error('Ocurrió un error, intenta de nuevo más tarde');
    console.log(err);
  } finally {
    dispatch({ type: 'LOADING', payload: { loading: false }});
  }
}, 500);

const usePropertiesFilter = () => {
    const alert = useAlert();
    const initialState = {
        propertyList: [],
        totalPages: 0,
        loading: false,
        sponsoredFilter: { value: '-1', label: "Todos" },
        destacadoFilter: { value: '-1', label: "Todos" },
        statusFilter: { value: '-1', label: "Todos" },
        inmuebleID: '',
        offset: 0,
        actualPage: 1,
        statusChange: false,
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        getProperties(
            state.sponsoredFilter.value, state.destacadoFilter.value, state.statusFilter.value,
            state.inmuebleID, state.offset, alert, dispatch
        );
      }, [
          state.sponsoredFilter.value, state.destacadoFilter.value, state.statusFilter.value,
          state.inmuebleID, state.offset, state.statusChange, alert, dispatch
    ]);

    return [state, dispatch];
}

export default usePropertiesFilter;
