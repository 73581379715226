/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { useAlert } from 'react-alert'
import { userActions } from '../../store/user/reducer'
import Button from '../../components/Button'
import Loader from '../../components/loader';
import InputField from '../../components/InputField'
import AuthService from '../../common/utils/auth.service'
import { resetPasswordEmail } from '../../common/utils/api-helpers';
import { useForm } from "react-hook-form";
import {
  LoginWrapper,
  LoginForm,
  LoginTitle,
  LoginText,
  LoginFormWrapper,
  InputContainer,
  ButtonsWrapper,
  ToLoginText,
  LoginLink,
} from './styled'

const mailIcon = require('../../common/images/mail.svg')
const passwordIcon = require('../../common/images/password.svg')

const Login = props => {
  const [loading, setLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showPasswordInfo, setShowPasswordInfo] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const alert = useAlert()

  const signIn = async(data) => {
    setLoading(true);
    if (forgotPassword) {
      try {
        await resetPasswordEmail(data.email);
        setShowPasswordInfo(true);
      } catch(err) {
        console.log(err)
        alert.error('Ocurrió un error, intenta de nuevo más tarde')
      } finally {
        setLoading(false);
      }
    } else {
      AuthService.login(data.email, data.password)
        .then(user => {
          setLoading(false);
          props.setUser(user);
          props.history.push('/');
        })
        .catch(err => {
          setLoading(false);
          alert.error('Correo o contrasena incorrectos')
          console.log(err);
        })
    }
  };

  return (
    <LoginWrapper>
      {loading && (
        <Loader />
      )}
      {!loading && (
        <LoginFormWrapper onSubmit={handleSubmit(signIn)}>
          {!forgotPassword ? (
            <>
              <LoginTitle>Bienvenido a Flashome</LoginTitle>
              <LoginText>Encuentra tu hogar ideal con nosotros</LoginText>
              <LoginForm>
                <InputContainer>
                  <InputField
                    name="email"
                    type="text"
                    icon={mailIcon}
                    reference={register({
                        required: { value: true, message: "Debes introducir este campo"},
                        pattern: {
                          value: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                          message: "Correo electrónico incorrecto",
                        }
                    })}
                    placeholder="Email"
                    suffixError={errors.email && errors.email.message}
                    internalError={errors.email}
                  />
                </InputContainer>
                <InputContainer>
                  <InputField
                    name="password"
                    icon={passwordIcon}
                    placeholder="Contraseña"
                    type="password"
                    internalError={errors.password}
                    suffixError={errors.password && errors.password.message}
                    reference={register({
                      required: { value: true, message: "Debes introducir este campo"},
                      minLength: { value: 6, message: "Mínimo 6 caracteres" },
                  })}
                  />
                </InputContainer>
                <ToLoginText><LoginLink onClick={() => setForgotPassword(true)}>Olvidé mi contraseña</LoginLink></ToLoginText>
              </LoginForm>
            </>
          ) : (
            <>
              {showPasswordInfo ? (
                <LoginTitle>Recibirás un correo con las instrucciones para recuperar tu contraseña.</LoginTitle>
              ) : (
                <>
                  <LoginTitle>Bienvenido a Flashome</LoginTitle>
                  <LoginText>Introduce tu correo para recuperar tu contraseña</LoginText>
                  <LoginForm>
                    <InputContainer>
                      <InputField
                        name="email"
                        type="text"
                        icon={mailIcon}
                        reference={register({
                            required: { value: true, message: "Debes introducir este campo"},
                            pattern: {
                              value: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                              message: "Correo electrónico incorrecto",
                            }
                        })}
                        placeholder="Email"
                        suffixError={errors.email && errors.email.message}
                        internalError={errors.email}
                      />
                    </InputContainer>
                    <ToLoginText><LoginLink onClick={() => setForgotPassword(false)}>Cancelar</LoginLink></ToLoginText>
                  </LoginForm>
                </>
              )}
            </>
          )}
          {!showPasswordInfo && (
            <ButtonsWrapper>
              <Button
                secondary
                width='45%'
                type="button"
                onClick={() => props.history.push('/register')}>
                Registrarme
              </Button>
              <Button
                width='45%'
                type="submit"
              >
                {!forgotPassword ? 'Iniciar Sesión' : 'Recuperar contraseña'}
              </Button>
            </ButtonsWrapper>
          )}
        </LoginFormWrapper>
      )}
    </LoginWrapper>
  )
}

const mapDispatchToProps = dispatch => ({
  setUser: userActions.setUset(dispatch)
})

export default connect(null, mapDispatchToProps)(withRouter(Login))
