import React, {useEffect, useState} from 'react';
import { useAlert } from 'react-alert';
import PriceCard from '../price-card';
import {
    getMembershipTypes,
    getMembershipFrecuency
} from '../../common/utils/api-helpers';
import Loader from '../loader';
import {
    MainWrapper,
} from './styled';

const MembershipSelect = ({ flippedCard, setFlippedCard, setSelectedFrecuency, onNextStep }) => {
    const [membershipTypes, setMembershipTypes] = useState([]);
    const [membershipFrecuency, setMembershipFrecuency] = useState([]);
    const [loading, setLoading] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        async function getMembershipInfo () {
            try{
                setLoading(true);
                const types = await getMembershipTypes();
                setMembershipTypes(types.data.result);
                const frecuency = await getMembershipFrecuency();
                setMembershipFrecuency(frecuency.data.result);
            } catch(err) {
                console.log(err);
                alert.error('Ocurrió un error, intenta de nuevo más tarde');
            } finally {
                setLoading(false);
            }
        };
        getMembershipInfo();
    }, [alert])

    return(
        <MainWrapper>
            {!loading && membershipTypes.length > 0 && membershipTypes.map(type => (
                <PriceCard
                    key={type.tipoMembresiaID}
                    title={type.nombre}
                    price={type.precio}
                    priceSubtitle="por día"
                    description={type.descripcion}
                    isSmall={type.tipoMembresiaID !== 2}
                    index={type.tipoMembresiaID}
                    flippedCard={flippedCard.tipoMembresiaID}
                    onClick={() => setFlippedCard(type)}
                    onCancel={() => setFlippedCard({ id: -1 })}
                    onNextStep={onNextStep}
                    setSelectedFrecuency={setSelectedFrecuency}
                    options={membershipFrecuency}
                />
            ))}
            {loading && (
                <Loader />
            )}
        </MainWrapper>
    )
};

export default MembershipSelect;