/* eslint-disable no-useless-escape*/
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import Select from 'react-select'
import Button from '../../components/Button'
import InputField from '../../components/InputField'
import Loader from '../../components/loader';
import { userActions } from '../../store/user/reducer';
import { useForm } from "react-hook-form";
import {
  crearUsuarioPlataforma,
  editarUsuarioAdmin,
  crearUsuarioAdmin
} from '../../common/utils/api-helpers';
import {
  LoginWrapper,
  LoginForm,
  LoginTitle,
  LoginText,
  LoginFormWrapper,
  InputContainer,
  ButtonsWrapper,
  ToLoginText,
  LoginLink
} from './styled'
import { useAlert } from 'react-alert';

const transformUserType = (type) => {
  switch (type) {
    case 'admin':
      return 1
    case 'plataforma':
      return 2;
    case 'broker':
      return 3;
    default:
      return null;
  }
};

const Register = (props) => {
  const { register, handleSubmit, errors, getValues } = useForm();
  const history = useHistory();
  const alert = useAlert();
  const editUser = props.location.state && props.location.state.user;
  const currentUser = props.user;
  const isAdmin = currentUser && currentUser.usuario.rol === 'admin';
  const [ tipoUsuario, setTipoUsuario ] = useState(transformUserType(editUser && editUser.tipoUsuario));
  const [loading, setLoading] = useState(false);
  let buttonText;
  if (isAdmin) {
    if (editUser) {
      buttonText = "Editar";
    } else {
      buttonText = "Registrar";
    }
  } else {
    buttonText = "Registrarme";
  }
  const userTypeOptions = [
    { value: 1, label: 'Admin' },
    { value: 2, label: 'Plataforma' },
    { value: 3, label: 'Broker' }
  ];

  const submit = async(data) => {
    setLoading(true);
    try {
      const finalUser = {
        nombre: data.name,
        apellido: data.lastName,
        telefono: data.phone,
        email: data.email,
      };
      if (data.password) {
        finalUser.password = data.password;
      }
      if (isAdmin) {
        finalUser['tipoUsuarioID'] = tipoUsuario;
        if (editUser){
          await editarUsuarioAdmin(finalUser, editUser.usuarioID);
          alert.success('Edición exitosa');
        } else {
          await crearUsuarioAdmin(finalUser);
          alert.success('Registro exitoso');
        }
        props.history.push('/');
      } else {
        const usuario = await crearUsuarioPlataforma(finalUser);
        alert.success('Registro exitoso');
        props.setUser(usuario.data);
        localStorage.setItem("flashome-user", JSON.stringify(usuario.data));
        props.history.push('/');
      }
    } catch(err) {
      console.log(err);
      alert.error('Ocurriió un error, intenta de nuevo más tarde');
    } finally {
      setLoading(false);
    }
  }

  return (
    <LoginWrapper>
      {loading && (
        <Loader />
      )}
      {!loading && (
        <LoginFormWrapper onSubmit={handleSubmit(submit)}>
          {!editUser && (<LoginTitle>Bienvenido a Flashome</LoginTitle>)}
          <LoginText>
            {editUser ? "Si no vas a cambiar la contraseña deja los campos vacíos" : "Sabemos que no buscas un inmueble, sino el hogar ideal para tu estilo de vida."}
          </LoginText>
          <LoginForm>
            <InputContainer>
              <InputField
                name="email"
                type="text"
                reference={register({
                    required: { value: true, message: "Debes introducir este campo"},
                    pattern: {
                      value: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                      message: "Correo electrónico incorrecto",
                    }
                })}
                placeholder="Email"
                suffixError={errors.email && errors.email.message}
                internalError={errors.email}
                defaultValue={(editUser && editUser.email) || ''}
              />
            </InputContainer>
            <InputContainer>
              <InputField
                name="password"
                placeholder="Contraseña"
                type="password"
                reference={register({
                  required: { value: !editUser , message: "Debes introducir este campo"},
                  minLength: { value: 6, message: "Mínimo 6 caracteres"},
                })}
                internalError={errors.password}
                suffixError={errors.password && errors.password.message}
              />
            </InputContainer>
            <InputContainer>
              <InputField
                name="confirmPassword"
                placeholder="Confirmar Contraseña"
                type="password"
                reference={register({
                  required: { value: !editUser, message: "Debes introducir este campo"},
                  minLength: { value: 6, message: "Mínimo 6 caracteres"},
                  validate: (value) => {
                    if (value !== getValues('password')){
                      return "Las contraseñas no coinciden";
                    }
                    return true;
                  }
                })}
                internalError={errors.confirmPassword}
                suffixError={errors.confirmPassword && errors.confirmPassword.message}
              />
            </InputContainer>
            <InputContainer>
              <InputField
                name="name"
                placeholder="Nombre"
                type="text"
                reference={register({
                  required: { value: true, message: "Debes introducir este campo"},
                  minLength: { value: 2, message: "Mínimo 2 caracteres"},
                })}
                internalError={errors.name}
                suffixError={errors.name && errors.name.message}
                defaultValue={(editUser &&editUser.nombre) || ''}
              />
            </InputContainer>
            <InputContainer>
              <InputField
                name="lastName"
                placeholder="Apellido"
                type="text"
                reference={register({
                  required: { value: true, message: "Debes introducir este campo"},
                  minLength: { value: 2, message: "Mínimo 2 caracteres"},
                })}
                internalError={errors.lastName}
                suffixError={errors.lastName && errors.lastName.message}
                defaultValue={(editUser && editUser.apellido) || ''}
              />
            </InputContainer>
            <InputContainer>
              <InputField
                name="phone"
                placeholder="Teléfono"
                type="text"
                reference={register({
                  required: { value: true, message: "Debes introducir este campo"},
                  minLength: { value: 10, message: "Número a 10 digitos"},
                })}
                internalError={errors.phone}
                suffixError={errors.phone && errors.phone.message}
                defaultValue={(editUser && editUser.telefono) || ''}
              />
            </InputContainer>
            {isAdmin && (
              <Select
                placeholder='Tipo de usuario'
                onChange={(value) => setTipoUsuario(value.value)}
                defaultValue={userTypeOptions[tipoUsuario - 1]}
                options={userTypeOptions}
              />
            )}
          </LoginForm>
          {!editUser && (<ToLoginText>¿Ya tienes cuenta? <LoginLink onClick={() => history.push('/login')}>Iniciar sesión</LoginLink></ToLoginText>)}
          <ButtonsWrapper>
            <Button
              width='45%'
              type="submit"
            >
              {buttonText}
            </Button>
          </ButtonsWrapper>
        </LoginFormWrapper>
      )}
    </LoginWrapper>
  )
}

const mapStateTopProps = ({ currentUser }) => {
  const { user  } = currentUser
  return { user }
}

const mapDispatchToProps = dispatch => ({
  setUser: userActions.setUset(dispatch)
})

export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Register));
