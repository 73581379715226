import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Map, Marker } from 'google-maps-react'
import { useAlert } from 'react-alert';
import Load from '../../components/loader';
import NotFound from '../../components/not-found';
import PropertyResume from '../../components/PropertyResume'
import { mapStyle } from '../../common/utils/mapStyle'
import {
  ContentWrapper,
  PropertiesListContainer,
  PropertiesListWrapper,
  PropertiesListResults,
  MapContainer,
  LoaderWrapper,
  PropertiesBlock,
  SectionSubtitle
} from './Properties.styled'
import { getFavorite } from '../../common/utils/api-helpers';

const property1 = require('../../common/images/property1.png')
const markerIcon = require('../../common/images/circle.svg')


const PropertiesList = props => {
  const [inmueblesList, setInmueblesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bounds, setBounds] = useState(null);
  const [initialCenter, setInitialCenter] = useState({ lat: 19, lng: 19});
  const alert = useAlert();

  const propertyDetail = (id) => props.history.push({ pathname: `/detalle/${id}` })

  const _mapLoaded = (map) => map.setOptions({styles: mapStyle});

  useEffect(() => {
      async function getFavorites() {
          setLoading(true);
          try {
              const res = await getFavorite();
              if (res.data && res.data.result) {
                  console.log(res.data);
                  setInmueblesList(res.data.result);
                  if (res.data.result.length > 0) {
                      setInitialCenter({
                          lat: res.data.result[0].latitude,
                          lng: res.data.result[0].longitude,
                      })
                  }
              } else {
                alert.error('Ocurrió un error, intenta de nuevo más tarde');
              }
          } catch (e) {
              console.log(e);
              alert.error('Ocurrió un error, intenta de nuevo más tarde');
          } finally {
            setLoading(false);
          }
      }
      getFavorites();
  }, [])

  return (
    <PropertiesListContainer className="favorites">
      <ContentWrapper>
      <MapContainer className="favorites">
        <Map
          google={window.google}
          zoom={13}
          containerStyle={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}
          mapTypeControl={false}
          streetViewControl={false}
          fullscreenControl={false}
          initialCenter={initialCenter}
          center={initialCenter}
          onReady={(_, map) => _mapLoaded(map)}
          onIdle={(_, map) => !bounds && setBounds(map.getBounds())}
          onDragend={(_, map) => setBounds(map.getBounds())}
        >
          {inmueblesList.map(el => (
            <Marker
              key={el.inmuebleID}
              title={el.titulo}
              name={el.inmuebleID}
              icon={markerIcon}
              onClick={el => propertyDetail(el.name)}
              position={{lat: el.latitude, lng: el.longitude}}
            />
          ))}
        </Map>
      </MapContainer>
      <PropertiesListWrapper className="favorites">
        <SectionSubtitle className="favorites">
            Tus Favoritos
        </SectionSubtitle>
        <PropertiesBlock>
          {loading && (
            <LoaderWrapper>
              <Load />
            </LoaderWrapper>
          )}
          {!loading && (
            <>
              {inmueblesList.length > 0 && (
                <PropertiesListResults>
                  {inmueblesList.map(el => (
                      <PropertyResume
                          key={el.inmuebleID}
                          size="list"
                          precio={el.precio}
                          habitaciones={el.numeroHabitaciones}
                          banos={el.numeroBanos}
                          estacionamientos={el.numeroEstacionamiento}
                          mascotas={el.mascotas}
                          areaConstruida={el.areaConstruida}
                          onClick={() => propertyDetail(el.inmuebleID)}
                          image={el.mainImageURL || property1}
                          calle={el.calle}
                          numeroExterior={el.numeroExterior}
                          colonia={el.colonia}
                          ciudad={el.ciudad}
                          fromSponsoredList={el.fromSponsoredList}
                          inmuebleID={el.inmuebleID}
                          isFavorite={el.favorito}
                      />
                  ))}
                </PropertiesListResults>
              )}
              {inmueblesList.length === 0 && (
                <NotFound text={'Aún no tienes favoritos'}/>
              )}
            </>
          )}
        </PropertiesBlock>
      </PropertiesListWrapper>
      </ContentWrapper>
    </PropertiesListContainer>
  )
};

export default withRouter(PropertiesList)
