import React from "react";
import { withRouter } from "react-router"
import { useAlert } from 'react-alert';
import {
    MainWrapper,
    UserTable,
    TableData,
    TableHeader,
    TableRow,
    MainTitle,
    Button,
    FilterWrapper,
    SortWrapper,
    SortLabel,
    LoaderWrapper,
    TableWrapper,
    Input
} from '../styled';
import useUsersFilters from './useUsersFilters';
import Select from 'react-select';
import NotFound from '../../../components/not-found';
import TwoStepsButton from '../../../components/TwoStepsButton'
import Load from '../../../components/loader'
import Pagination from '../../../components/pagination';
import { USERSLIMIT } from '../../../common/utils/constants';
import { userStatus } from '../../../common/utils/api-helpers';

const selectStyle = {
    control: (provided) => ({
        ...provided,
        border: '1px solid #000',
        fontSize: '14px',
        cursor: 'pointer',
        border: '1px solid #C6C6C6',
    }),
    input: (provided) => ({
        ...provided,
        fontSize: '14px',
        fontFamily: 'Montserrat, sans-serif'
    }),
    option: (provided) => ({
        ...provided,
        cursor: 'pointer'
    })
};

const UsersList = (props) => {
    const alert = useAlert();
    const [{
        usersList,
        orderByFilter,
        userTypeFilter,
        activeFilter,
        nameFilter,
        loading,
        statusChange,
        totalPages,
        offset,
        actualPage
    }, dispatch] = useUsersFilters();

    const changeUserStatus = async (userId, newStatus) => {
        try {
            dispatch({ type: 'LOADING', payload: { loading: true } });
            await userStatus(userId, newStatus);
            dispatch({ type: 'STATUS_CHANGE', payload: { statusChange: !statusChange } });
            dispatch({ type: 'LOADING', payload: { loading: true } });
            alert.success(newStatus ? 'Usuario Activado' : 'Usuario desactivado');
        } catch (e) {
            alert.error('Ocurrió un error, intenta de nuevo más tarde');
            dispatch({ type: 'LOADING', payload: { loading: false } });
        }
    }

    return (
        <>
            <MainWrapper>
                <MainTitle>Lista de usuarios</MainTitle>
                <FilterWrapper>
                    <SortWrapper>
                        <SortLabel>Ordenar por:</SortLabel>
                        <Select
                            value={orderByFilter}
                            defaultValue={orderByFilter}
                            isSearchable={false}
                            options={[
                                { value: 1, label: "Nombre A-Z" },
                                { value: 0, label: "Nombre Z-A" },
                                { value: 3, label: "Apellido A-Z" },
                                { value: 2, label: "Apellido Z-A" },
                                { value: 5, label: "Correo A-Z" },
                                { value: 4, label: "Correo Z-A" },
                                { value: 7, label: "Tipo usuario A-Z" },
                                { value: 6, label: "Tipo usuario Z-A" },
                                { value: 8, label: "Status A-Z" },
                                { value: 9, label: "Status Z-A" },
                            ]}
                            styles={selectStyle}
                            onChange={(event) => dispatch({
                                type: 'CHANGE_FILTER',
                                payload: { orderByFilter: event, userTypeFilter, activeFilter, nameFilter }
                            })}
                        />
                    </SortWrapper>
                    <SortWrapper>
                        <SortLabel>Tipo de usuario:</SortLabel>
                        <Select
                            value={userTypeFilter}
                            defaultValue={userTypeFilter}
                            isSearchable={false}
                            onChange={(event) => dispatch({
                                type: 'CHANGE_FILTER',
                                payload: { orderByFilter, userTypeFilter: event, activeFilter, nameFilter }
                            })}
                            styles={selectStyle}
                            options={[
                                { value: '-1', label: "Todos" },
                                { value: '1', label: "Admin" },
                                { value: '3', label: "Broker" },
                                { value: '2', label: "Plataforma" },
                            ]}
                        />
                    </SortWrapper>
                    <SortWrapper>
                        <SortLabel>Status:</SortLabel>
                        <Select
                            value={activeFilter}
                            isSearchable={false}
                            defaultValue={activeFilter}
                            onChange={(event) => dispatch({
                                type: 'CHANGE_FILTER',
                                payload: { orderByFilter, userTypeFilter, activeFilter: event, nameFilter }
                            })}
                            styles={selectStyle}
                            options={[
                                { value: '-1', label: "Todos" },
                                { value: '0', label: "Innactivo" },
                                { value: '1', label: "Activo" },
                            ]}
                        />
                    </SortWrapper>
                    <SortWrapper>
                        <SortLabel>Nombre completo:</SortLabel>
                        <Input
                            value={nameFilter}
                            onChange={(event) => dispatch({
                                type: 'CHANGE_FILTER',
                                payload: { orderByFilter, userTypeFilter, activeFilter, nameFilter: event.target.value }
                            })}
                        />
                    </SortWrapper>
                    <SortWrapper onClick={() => { props.history.push({ pathname: '/register' }) }}>
                        <Button className='blue'>Crear usuario</Button>
                    </SortWrapper>
                </FilterWrapper>
                {loading && (
                    <LoaderWrapper>
                        <Load />
                    </LoaderWrapper>
                )}
                {!loading && usersList && usersList.length > 0 && (
                    <TableWrapper>
                        <UserTable>
                            <thead>
                                <TableRow>
                                    <TableHeader>
                                        Nombre
                                    </TableHeader>
                                    <TableHeader>
                                        Apellido
                                    </TableHeader>
                                    <TableHeader>
                                        Correo
                                    </TableHeader>
                                    <TableHeader style={{ width: '30px' }}>
                                        Tipo
                                    </TableHeader>
                                    <TableHeader style={{ width: '25px' }}>
                                        Status
                                    </TableHeader>
                                    <TableHeader />
                                    <TableHeader />
                                </TableRow>
                            </thead>
                            <tbody>
                                {usersList.map((user) => (
                                    <TableRow key={user.email}>
                                        <TableData>
                                            {user.nombre}
                                        </TableData>
                                        <TableData>
                                            {user.apellido}
                                        </TableData>
                                        <TableData>
                                            {user.email}
                                        </TableData>
                                        <TableData style={{ width: '20px' }}>
                                            {user.tipoUsuario}
                                        </TableData>
                                        <TableData style={{ width: '20px' }}>
                                            {user.activo ? 'Activo' : 'Inactivo'}
                                        </TableData>
                                        <TableData >
                                            <Button
                                                className="edit"
                                                onClick={() => {
                                                    props.history.push({
                                                        pathname: '/register',
                                                        state: { user }
                                                    })
                                                }}
                                            >
                                                Editar
                                            </Button>
                                        </TableData>
                                        <TableData>
                                            {user.activo ? (
                                                <TwoStepsButton
                                                    firstStepText="Desactivar"
                                                    cancelText="Cancelar"
                                                    confirmationText="Confirmar"
                                                    className="red"
                                                    onClick={() => changeUserStatus(user.usuarioID, 0)}
                                                />
                                            ) : (
                                                <TwoStepsButton
                                                    firstStepText="Activar"
                                                    cancelText="Cancelar"
                                                    confirmationText="Confirmar"
                                                    className="green"
                                                    onClick={() => changeUserStatus(user.usuarioID, 1)}
                                                />
                                            )}
                                        </TableData>
                                    </TableRow>
                                ))}
                            </tbody>
                        </UserTable>
                    </TableWrapper>
                )}
                {!loading && usersList && usersList.length === 0 && (
                    <NotFound />
                )}
            </MainWrapper>
            <Pagination
                totalPages={totalPages}
                actualPage={actualPage}
                offset={offset}
                propertyLimit={USERSLIMIT}
                changePage={(newOffset, newPage) => dispatch({
                    type: 'CHANGE_PAGE',
                    payload: { offset: newOffset, actualPage: newPage }
                })}
            />
        </>
    );
};

export default withRouter(UsersList);