import React from 'react'
import styled from 'styled-components'
import { ReactSVG } from 'react-svg'
import { Row, Grid } from 'react-flexbox-grid'

const AmenitiesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`

const AmenitiesWrapperDetailed = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

const Amenitie = styled.div`
  height: 20px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
`

const AmenitieDetailed = styled.div`
  height: 20px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-content: center;

  @media (max-width: 1200px) {
    margin-bottom: 10px;
    justify-content: flex-start;
  }
`

const AmenitieNumber = styled.span`
  font-size: 14px;
`

const AmenitieImage = styled(ReactSVG)`
  width: 20px;
  height: 20px;
  margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;
  }

  path {
    stroke: #000;
  }
`

const AmenitieNumberDetailed = styled.span`
  font-weight: 600;
  margin-left: 5px;
`;

const amenitiesImg = {
  bath: require('../common/images/bath.svg'),
  toilet: require('../common/images/toilet.svg'),
  bed: require('../common/images/bed.svg'),
  car: require('../common/images/car.svg'),
  pet: require('../common/images/pet.svg'),
  house: require('../common/images/house.svg'),
  area : require('../common/images/area.svg'),
  clock: require('../common/images/clock.svg')
}

const AmenitiesBar = ({
  detailed,
  habitaciones,
  banos,
  mediosBanos = null,
  estacionamientos,
  mascotas,
  areaConstruida,
  antiguedad,
  tipoInmueble
}) => (
  <Grid>
    { detailed ? (<>
      <Row>
        <AmenitiesWrapperDetailed style={{ marginBottom: '20px' }}>
          <AmenitieDetailed>
            <AmenitieImage src={amenitiesImg.house}/>Tipo de inmueble: <AmenitieNumberDetailed>{tipoInmueble === 1 ? 'Casa' : 'Departamento'}</AmenitieNumberDetailed>
          </AmenitieDetailed>
          <AmenitieDetailed>
            <AmenitieImage src={amenitiesImg.clock}/>Antiguedad: <AmenitieNumberDetailed>{antiguedad > 1 ? `${antiguedad} años` : `${antiguedad} año`}</AmenitieNumberDetailed>
          </AmenitieDetailed>
          <AmenitieDetailed>
            <AmenitieImage src={amenitiesImg.area}/>Area construida: <AmenitieNumberDetailed>{areaConstruida} m2</AmenitieNumberDetailed>
          </AmenitieDetailed>
        </AmenitiesWrapperDetailed>
      </Row>
      <Row>
        <AmenitiesWrapperDetailed>
          <AmenitieDetailed>
            <AmenitieImage src={amenitiesImg.bed}/>Habitaciones: <AmenitieNumberDetailed>{habitaciones}</AmenitieNumberDetailed>
          </AmenitieDetailed>
          <AmenitieDetailed>
            <AmenitieImage src={amenitiesImg.bath}/>Baños: <AmenitieNumberDetailed>{banos}</AmenitieNumberDetailed>
          </AmenitieDetailed>
          {mediosBanos > 0 && (
            <AmenitieDetailed>
              <AmenitieImage src={amenitiesImg.toilet}/>Medios baños: <AmenitieNumberDetailed>{mediosBanos}</AmenitieNumberDetailed>
            </AmenitieDetailed>
          )}
          <AmenitieDetailed>
            <AmenitieImage src={amenitiesImg.car}/>Estacionamientos: <AmenitieNumberDetailed>{estacionamientos}</AmenitieNumberDetailed>
          </AmenitieDetailed>
          <AmenitieDetailed>
            <AmenitieImage src={amenitiesImg.pet}/>Mascotas: <AmenitieNumberDetailed>{mascotas === 1 ? 'Si' : 'No'}</AmenitieNumberDetailed>
          </AmenitieDetailed>
        </AmenitiesWrapperDetailed>
      </Row>
    </>
      ) : (
      <Row>
        <AmenitiesWrapper>
          <Amenitie>
            <AmenitieImage src={amenitiesImg.bed}/><AmenitieNumber>{habitaciones}</AmenitieNumber>
          </Amenitie>
          <Amenitie>
            <AmenitieImage src={amenitiesImg.bath}/><AmenitieNumber>{banos}</AmenitieNumber>
          </Amenitie>
          <Amenitie>
            <AmenitieImage src={amenitiesImg.car}/><AmenitieNumber>{estacionamientos}</AmenitieNumber>
          </Amenitie>
          <Amenitie>
            <AmenitieImage src={amenitiesImg.pet}/><AmenitieNumber>{mascotas === 1 ? 'Si' : 'No'}</AmenitieNumber>
          </Amenitie>
          <Amenitie>
            <AmenitieNumber>{areaConstruida} m2</AmenitieNumber>
          </Amenitie>
        </AmenitiesWrapper>
      </Row>
    )}
  </Grid>
);

export default AmenitiesBar
