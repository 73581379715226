import styled from 'styled-components'
import { COLORS } from '../common/utils/constants';

export const Button = styled.button`
  background-color: ${props => props.secondary ? '#FFF' : COLORS.darkBlue};
  font-size: 'Montserrat', sans-serif;
  padding: 15px 0px;
  text-transform: uppercase;
  color: ${props => props.secondary ? COLORS.darkBlue : '#FFF'};
  border: 1px solid ${props => props.secondary ? COLORS.primaryBlue : 'transparent'};
  font-size: 16px;
  cursor: pointer;
  width: ${props => props.width || '100%'};
  margin: ${props => props.margin || '0'};
  margin-bottom: ${props => props.marginBottom || '0'};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;


  &:hover {
    background-color: ${props => props.secondary ? COLORS.darkBlue : COLORS.primaryOrange};
    color: ${COLORS.white};
  }

  &.disabled {
    background-color: #5e5d5c;
  }
`;

export default Button
