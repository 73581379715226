import React, { useEffect, useState } from 'react'
import { withRouter, useLocation } from 'react-router'
import { Range } from 'rc-slider'
import Autocomplete from 'react-google-autocomplete';
import { Map, Marker } from 'google-maps-react'
import { numberWithCommas } from '../../common/utils/helpers'
import Load from '../../components/loader';
import NotFound from '../../components/not-found';
import Pagination from '../../components/pagination';
import Filters from '../../components/filters';
import SelectComponent from '../../components/SelectComponent'
import PropertyResume from '../../components/PropertyResume'
import { COLORS } from '../../common/utils/constants';
import { mapStyle } from '../../common/utils/mapStyle'
import { PROPERTYLIMIT } from '../../common/utils/constants';
import {
  AutocompleteWrapper,
  ContentWrapper,
  PropertiesListContainer,
  PropertiesListWrapper,
  PropertiesListResults,
  PropertiesFilters,
  CityFilterWrapper,
  FilterWrapper,
  BudgetWrapper,
  BudgetNumbers,
  RangeWrapper,
  SortWrapper,
  MapContainer,
  ButtonWraper,
  BudgetLabel,
  BudgetSortWrapper,
  LoaderWrapper,
  SelectionWrapper,
  Selection,
  SortLabel,
  BudgetHighlight,
  PropertiesBlock,
} from './Properties.styled'
import usePropertyFilters, { changeValues } from './usePropertyFilters';

const property1 = require('../../common/images/property1.png')
const markerIcon = require('../../common/images/circle.svg')


const PropertiesList = props => {
  const query = new URLSearchParams(useLocation().search);
  const min = query.get("min");
  const max = query.get("max");
  const lat = query.get("lat");
  const lng = query.get("lng");
  const age = query.get("age")
  const area = query.get("area")
  const address = query.get("address");
  const propertyType = query.get("propertyType");
  const offset = parseInt(query.get("offset")) || 0;
  const bedrooms = parseInt(query.get("bedrooms")) || 0;
  const bathrooms = parseInt(query.get("bathrooms")) || 0;
  const halfBathrooms = parseInt(query.get("halfBathrooms")) || 0;
  const categoryType = query.get("categoryType");
  const actualPage = parseInt(query.get("actualPage")) || 1;
  const parkingLots = parseInt(query.get("parkingLots")) || 0;
  const pets = query.get("pets") ? query.get("pets") === 'true' : null;
  const orderBy = query.get("orderBy") || 0;
  const [ sticky, setSticky ] = useState(false);

  const [{
    inmueblesList,
    hoveredMarker,
    bounds,
    totalPages,
    loading,
    showFilter,
  }, dispatch] = usePropertyFilters({
    min, max, offset, categoryType, propertyType,
    bedrooms, bathrooms, halfBathrooms, parkingLots, pets, area, age, orderBy,
    lat, lng, actualPage,
  });

  const scrollHandle = () => {
    if(window.pageYOffset >= 80) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  }

  useEffect(() => {
    if(window.innerWidth >= 769) {
      const watchScroll = () => {
        window.addEventListener("scroll", scrollHandle);
      }
      watchScroll();
      return () => {
        window.removeEventListener("scroll", scrollHandle);
      }
    }
  }, []);

  const propertyDetail = (id) => props.history.push({
    pathname: `/detalle/${id}`,
  })

  const _mapLoaded = (map) => map.setOptions({styles: mapStyle});

  return (
    <PropertiesListContainer>
      <PropertiesFilters sticky={sticky}>
        <CityFilterWrapper>
          <SelectionWrapper>
            <Selection
              onClick={() => {
                changeValues(
                  {
                    min, max: 20000, categoryType: '1', lat, lng, address, offset: 0, actualPage: 1,
                    propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots, pets, area, age, orderBy
                  },
                  props.history
                );
              }}
              className={categoryType === '1' ? 'active' : 'inactive'}>
              Renta
            </Selection>
            <Selection
              onClick={() => {
                changeValues(
                  {
                    min, max: 100000000, categoryType: '2', lat, lng, address, offset: 0, actualPage: 1,
                    propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots, pets, area, age, orderBy
                  },
                  props.history
                );
              }}
              className={categoryType === '2' ? 'active' : 'inactive'}>
              Venta
            </Selection>
            <Selection
              onClick={() => {
                changeValues(
                  {
                    min, max: 100000000, categoryType: '3', lat, lng, address, offset: 0, actualPage: 1,
                    propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots, pets, area, age, orderBy
                  },
                  props.history
                );
              }}
              className={categoryType === '3' ? 'active' : 'inactive'}>
              Preventa
            </Selection>
          </SelectionWrapper>
          <AutocompleteWrapper>
            <Autocomplete
              style={{
                fontSize: 16,
                fontFamily: 'Montserrat, sans-serif',
                padding: '0 10px',
                border: `solid 2px ${COLORS.darkBlue}`,
                boxSizing: 'border-box',
                height: '32px',
                width: '100%',
              }}
              onPlaceSelected={(place) => {
                changeValues(
                  {
                    min, max, categoryType, lat: place.geometry.location.lat(), lng: place.geometry.location.lng(), address: place.formatted_address,
                    offset: 0, actualPage:1, propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots, pets, area, age, orderBy
                  },
                  props.history
                );
                dispatch({ type: 'CHANGE_BOUNDS', payload: { bounds: null }});
              }}
              placeholder={'Introduce una dirección'}
              defaultValue={address}
              types={['address']}
              componentRestrictions={{country: "mx"}}
            />
          </AutocompleteWrapper>
          <BudgetWrapper>
            <RangeWrapper>
              <BudgetNumbers>
                <BudgetHighlight>${numberWithCommas(min)}</BudgetHighlight>
                <BudgetLabel>
                  Precio
                </BudgetLabel>
                <BudgetHighlight> ${numberWithCommas(max)}</BudgetHighlight>
              </BudgetNumbers>
              <Range
                min={0}
                max={categoryType === '1' ? 50000 : 100000000}
                value={[min, max]}
                step={500}
                onChange={(range) => {
                  changeValues(
                    {
                      min: range[0], max: range[1], categoryType, lat, lng, address, offset: 0, actualPage: 1,
                      propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots, pets, area, age, orderBy
                    }, props.history
                  );
                }}
                railStyle={{ border: 'solid 2px #EEE' }}
                handleStyle={[
                  { border: `solid 2px ${COLORS.darkBlue}`, width: '16px', height: '16px'  },
                  { border: `solid 2px ${COLORS.darkBlue}`, width: '16px', height: '16px'  }
                ]}
                trackStyle={[{ border: `solid 2px ${COLORS.darkBlue}` }]}/>
            </RangeWrapper>
          </BudgetWrapper>
          <FilterWrapper
            onClick={() => dispatch({ type: 'CHANGE_FILTER', payload: { showFilter: !showFilter }})}
          >
            Filtrar
          </FilterWrapper>
          <Filters
            show={showFilter}
            onSubmit={(propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots, pets, area, age) => {
              changeValues(
                {
                  min, max, categoryType, lat, lng, address, offset: 0, actualPage: 1,
                  propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots, pets, area, age, orderBy
                },
                props.history
              );
              dispatch({ type: 'CHANGE_FILTER', payload: { showFilter: false }});
            }}
            initialParams={{propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots, pets, area, age}}
          />
        </CityFilterWrapper>
      </PropertiesFilters>
      <ContentWrapper>
      <MapContainer sticky={sticky}>
        <Map
          google={window.google}
          zoom={13}
          containerStyle={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}
          mapTypeControl={false}
          streetViewControl={false}
          fullscreenControl={false}
          initialCenter={{lat, lng}}
          center={{ lat, lng}}
          onReady={(_, map) => _mapLoaded(map)}
          onIdle={(_, map) => !bounds && dispatch({ type: 'CHANGE_BOUNDS', payload: { bounds: map.getBounds() }})}
          onDragend={(_, map) =>{
            changeValues(
              {
                min, max, categoryType, lat: map.center.lat(), lng: map.center.lng(), address, offset: 0, actualPage: 1,
                propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots, pets, area, age, orderBy
              },
              props.history,
            );
            dispatch({ type: 'CHANGE_BOUNDS', payload: { bounds: map.getBounds() }});
          }}
        >
          {inmueblesList.map(el => (
            <Marker
              key={el.inmuebleID}
              title={el.titulo}
              name={el.inmuebleID}
              icon={markerIcon}
              onMouseover={el => !hoveredMarker && dispatch({ type: 'CHANGE_MARKER', payload: { hoveredMarker: el.name }})}
              onMouseout={_ => dispatch({ type: 'CHANGE_MARKER', payload: { hoveredMarker: null }})}
              onClick={el => propertyDetail(el.name)}
              position={{lat: el.latitude, lng: el.longitude}} />
          ))}
        </Map>
      </MapContainer>
      <PropertiesListWrapper sticky={sticky}>
        <BudgetSortWrapper>
          <SortWrapper>
            <SortLabel>Ordenar por:</SortLabel>
              <SelectComponent
                    value={orderBy}
                    onChange={(event) => {
                      changeValues(
                        {
                          min, max, categoryType, lat: lat, lng: lng, address: address,
                          offset: 0, actualPage:1, propertyType, bedrooms, bathrooms, halfBathrooms, 
                          parkingLots, pets, area, age, orderBy: event.target.value
                        },
                        props.history
                      );
                    }}
                    options={[
                        { value: 0, label: "Ninguno"},
                        { value: 1, label: "Más nuevos"},
                        { value: 4, label: "Más viejos"},
                        { value: 2, label: "Menor precio"},
                        { value: 3, label: "Mayor precio"},
                    ]}
                />
            </SortWrapper>
          </BudgetSortWrapper>
        <PropertiesBlock>
          {loading && (
            <LoaderWrapper>
              <Load />
            </LoaderWrapper>
          )}
          {!loading && (
            <>
              {inmueblesList.length > 0 && (
                <PropertiesListResults>
                  {inmueblesList.map(el => (
                    <PropertyResume
                      key={el.inmuebleID}
                      size="list"
                      precio={el.precio}
                      habitaciones={el.numeroHabitaciones}
                      banos={el.numeroBanos}
                      estacionamientos={el.numeroEstacionamiento}
                      mascotas={el.mascotas}
                      hovered={hoveredMarker === el.inmuebleID}
                      areaConstruida={el.areaConstruida}
                      onClick={() => propertyDetail(el.inmuebleID)}
                      image={el.mainImageURL || property1}
                      calle={el.calle}
                      numeroExterior={el.numeroExterior}
                      colonia={el.colonia}
                      ciudad={el.ciudad}
                      fromSponsoredList={el.fromSponsoredList}
                      inmuebleID={el.inmuebleID}
                      isFavorite={el.favorito}
                    />
                  ))}
                </PropertiesListResults>
              )}
              {inmueblesList.length === 0 && (
                <NotFound />
              )}
            </>
          )}
          {inmueblesList.length > 0 && (
            <ButtonWraper>
              <Pagination
                totalPages={totalPages}
                actualPage={actualPage}
                offset={offset}
                propertyLimit={PROPERTYLIMIT}
                changePage={(newOffset, newPage) => changeValues(
                  {
                    min, max, categoryType, lat, lng, address, offset: newOffset, actualPage: newPage,
                    propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots, pets, area, age, orderBy
                  },
                  props.history
                )}
              />
            </ButtonWraper>
          )}
        </PropertiesBlock>
      </PropertiesListWrapper>
      </ContentWrapper>
    </PropertiesListContainer>
  )
}

export default withRouter(PropertiesList)
