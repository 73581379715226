import axios from 'axios'

class AuthService {

  login(email, password) {
    return axios.post('/login', {
      email,
      password
    }).then(response => {
      if (response.data.token) {
        localStorage.setItem("flashome-user", JSON.stringify(response.data));
        return response.data
      } else {
        return 'Error'
      }
    })
  }

  logout() {
    localStorage.removeItem("flashome-user")
  }

  registerUser(
    email,
    password,
    nombre,
    apellido,
    fechaNacimiento,
    sexoID,
    telefono) {
    return axios.post('/usuarios', {
      email,
      nombre,
      apellido,
      fechaNacimiento,
      sexoID,
      telefono,
      password
    })
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('flashome-user'));;
  }
}

export default new AuthService()
