import React from 'react';
import Loader from 'react-loader-spinner'
import { COLORS } from '../../common/utils/constants';
import PropTypes from 'prop-types';
import {
    LoaderWrapper,
} from './styled';

const Load = ({ height, width, marginTop }) => (
    <LoaderWrapper marginTop={marginTop}>
        <Loader
            type="Grid"
            color={COLORS.primaryBlue}
            secondaryColor={COLORS.primaryBlue}
            height={height}
            width={width}
        />
    </LoaderWrapper>
);

Load.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    marginTop: PropTypes.number,
}

Load.defaultProps = {
    height: 100,
    width: 100,
    marginTop: 0,
};

export default Load;