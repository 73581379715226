import React from 'react';
import { ContentWrapper } from './styled';

const PP = _ => (
  <ContentWrapper>
    <h1>Politicas de Privacidad</h1>
    <p>WIP</p>
  </ContentWrapper>
);

export default PP;
