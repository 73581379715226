import styled from "styled-components";
import { COLORS } from "../../common/utils/constants";

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 77vh;
    table, th, td {
        border-collapse: collapse;
    }
`;

export const FilterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 95%;
    margin: 0 auto;
    flex-wrap: wrap;
`;

export const MainTitle = styled.h1`
    margin 10px auto 10px;
    width: 95%;
`;

export const UserTable = styled.table`
    border-radius: 10px;
    overflow: hidden;
`;

export const TableWrapper = styled.div`
    width: 95%;
    margin: 30px auto;
    border: 1px solid #dadada;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: 768px) {
        margin:  10px;
        overflow: scroll;
    }
`

export const TableRow = styled.tr`
    border-bottom: 1px solid #dadada;
    cursor: pointer;

    &:hover {
        background: #ececff;
    }
`;

export const TableHeader = styled.th`
    width: 15%;
    background: #022169;
    color: #FFF;
    font-weight: 500;
    padding: 15px;
    text-align: left;
    font-size: 16px;

    &.id {
        width: 20px;
    }
`;

export const TableData = styled.td`
    width: 15%;
    padding: 15px;
    font-size: 14px;
    font-weight: 500;

    &.id{
        width: 20px;
    }

    &.edit {
       text-align: center;
       color: #fcba03;
    }

    &.delete {
        text-align: center;
        color: red;
     }
`;

export const Button = styled.button`
     width: 100%;
     border: none;
     color: white;
     border-radius: 5px;
     padding: 5px;
     cursor: pointer;

     &.edit {
        text-align: center;
        background-color: #ffc107;
        color: #000;

        &:hover {
            background-color: #BF9106
        }
     }
 
     &.delete {
        text-align: center;
        background-color: #dc3545;

        &:hover {
           background-color: #B5492B;
        }
     }

     &.activate {
       text-align: center;
       background-color: #28a745;

       &:hover {
           background: #518C4A;
       }
    }

     &.blue {
        text-align: center;
        background-color:  ${COLORS.primaryBlue};
        height: 34px;
        padding: 0 10px;

        &:hover {
            background: #183463;
        }
     }

    @media (max-width: 768px) {
        &.blue {
            margin-top: 10px;
        }
    }
`;

export const SortWrapper = styled.div`
    display: flex;
    font-size: 14px;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-end;
    width: 180px;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
        margin: 0;
    }  
`;

export const SortLabel = styled.p`
  white-space: nowrap;
  margin: 5px 0;

  &.pointer {
    cursor: pointer;
    color: blue;
  }
`

export const LoaderWrapper = styled.div`
    height: 300px;
    display: flex;
    justify-content: center;
`

export const Input = styled.input`
    border: none;
    box-sizing: border-box;
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
    font-size: 14px;
    height: 34px;
    padding: 15px 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #C6C6C6;
`