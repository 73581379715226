import styled from 'styled-components'
import React from "react";
import { COLORS } from '../common/utils/constants';

const Select = styled.select`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  background-color: ${COLORS.white};
  color: ${COLORS.primaryOrange};
  border: none;
  border-radius: 3px;
  border: 1px solid ${COLORS.primaryOrange};
  -webkit-appearance: none;
  -webkit-border-radius: 3px;
  margin-left: 10px;
  font-size: 15px;
  padding: 3px  10px;
  cursor: pointer;
  transition: all 0.25s;
`;

const Option = styled.option``

const SelectComponent = ({ options, onChange, value}) => {
    return(
        <Select
            value={value}
            onChange={onChange}
        >
            {options.map(option => (
                <Option value={option.value} key={option.label}>
                    {option.label}
                </Option>
            ))}
        </Select>
    );
};

export default SelectComponent;
