import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  MapContainer,
  FieldWrapper,
  ButtonWrapper,
  GeneralWrapper,
  DirectionsWrapper,
  GeneralInfoWrapper,
  SelectionWrapper,
  Selection,
  StreetNumber,
  Space,
  MapPin,
  MapInstruction,
  SelectErrorMessage
} from './RegisterProperty.styled'
import { Map } from 'google-maps-react'
import InputField from '../../components/InputField'
import Counter from '../../components/Counter'
import Button from '../../components/Button'
import ToggleButton from '../../components/ToggleButton'
import Autocomplete from 'react-google-autocomplete';
import { mapStyle } from '../../common/utils/mapStyle'
import Select from 'react-select'
import { propertiesActions } from '../../store/properties/reducer'
import isEmpty from 'lodash/isEmpty';
import { useForm, Controller } from "react-hook-form";

const pin = require('../../common/images/location.svg')
const clock = require('../../common/images/clock.svg')
const bed = require('../../common/images/bed.svg')
const bath = require('../../common/images/bath.svg')
const halfBath = require('../../common/images/toilet.svg')
const car = require('../../common/images/car.svg')
const area = require('../../common/images/area.svg')
const pet = require('../../common/images/pet.svg')

const GeneralInfo = ({ changeStep, generalInfo, setGeneralInfo, setGeneralInfoNoField, isEditValue }) => {
  const { register, handleSubmit, errors, control, reset } = useForm();
  const [ mapLat, setMapLat ] = useState(19.432446)
  const [ mapLng, setMapLng ] = useState(-99.133204)
  const [ address, setAddress ] = useState('')
  const mapRef = useRef()
  const google = window.google
  const geocoder = new google.maps.Geocoder();

  const _mapLoaded = (_, map) => {
    map.setOptions({
      styles: mapStyle
    })
  }

  const options = [
    { value: 1, label: 'Casa' },
    { value: 2, label: 'Departamento' },
  ]

  const setAddressInfo = (addressInfo) => {
    const finalLocationObject = {};
    setAddress(addressInfo.formatted_address)
    addressInfo.address_components.forEach((el) => finalLocationObject[el.types[0] === 'political' && el.types[1] ? el.types[1] : el.types[0]] = el.long_name);
    const dataObject = {
      street_number: finalLocationObject['street_number'] || '',
      route: finalLocationObject['route'] || '',
      neighborhood: finalLocationObject['neighborhood'] || finalLocationObject['sublocality'] || finalLocationObject['sublocality_level_1'] || '',
      locality: finalLocationObject['locality'] || '',
      postal_code: finalLocationObject['postal_code'] || '',
    };
    setGeneralInfoNoField(dataObject);
    reset(dataObject);
  }

  const mapDragged = (_, map) => {
    setGeneralInfo('lat', map.getCenter().lat())
    setGeneralInfo('lng', map.getCenter().lng())
    setMapLat(map.getCenter().lat())
    setMapLng(map.getCenter().lng())
    geocoder.geocode({ location: { lat: map.getCenter().lat(), lng: map.getCenter().lng() }}, (results, status) => {
      if(status === 'OK'){
        setAddressInfo(results[0])
      }
    })
  }

  const submit = (data) => {
    data.propertyType = data.propertyType.value;
    setGeneralInfoNoField(data);
    changeStep();
  };

  useEffect(() => {
    generalInfo.propertyType = options[generalInfo.propertyType - 1]
    reset(generalInfo);
  }, [isEditValue]);

  return(
    <form onSubmit={handleSubmit(submit)}>
      <GeneralWrapper>
        <DirectionsWrapper>
          <SelectionWrapper>
            <Selection
              onClick={() => setGeneralInfo('categoryType', 1)}
              className={generalInfo.categoryType === 1 ? 'active' : 'inactive'}>
              Renta
            </Selection>
            <Selection
              onClick={() => setGeneralInfo('categoryType', 2)}
              className={generalInfo.categoryType ===  2 ? 'active' : 'inactive'}>
              Venta
            </Selection>
            <Selection
              onClick={() => setGeneralInfo('categoryType', 3)}
              className={generalInfo.categoryType === 3 ? 'active' : 'inactive'}>
              Preventa
            </Selection>
          </SelectionWrapper>
          <Autocomplete
            style={{
              width: '100%',
              fontFamily: 'Montserrat, sans-serif',
              fontSize: 14,
              height: '38px',
              padding: '10px',
              border: 'solid 1px #C6C6C6',
              boxSizing: 'border-box',
              marginBottom: '10px',
              borderRadius: '5px'
            }}
            onPlaceSelected={(place) => {
              setAddressInfo(place)
              mapRef.current.map.setCenter(new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()))
              setGeneralInfo('lat', place.geometry.location.lat())
              setGeneralInfo('lng', place.geometry.location.lng())
            }}
            types={['address']}
            value={address}
            onChange={e => setAddress(e.target.value)}
            placeholder={isEmpty(generalInfo.route) ? 'Introduce una calle, número y colonia' : 'Cambiar la dirección'}
            componentRestrictions={{ country: "mx" }}
          />
          <StreetNumber>
            <InputField
              name="route"
              type="text"
              reference={register({
                required: { value: true , message: "Debes introducir este campo"},
                minLength: { value: 2, message: "Mínimo 2 caracteres"},
              })}
              placeholder='Calle *'
              internalError={errors.route}
              suffixError={errors.route && errors.route.message}
            />
            <Space />
            <InputField
              name="street_number"
              type="text"
              reference={register({
                required: { value: true , message: "Debes introducir este campo"},
              })}
              internalError={errors.street_number}
              suffixError={errors.street_number && errors.street_number.message}
              placeholder='Número *'
            />
          </StreetNumber>
          <StreetNumber>
            <InputField
              name="neighborhood"
              type="text"
              reference={register({
                required: { value: true , message: "Debes introducir este campo"},
                minLength: { value: 2, message: "Mínimo 2 caracteres"},
              })}
              internalError={errors.neighborhood}
              suffixError={errors.neighborhood && errors.neighborhood.message}
              placeholder='Colonia *'
            />
            <Space />
            <InputField
              name="locality"
              type="text"
              reference={register({
                required: { value: true , message: "Debes introducir este campo"},
                minLength: { value: 2, message: "Mínimo 2 caracteres"},
              })}
              internalError={errors.locality}
              suffixError={errors.locality && errors.locality.message}
              placeholder='Ciudad *'
            />
          </StreetNumber>
          <StreetNumber>
            <InputField
              name="postal_code"
              type="text"
              reference={register({
                required: { value: true , message: "Debes introducir este campo"},
                minLength: { value: 2, message: "Mínimo 2 caracteres"},
              })}
              internalError={errors.postal_code}
              suffixError={errors.postal_code && errors.postal_code.message}
              placeholder='Código Postal *'
            />
          </StreetNumber>
          <MapInstruction>Para ajustar la dirección mueve el mapa </MapInstruction>
          <MapContainer>
            <Map
              google={google}
              zoom={15}
              containerStyle={{
                position: 'relative',
                width: '100%',
                height: '333px'
              }}
              ref={mapRef}
              mapTypeControl={false}
              streetViewControl={false}
              fullscreenControl={false}
              initialCenter={{ lat: mapLat, lng: mapLng }}
              onDragend={mapDragged}
              onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
            >
            </Map>
            <MapPin
              src={pin}
            />
          </MapContainer>
        </DirectionsWrapper>
        <GeneralInfoWrapper>
          <FieldWrapper>
            <Controller
              as={Select}
              name="propertyType"
              placeholder='Tipo de inmueble'
              options={options}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: `1px solid ${errors.propertyType ? '#FF3429' : '#C6C6C6'}`,
                  borderRadius: '5px',
                  fontSize: '14px'
                })
              }}
              rules={{ required: true }}
              control={control}
            />
            {errors.propertyType && (
              <SelectErrorMessage>Debes introducir este campo</SelectErrorMessage>
            )}
          </FieldWrapper>
          <FieldWrapper>
            <InputField
              name="age"
              type="number"
              reference={register({
                required: { value: true , message: "Debes introducir este campo"},
              })}
              internalError={errors.age}
              suffixError={errors.age && errors.age.message}
              icon={clock}
              suffix='años'
              placeholder='Antigüedad *'
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputField
              icon={area}
              suffix='m2'
              placeholder='Área construida *'
              name="area"
              type="number"
              reference={register({
                required: { value: true , message: "Debes introducir este campo"},
              })}
              internalError={errors.area}
              suffixError={errors.area && errors.area.message}
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputField
              icon={area}
              suffix='m2'
              name="groundArea"
              type="number"
              reference={register({
                required: { value: true , message: "Debes introducir este campo"},
              })}
              internalError={errors.groundArea}
              suffixError={errors.groundArea && errors.groundArea.message}
              placeholder='Área terreno'
            />
          </FieldWrapper>
          <FieldWrapper>
            <Counter
              onChange={(value) => setGeneralInfo('bedrooms', value)}
              label='Recámaras'
              icon={bed}
              minValue={1}
              valueProp={generalInfo.bedrooms}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Counter
              onChange={(value) => setGeneralInfo('bathrooms', value)}
              label='Baños'
              icon={bath}
              minValue={1}
              valueProp={generalInfo.bathrooms}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Counter
              onChange={(value) => setGeneralInfo('halfBathrooms', value)}
              label='Medios Baños'
              icon={halfBath}
              valueProp={generalInfo.halfBathrooms}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Counter
              onChange={(value) => setGeneralInfo('parkingLots', value)}
              label='Estacionamiento'
              icon={car}
              valueProp={generalInfo.parkingLots}
            />
          </FieldWrapper>
          <FieldWrapper>
            <ToggleButton
              onChange={(value) => setGeneralInfo('pets', value)}
              label={'Mascotas'}
              icon={pet}
              valueProp={generalInfo.pets}
            />
          </FieldWrapper>
          <ButtonWrapper>
            <Button type="submit">Continuar</Button>
          </ButtonWrapper>
        </GeneralInfoWrapper>
      </GeneralWrapper>
    </form>
  )
}

const mapStateTopProps = ({ properties }) => {
  const { generalInfo } = properties
  return { generalInfo }
}

const mapDispatchToProps = dispatch => ({
  setGeneralInfo: propertiesActions.setGeneralInfo(dispatch),
  setGeneralInfoNoField: propertiesActions.setGeneralInfoNoField(dispatch),
})

export default connect(mapStateTopProps, mapDispatchToProps)(GeneralInfo)
