import styled from 'styled-components'
import Button from '../../components/Button'

export const ProfileWrapper = styled.div`
    width: 100%;
    max-width: 600px;
    display: flex;
    min-height: calc(100vh - 160px);
    margin: 20px auto 0;
    flex-direction: column;

    @media (max-width: 768px) {
        box-sizing: border-box;
        padding: 15px;
    }
`;

export const ProfileHeadline = styled.h1`
    font-size: 22px;
`

export const FormWrapper = styled.form`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 15px;
    margin-bottom: 30px;
    padding: 15px;
    border: 1px solid #dadada;
    border-radius: 10px;

    @media (max-width: 768px) {
        display: block;
        box-sizing: border-box;
    }
`;

export const InputContainer = styled.div`
    width: 100%;

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

export const ConfirmButton = styled(Button)`
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 10px 0;
    font-size: 14px;
`;