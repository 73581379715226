/* eslint-disable no-undef*/ 
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { registerCard } from '../../common/utils/api-helpers';
import useOpenPay from '../../common/utils/useOpenPay';
import { useAlert } from 'react-alert';
import Load from '../loader';
import InputField from '../InputField';
import Button from '../Button';
import {
  MainWrapper,
  CardDataWrapper,
  Title,
  Form,
  CloseButton,
  ExpirationWrapper,
  Separator,
  CardImage,
  CardTitle,
  CardWrapper,
  Card,
} from './styled';

const closeIcon = require('../../common/images/close_blue.png')
const cards1 = require('../../common/images/cards1.png')
const cards2 = require('../../common/images/cards2.png')

const CardForm = ({ closeModal }) => {
  const { register, handleSubmit, errors } = useForm();
  const { deviceSessionId } = useOpenPay();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      OpenPay.token.create(
        {
          card_number: data.cardNumber,
          holder_name: data.name,
          expiration_year: data.year,
          expiration_month: data.month,
          cvv2: data.cvv,
        },
        async (response) => {
          const id = response.data.id;
          await registerCard(id, deviceSessionId);
          setLoading(false);
          alert.success('Tarjeta registrada con éxito');
          closeModal();
        },
        (response) => {
          console.log('Error', response);
          alert.error('Ocurrió un error, intenta de nuevo más tarde');
          setLoading(false);
        }
      ); 
    } catch(e) {
      alert.error('Ocurrió un error, intenta de nuevo más tarde');
      console.log(e);
    }
  };

  return(
    <MainWrapper>
      {loading ? (
        <Load />
      ) : (
        <>
          <CloseButton onClick={closeModal} src={closeIcon}/>
          <CardDataWrapper>
            <Title>Introduce los datos de la tarjeta</Title>
            <CardWrapper>
                <Card>
                    <CardTitle>Crédito</CardTitle>
                    <CardImage src={cards1} />
                </Card>
                <Card>
                    <CardTitle>Débito</CardTitle>
                    <CardImage src={cards2} />
                </Card>
            </CardWrapper>
            <Form onSubmit={handleSubmit(onSubmit)} id="payment-form">
              <InputField
                name="name"
                type="text"
                placeholder="Nombre del titular"
                reference={register({ required: true })}
                internalError={errors.name}
                suffixError={"Debes introducir este campo"}
                withMargin
              />
              <InputField
                name="cardNumber"
                type="text"
                placeholder="Número de tarjeta"
                reference={register({
                    required: { value: true, message: "Debes introducir este campo"},
                    minLength:{ value: 16, message: "Número de tarjeta inválido"},
                    maxLength:{ value: 16, message: "Número de tarjeta inválido"},
                    validate: (value) => {
                      if (!OpenPay.card.validateCardNumber(value)){
                        return "Número de tarjeta inválido";
                      }
                      return true;
                    }
                })}
                internalError={errors.cardNumber}
                suffixError={errors.cardNumber && errors.cardNumber.message}
                withMargin
              />
              <ExpirationWrapper>
                <InputField
                    name="month"
                    type="text"
                    placeholder="Mes de expiración"
                    reference={register({
                        required: { value: true},
                        minLength:{ value: 1, message: "Mes inválido"},
                        maxLength:{ value: 2, message: "Mes inválido"},
                        min:{ value: 1, message: "Mes inválido"},
                        max:{ value: 12, message: "Mes inválido"},
                    })}
                    internalError={errors.month}
                    suffixError={errors.month && errors.month.message}
                    withMargin
                />
                <Separator />
                <InputField
                    name="year"
                    placeholder="Año de expiración"
                    reference={register({
                        required: { value: true},
                        minLength:{ value: 2, message: "Dos dígitos"},
                        maxLength:{ value: 2, message: "Dos dígitos"},
                    })}
                    internalError={errors.year}
                    suffixError={errors.year && errors.year.message}
                    withMargin
                />
              </ExpirationWrapper>
              <InputField
                name="cvv"
                placeholder="Código de seguridad"
                reference={register({
                    required: { value: true, message: "Debes introducir este campo"},
                    minLength:{ value: 3, message: "CVV inválido"},
                    maxLength:{ value: 4, message: "CVV inválido"},
                    validate: (value) => {
                      if (!OpenPay.card.validateCVC(value)){
                        return "CVV inválido";
                      }
                      return true;
                    }
                })}
                internalError={errors.cvv}
                suffixError={errors.cvv && errors.cvv.message}
                withMargin
              />
              <Button type="submit">
                Agregar
              </Button>
            </Form>
          </CardDataWrapper>
        </>
      )}
    </MainWrapper>
  );
};

export default CardForm;
