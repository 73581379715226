import React from "react";
import { withRouter } from "react-router"
import { useAlert } from 'react-alert';
import {
  Button,
  MainWrapper,
  UserTable,
  TableData,
  TableHeader,
  TableRow,
  MainTitle,
  FilterWrapper,
  SortWrapper,
  SortLabel,
  LoaderWrapper,
  TableWrapper,
  Input
} from '../styled';
import usePropertiesFilter from './usePropertiesFilters';
import Select from 'react-select';
import SelectComponent from '../../../components/SelectComponent'
import NotFound from '../../../components/not-found';
import TwoStepsButton from '../../../components/TwoStepsButton'
import Load from '../../../components/loader'
import Pagination from '../../../components/pagination';
import { USERSLIMIT } from '../../../common/utils/constants';
import { getAddress } from '../../../common/utils/helpers'
import { changeUserPropertyStatus } from '../../../common/utils/api-helpers';

const selectStyle = {
  control: (provided) => ({
      ...provided,
      border: '1px solid #000',
      fontSize: '14px',
      cursor: 'pointer',
      border: '1px solid #C6C6C6',
  }),
  input: (provided) => ({
      ...provided,
      fontSize: '14px',
      fontFamily: 'Montserrat, sans-serif'
  }),
  option: (provided) => ({
      ...provided,
      cursor: 'pointer'
  })
};

const UserPropertiesList = (props) => {
  const alert = useAlert();
  const [{
    propertyList,
    sponsoredFilter,
    destacadoFilter,
    statusFilter,
    loading,
    inmuebleID,
    totalPages,
    offset,
    actualPage,
    statusChange
  }, dispatch] = usePropertiesFilter();

  const getDestacado = (statusDestacado) => {
    switch (statusDestacado) {
      case 0:
        return "No destacado";
      case 1:
        return "Destacado búsqueda";
      case 2:
        return "Destacado home";
      default:
        return "Otro";
    }
  };

  const changeStatus = async (inmuebleID, newStatus) => {
    try {
      dispatch({ type: 'LOADING', payload: { loading: true } });
      await changeUserPropertyStatus(inmuebleID, newStatus);
      dispatch({ type: 'STATUS_CHANGE', payload: { statusChange: !statusChange } });
      dispatch({ type: 'LOADING', payload: { loading: true } });
      alert.success(newStatus ? 'Anuncio activado' : 'Anuncio desactivado');
    } catch (e) {
      alert.error('Ocurrió un error, intenta de nuevo más tarde');
      dispatch({ type: 'LOADING', payload: { loading: false } });
    }
  }

  return (
    <>
      <MainWrapper>
        <MainTitle>Tus anuncios</MainTitle>
        <FilterWrapper>
          <SortWrapper>
            <SortLabel>Patrocinados:</SortLabel>
            <Select
              value={sponsoredFilter}
              defaultValue={sponsoredFilter}
              onChange={(event) => dispatch({
                type: 'CHANGE_FILTER',
                payload: { sponsoredFilter: event, destacadoFilter, statusFilter, inmuebleID }
              })}
              styles={selectStyle}
              options={[
                { value: '-1', label: "Todos" },
                { value: '0', label: "No" },
                { value: '1', label: "Sí" },
              ]}
            />
          </SortWrapper>
          <SortWrapper>
            <SortLabel>Destacados:</SortLabel>
            <Select
              value={destacadoFilter}
              defaultValue={destacadoFilter}
              onChange={(event) => dispatch({
                type: 'CHANGE_FILTER',
                payload: { destacadoFilter: event, sponsoredFilter, statusFilter, inmuebleID }
              })}
              options={[
                { value: '-1', label: "Todos" },
                { value: '0', label: "No destacados" },
                { value: '1', label: "Destacados búsqueda" },
                { value: '2', label: "Destacados home" },
              ]}
            />
          </SortWrapper>
          <SortWrapper>
            <SortLabel>Status:</SortLabel>
            <Select
              value={statusFilter}
              defaultValue={statusFilter}
              onChange={(event) => dispatch({
                type: 'CHANGE_FILTER',
                payload: { statusFilter: event, sponsoredFilter, destacadoFilter, inmuebleID }
              })}
              options={[
                { value: '-1', label: "Todos" },
                { value: '0', label: "Innactivo" },
                { value: '1', label: "Activo" },
              ]}
            />
          </SortWrapper>
          <SortWrapper>
            <SortLabel>ID anuncio:</SortLabel>
            <Input
              value={inmuebleID}
              onChange={(event) => dispatch({
                type: 'CHANGE_FILTER',
                payload: { inmuebleID: event.target.value, sponsoredFilter, destacadoFilter, statusFilter }
              })}
            />
          </SortWrapper>
        </FilterWrapper>
        {loading && (
          <LoaderWrapper>
            <Load marginTop={150} />
          </LoaderWrapper>
        )}
        {!loading && propertyList && propertyList.length > 0 && (
          <TableWrapper>
            <UserTable>
              <thead>
                <TableRow>
                  <TableHeader className="id">
                    ID
                </TableHeader>
                  <TableHeader>
                    Título
                </TableHeader>
                  <TableHeader>
                    Dirección
                </TableHeader>
                  <TableHeader className="id">
                    Estado
                </TableHeader>
                  <TableHeader className="id">
                    Patrocinado
                </TableHeader>
                  <TableHeader className="id">
                    Destacado
                </TableHeader>
                  <TableHeader>
                    Cambiar estado
                </TableHeader>
                  <TableHeader>
                    Ver
                </TableHeader>
                  <TableHeader>
                    Editar
                </TableHeader>
                  <TableHeader>
                    Contratar membresia
                </TableHeader>
                </TableRow>
              </thead>
              <tbody>
                {propertyList.map((property) => (
                  <TableRow key={property.inmuebleID}>
                    <TableData className="id">
                      {property.inmuebleID}
                    </TableData>
                    <TableData>
                      {property.titulo}
                    </TableData>
                    <TableData>
                      {getAddress(property.calle, property.numeroExterior, property.colonia, property.ciudad)}
                    </TableData>
                    <TableData className="id">
                      {property.nombreStatus}
                    </TableData>
                    <TableData className="id">
                      {property.patrocinado ? 'Sí' : 'No'}
                    </TableData>
                    <TableData className="id">
                      {getDestacado(property.destacado)}
                    </TableData>
                    <TableData>
                      {property.statusID ? (
                        <TwoStepsButton
                          firstStepText="Deshabilitar"
                          cancelText="Cancelar"
                          confirmationText="Confirmar"
                          className="red"
                          onClick={() => changeStatus(property.inmuebleID, 0)}
                        />
                      ) : (
                        <TwoStepsButton
                          firstStepText="Habilitar"
                          cancelText="Cancelar"
                          confirmationText="Confirmar"
                          className="green"
                          onClick={() => changeStatus(property.inmuebleID, 1)}
                        />
                      )}
                    </TableData>
                    <TableData >
                      <Button
                        className="activate"
                        onClick={() => {
                          props.history.push(`/detalle/${property.inmuebleID}`)
                        }}
                      >
                        Ver
                    </Button>
                    </TableData>
                    <TableData >
                      <Button
                        className="edit"
                        onClick={() => {
                          props.history.push(`/registrar-propiedad/${property.inmuebleID}`)
                        }}
                      >
                        Editar
                    </Button>
                    </TableData>
                    <TableData >
                      <Button
                        className="activate"
                        onClick={() => {
                          props.history.push(`/contratar-membresia/1/${property.inmuebleID}`)
                        }}
                      >
                        Contratar membresia
                    </Button>
                    </TableData>
                  </TableRow>
                ))}
              </tbody>
            </UserTable>
          </TableWrapper>
        )}
        {!loading && propertyList && propertyList.length === 0 && (
          <NotFound />
        )}
      </MainWrapper>
      <Pagination
        totalPages={totalPages}
        actualPage={actualPage}
        offset={offset}
        propertyLimit={USERSLIMIT}
        changePage={(newOffset, newPage) => dispatch({
          type: 'CHANGE_PAGE',
          payload: { offset: newOffset, actualPage: newPage }
        })}
      />
    </>
  );
};

export default withRouter(UserPropertiesList);
