import { handleActions } from 'redux-actions'

export const types = {
    SET_USER: 'SET_USER',
}

const initialValues = {
    user: null,
}

export const userActions = {
    setUset: dispatch => (user) => {
        dispatch({ type: types.SET_USER, user })
    }
}

export const userReducer = 
    handleActions({
        [types.SET_USER]: (_, action) => {
            return ({
                user: action.user
            })
        },
    }, initialValues)