import React from 'react'
import styled from 'styled-components'
import { ReactSVG } from 'react-svg'
import { COLORS } from '../common/utils/constants'

const CounterWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const CounterLabel = styled.p`
  margin-right: 15px;
  color: #000;
  font-size: 14px;
`

const CounterInput = styled.input`
  border: none;
  border-bottom: 1px solid #BABFC9;
  height: 20px;
  width: 50px;
  margin: 0 10px;
  padding-bottom: 5px;
  text-align: center;
  font-size: 16px;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
  }
`

const CounterIcon = styled(ReactSVG)`
  width: 25px;
  height: 25px;
  margin-right: 10px;

  svg {
    width: 25px;
    height: 25px;
  }

  path, line {
    stroke: #000;
  }

  &.filter {
    margin-right: 20px;
  }
`

const MinusPlus = styled(ReactSVG)`
  width: 12px;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;
  }

  path {
    fill: ${COLORS.darkBlue};
  }
`
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;

  &.filter {
    padding-left: 13px;

    @media (max-width: 768px) {
      padding-left: 10px;
    }
  }
`

const RightWrapper = styled.div`
  display: flex;

  &.filter {
    padding-right: 13px;

    @media (max-width: 768px) {
      padding-right: 10px;
    }
  }


  @media (max-width: 768px) {
    width: 30%;
  }
`

const minus = require('../common/images/minus.svg')
const plus = require('../common/images/plus.svg')

const Counter = ({ label, icon, onChange = () => {}, valueProp, counterClass = '', minValue = 0 }) => (
  <CounterWrapper>
    <LeftWrapper className={counterClass}>
      {icon && <CounterIcon className={counterClass} src={icon} />}
      <CounterLabel className={counterClass}>{label}</CounterLabel>
    </LeftWrapper>
    <RightWrapper className={counterClass}>
      <MinusPlus src={minus} onClick={() => valueProp > 0 && onChange(valueProp - 1)}/>
      <CounterInput
        onChange={e => onChange(parseInt(e.target.value))}
        value={valueProp}
        min={minValue}
        type='number' />
      <MinusPlus src={plus} onClick={() => onChange(valueProp + 1)}/>
    </RightWrapper>
  </CounterWrapper>
);

export default Counter
