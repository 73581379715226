export const TABLET_WIDTH = 767

export const COLORS = {
  primaryBlue: '#3777E3',
  gray: '#BABFC9',
  primaryOrange: '#FF7F32',
  darkBlue: '#022169',
  black: '#000',
  white: '#FFF',
  pink: '#ff8080',
};

export const PROPERTYLIMIT = 9;

export const USERSLIMIT = 20;
