import styled from "styled-components";

export const Image = styled.img`
    width: 200px;
    height: 200px;
    margin-top: 60px;


    @media (max-width: 768px) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
`;

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50vh;
    margin-top: 30px;
`

export const BigText = styled.div`
    font-size: 30px;
    margin-bottom: 15px;
    text-align: center;
`;

export const SmallText = styled.div`
    font-size: 20px;
    text-align: center;
    display: flex;
    align-items: center;

`;

export const Code = styled.p`
    font-size: 25px;
    color: #e2574c;
    margin: 0 10px;
`