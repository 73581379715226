import styled from "styled-components";
import { COLORS } from "../../common/utils/constants";
export const MainWrapper = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 3;
    cursor: pointer;

    svg {
        fill: ${COLORS.darkBlue};
    }
    &.isFavorite { 
        svg {
            fill: ${COLORS.pink};
        }
    }

    &.noMargin {
        bottom: 0;
        right: 0;
        top: 0;
    }
`;