import React from 'react';
import styled from 'styled-components';
import InputField from '../../components/InputField';
import Button from '../../components/Button';
import { useForm } from "react-hook-form";
import { sendContacto } from '../../common/utils/api-helpers';
import { useAlert } from 'react-alert';

const backgroundImage = require('../../common/images/carso-bkg.jpeg')
const fbLogo = require('../../common/images/fbLogo.png')

const ContactWrapper = styled.div`
    max-width: 1200px;
    margin: 10px auto;
    min-height: calc(100vh - 160px);
    display: flex;
    justify-content: center;
    margin-top: -200px;
    justify-content: space-around;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 20px;
        margin-top: -250px;
    }
`

const ContactTitle = styled.h1`
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
    color: #FFF
`

const ContactText = styled.p`
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
    color: #FFF;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        margin-bottom: 30px;
    }
`

const ContactImage = styled.img`
    width: auto;
    height: 400px;
    box-shadow: 0px -10px 30px -5px rgb(0 0 0 / 40%);

    @media (max-width: 768px) {
        display: none;
    }
`

const ContactBkg = styled.div`
    background-color: #022169;
    width: 100%;
    height: 300px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
`

const ContactContentWrapper = styled.div`
    width: 400px;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 0;
    }
`

const ContactFormWrapper = styled.div`
    width: 100%;
    padding: 5px 20px 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px -10px 30px -5px rgb(0 0 0 / 40%);
    
    @media (max-width: 768px) {
        box-sizing: border-box;
    }
`

const FieldWrapper = styled.div`
`

const Label = styled.p`
    margin-bottom: 5px;
    font-weight: 500;
`

const RightCol = styled.div``

const ContactForm = styled.form``

const SocialInfo = styled.div`
    margin-top: 40px;

    @media (max-width: 768px) {
        margin-top: 10px;
    }
`

const SocialLogo = styled.img`
    width: 30px;
`

const SocialText = styled.p``

const Contact = () => {
    const { register, handleSubmit, errors, getValues, reset } = useForm();
    const alert = useAlert();

    const submit = async(data, e) => {
        try {
            await sendContacto(data);
            alert.success('Enviado con exito');
        } catch(err) {
            console.log(err);
            alert.error('Ocurrió un error, intenta de nuevo más tarde');
        }
        e.target.reset();
    }

    return (
        <>
            <ContactBkg />
            <ContactWrapper>
                <ContactContentWrapper>
                    <ContactTitle>¡Contactanos!</ContactTitle>
                    <ContactText>Estamos aquí para ayudarte, deja tus datos y te contactaremos lo mas pronto posible</ContactText>
                    <ContactFormWrapper>
                        <ContactForm onSubmit={handleSubmit(submit)}>
                            <FieldWrapper>
                                <Label>Nombre *</Label>
                                <InputField
                                    name="nombre"
                                    placeholder="Nombre"
                                    type="text"
                                    reference={register({
                                        required: { value: true, message: "Debes introducir este campo" },
                                        minLength: { value: 2, message: "Mínimo 2 caracteres" },
                                    })}
                                    internalError={errors.nombre}
                                    suffixError={errors.nombre && errors.nombre.message}
                                />
                            </FieldWrapper>
                            <FieldWrapper>
                                <Label>Telefono</Label>
                                <InputField
                                    name="telefono"
                                    placeholder="Teléfono"
                                    type="text"
                                    reference={register({
                                        minLength: { value: 10, message: "Número a 10 digitos" },
                                    })}
                                    internalError={errors.telefono}
                                    suffixError={errors.telefono && errors.telefono.message}
                                />
                            </FieldWrapper>
                            <FieldWrapper>
                                <Label>Correo *</Label>
                                <InputField
                                    name='email'
                                    type="text"
                                    placeholder='Correo'
                                    reference={register({
                                        required: { value: true, message: "Debes introducir este campo" },
                                        pattern: {
                                            value: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                            message: "Correo electrónico incorrecto",
                                        }
                                    })}
                                    internalError={errors.email}
                                    suffixError={errors.email && errors.email.message}
                                />
                            </FieldWrapper>
                            <FieldWrapper>
                                <Label>Mensaje *</Label>
                                <InputField
                                    isTextArea
                                    name="mensaje"
                                    placeholder="Escribe aquí lo que nos quieres decir"
                                    type="text"
                                    reference={register({
                                        required: { value: true, message: "Debes introducir este campo" },
                                        minLength: { value: 10, message: "Debes de escribir al menos 10 caracteres" },
                                    })}
                                    internalError={errors.mensaje}
                                    suffixError={errors.mensaje && errors.mensaje.message}
                                />
                            </FieldWrapper>
                            <Button
                                type='submit'
                                margin='15px 0 0'
                                style={{ borderRadius: '5px' }}
                            >
                                Enviar
                        </Button>
                        </ContactForm>
                    </ContactFormWrapper>
                </ContactContentWrapper>
                <RightCol>
                    <ContactImage src={backgroundImage} />
                    <SocialInfo>
                        <SocialText>
                            Si lo prefieres envia un correo a: 
                        </SocialText>
                        <SocialText>
                            <b>contacto@flashome.mx</b>
                        </SocialText>
                        <SocialText>
                            O siguenos en nuestras redes sociales:
                        </SocialText>
                        <a href='https://www.facebook.com/FlasHomeMexico' target='_blank'><SocialLogo src={fbLogo}/></a>
                    </SocialInfo>
                </RightCol>
            </ContactWrapper>
        </>
    )
}

export default Contact;
