import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import {
    MainWrapper,
    CardTitle,
    TitleSeparator,
    PriceWrapper,
    Price,
    PriceSubtitle,
    Description,
    InputWrapper,
    RadioButtonLabel,
    Inputs,
    FrecuencyTitle,
    FinalPriceWrapper
} from './styled';
import Button from '../Button';

const PriceCard = ({
    title,
    price,
    priceSubtitle,
    description,
    options,
    isSmall,
    index,
    flippedCard,
    onClick,
    onCancel,
    setSelectedFrecuency,
    onNextStep
}) => {
    const [totalPrice, setTotalPrice] = useState(0);

    return (
        <ReactCardFlip isFlipped={index === flippedCard}>
            <MainWrapper isSmall={isSmall} index={index}>
                <CardTitle>{title}</CardTitle>
                <TitleSeparator />
                <PriceWrapper>
                    <Price>
                        {`$${price}`}
                    </Price>
                    <PriceSubtitle>
                        {priceSubtitle}
                    </PriceSubtitle>
                </PriceWrapper>
                <Description>
                    {description}
                </Description>
                <Button onClick={() => {
                    onClick();
                    setTotalPrice(0);
                    setSelectedFrecuency({frecuenciaMembresiaID: -1});
                }}>
                    Elegir
                </Button>
            </MainWrapper>
            <MainWrapper isSmall={isSmall} index={index}>
                <FrecuencyTitle>{"¿Por cuánto tiempo quieres publicitar tu anuncio?"}</FrecuencyTitle>
                <Inputs>
                    {options.map(option => (
                        <InputWrapper key={option.frecuenciaMembresiaID}>
                            <input
                                type="radio"
                                id={option.frecuenciaMembresiaID}
                                name="frecuencia"
                                value={option.frecuenciaMembresiaID}
                                onClick={() => {
                                    setTotalPrice((price * option.numeroDias).toFixed(2));
                                    setSelectedFrecuency(option);
                                }}
                            />
                            <RadioButtonLabel htmlFor={option.frecuenciaMembresiaID} isSmall={isSmall}>{option.descripcion}</RadioButtonLabel>
                        </InputWrapper>
                    ))}
                </Inputs>
                <FinalPriceWrapper>
                    <Price>
                        {`$${totalPrice}`}
                    </Price>
                    <PriceSubtitle>
                        {'Total'}
                    </PriceSubtitle>
                </FinalPriceWrapper>
                <Button
                    marginBottom={'10px'}
                    onClick={onNextStep}
                >
                    Aceptar
                </Button>
                <Button
                    onClick={() => {
                        onCancel();
                        setTotalPrice(0);
                        setSelectedFrecuency({frecuenciaMembresiaID: -1});
                    }}
                    secondary
                >
                    Cancelar
                </Button>
            </MainWrapper>
        </ReactCardFlip>
    )
};

export default PriceCard;