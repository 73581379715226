import styled from 'styled-components';
import { COLORS } from '../../common/utils/constants';

export const PaginationWrapper = styled.div`
    width: 100%;
    padding: 0 15px;
    color: ${COLORS.gray};
    border-top: 1px solid ${COLORS.gray};
    .pagination {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        padding: 10px 0 0;
        margin: 0;
    }

    li {
        list-style-type: none;
        padding: 6px;
    }

    a {
        cursor: pointer;
    }

    .active {
        color: ${COLORS.primaryBlue};
        border-top: 1px solid ${COLORS.primaryBlue};
    }

`;
