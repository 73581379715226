import styled from 'styled-components'
import { COLORS } from '../../common/utils/constants';
export const RegisterTitle = styled.h1`
  margin: 0 0 15px 0;
  font-weight: 600;
  ${props => props.isOrange && `color: ${COLORS.primaryOrange}`};
`

export const RegisterWrapper = styled.div`
  margin: 20px auto 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 30px;
  font-weight: 500;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
  }
`

export const DescriptionCounter = styled.span``

export const InstructionsWrapper = styled.p`
  font-size: 18px;
`

export const Space = styled.div`
  width: 10px;
`;

export const GeneralWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: 6;
`

export const DirectionsWrapper = styled.div`
  width: 60%;
  padding-right: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`

export const GeneralInfoWrapper = styled.div`
  width: 40%;
  position: relative;
  margin-top: 42px;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
    position: unset;
    display: flex;
    flex-direction: column;
  }
`

export const MapContainer = styled.div`
  width: 100%;
  height: 333px;
  margin-top: 10px;
  position: relative;
`

export const MapInstruction = styled.p``

export const MapPin = styled.img`
  height: 40px;
  top: calc(50% - 20px);
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`

export const StepWrapper = styled.div`
  display: flex;
  height: 10px;
  width: 400px;
  margin: 20px 0;
  z-index: 3;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Step = styled.div`
  width: 150px;
  margin-right: 10px;
  &:after {
    content:"";
    float: left;
    background: ${props => props.active ? COLORS.primaryOrange : '#BABFC9'};
    width: 100%;
    height: 6px;
    border-radius: 3px;
  }
`

export const FieldWrapper = styled.div`
  margin-bottom: 8px;
  width: 100%;
  position: relative;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  @media (max-width: 768px) {
    position: unset;
  }
`
export const AmenitiesWrapper = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &.loading{
    justify-content: center;
  }
`

export const AmenitiesButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 3;
  @media (max-width: 768px) {
    justify-content: space-around;
  }
`

export const InputLabel = styled.p`
  margin: 0;
  height: 51px;
  align-items: center;
  display: flex;
`

export const CalendarWrapper = styled.div`
  width: 280px;
  z-index: 6;
  @media (max-width: 768px) {
    width: 300px;
  }
`

export const SelectionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`

export const Selection = styled.p`
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  border: 2px solid ${COLORS.darkBlue};
  padding: 5px 10px;
  border-right: none;

  &:last-child{
    border-right: 2px solid ${COLORS.darkBlue};
  }

  &.active {
    background: ${COLORS.darkBlue};
    color: ${COLORS.white};
  }
`

export const PriceTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StreetNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;s
`

export const BuyButtonWrapper = styled.div`
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LoadWrapper = styled.div`
  height: 50vh;
  display: flex;
`;

export const SelectErrorMessage = styled.div`
  position: absolute;
  top: 13px;
  right: 50px;
  font-size: 10px;
  color: #FF3429;

  @media (max-width: 768px) {
    max-width: 115px;
    top: 8px;
  }
`