import React from 'react';
import { ContentWrapper } from './styled';

const TC = _ => (
  <ContentWrapper>
    <h1>Terminos y Condiciones</h1>
    <p>WIP</p>
  </ContentWrapper>
);

export default TC;
