import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom'
import useOpenPay from '../../common/utils/useOpenPay';
import { registerMembership } from '../../common/utils/api-helpers';
import MembershipSelect from '../../components/membership-select';
import MembershipPay from '../../components/membership-pay';
import MembershipDone from '../../components/membership-done';
import Button from '../../components/Button'
import Loader from '../../components/loader';
import {
    BuyButtonWrapper,
    LoadWrapper,
} from './RegisterProperty.styled';

const BuyMembership = ({ savedAdId }) => {
    const history = useHistory();
    const alert = useAlert();
    const [selectedFrecuency, setSelectedFrecuency] = useState({ frecuenciaMembresiaID: -1 });
    const [selectedMembership, setSelectedMembership] = useState({ tipoMembresiaID: -1 });
    const [selectedCard, setSelectedCard] = useState({ tarjetaID: -1})
    const [currentStep, setCurrentStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { deviceSessionId } = useOpenPay();

    useEffect(() => {
        if(currentStep === 0){
            setSelectedMembership({tipoMembresiaID: -1});
            setSelectedFrecuency({ frecuenciaMembresiaID: -1 });
        }
    }, [currentStep])

    const goToNextStep = () => {
        if (selectedFrecuency.frecuenciaMembresiaID !== -1 && selectedMembership.tipoMembresiaID !== -1) {
            setCurrentStep(1);
        } else {
            alert.error('Debes seleccionar la duración de la membresia');
        }
    };

    const registerMembershipFunction = async () => {
        if (selectedCard.tarjetaID !== -1) {
            try {
                setLoading(true);
                const respuesta = await registerMembership(
                    selectedFrecuency.frecuenciaMembresiaID,
                    selectedMembership.tipoMembresiaID,
                    (selectedMembership.precio * selectedFrecuency.numeroDias).toFixed(2),
                    selectedCard.tarjetaID,
                    deviceSessionId,
                    savedAdId,
                );
                if (respuesta.data.error_id){
                    setError(respuesta.data.error_id);
                }
                setCurrentStep(2);
            } catch(err) {
                alert.error('Ocurrió un error, intenta de nuevo más tarde');
                console.log(err);
            } finally {
                setLoading(false);
            }
        } else {
            alert.error('Debes seleccionar una tarjeta');
        }
    }

    return (
        <>
            {loading && (
                <LoadWrapper>
                    <Loader />
                </LoadWrapper>
            )}
            {!loading && currentStep === 0 && (
                <>
                    <MembershipSelect
                        setSelectedFrecuency={setSelectedFrecuency}
                        flippedCard={selectedMembership}
                        setFlippedCard={setSelectedMembership}
                        onNextStep={goToNextStep}
                    />
                    <Button secondary onClick={() => history.push(`/detalle/${savedAdId}`)}>
                        {'No gracias, quiero seguir con el plan gratuito'}
                    </Button>
                </>
            )}
            {!loading && currentStep === 1 && (
                <>
                    <MembershipPay
                        membership={selectedMembership}
                        frecuency={selectedFrecuency}
                        card={selectedCard}
                        setCard={setSelectedCard}
                    />
                    <BuyButtonWrapper>
                        <Button onClick={registerMembershipFunction}>
                            {`Pagar $${(selectedMembership.precio * selectedFrecuency.numeroDias).toFixed(2)}`}
                        </Button>
                        <Button secondary onClick={() => setCurrentStep(0)}>
                            {'Regresar'}
                        </Button>
                    </BuyButtonWrapper>
                </>
            )}
            {!loading && currentStep === 2 && (
                <>
                    <MembershipDone error={error}/>
                    <Button onClick={() => history.push(`/detalle/${savedAdId}`)}>
                        {'Ir a mi anuncio'}
                    </Button>
                </>
            )}
        </>
    )
};

export default BuyMembership;