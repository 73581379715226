import React from 'react'
import styled from 'styled-components'
import { useHistory, Link } from 'react-router-dom'

const SidebarWrapper = styled.div`
  width: 80vw;
  height: calc(100% - 60px);
  background: #fff;
  padding-top: 60px;
  position: relative;
`

const OptionsWrapper = styled.ul`
  margin: 0;
  padding: 0;
`

const Option = styled.li`
  list-style: none;
  padding: 15px 20px;
  font-weight: 600;
`

const BottomOptions = styled.div`
  padding: 15px 20px;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column; `

const BottomOption = styled(Link)`
  text-decoration: none;
  color: #000;
  width: 100%;
  margin-top: 10px;
`

const Sidebar = ({ user, isAdmin, close, logout }) => {
  const history = useHistory();
  const redirect = (url) => {
    history.push(url)
    close()
  }

  return (
    <SidebarWrapper>
      <OptionsWrapper>
        <Option
          onClick={() => user ? redirect('/registrar-propiedad') : redirect('/login')}
        >
          Registrar Propiedad
        </Option>
        {user ? (
          <>
            <Option
              onClick={() => redirect('/user/inmuebles')}
            >
              Mis anuncios
            </Option>
            <Option
              onClick={() => redirect('/perfil')}
            >
              Mi información
            </Option>
            <Option
              onClick={() => redirect('/favoritos')}
            >
              Mis favoritos
            </Option>
          </>
        ) : (
          <Option
            onClick={() => redirect('/login')}
          >
            Iniciar sesión
          </Option>
        )}
        {isAdmin && (
          <>
            <Option
              onClick={() => redirect('/admin/usuarios')}
            >
              Lista usuarios
            </Option>
            <Option
              onClick={() => redirect('/register')}
            >
              Crear usuario
            </Option>
            <Option
              onClick={() => redirect('/admin/inmuebles')}
            >
              Lista inmuebles
            </Option>
          </>
        )}
      </OptionsWrapper>
      <BottomOptions>
        {user && (
          <BottomOption
            onClick={logout}
          >
            Cerrar sesión
          </BottomOption>
        )}
        <BottomOption to='/terminos-y-condiciones'>
          Términos y Condiciones
        </BottomOption>
        <BottomOption to='/politicas-privacidad'>
          Políticas de Privacidad
        </BottomOption>
      </BottomOptions>
    </SidebarWrapper>
  )
}

export default Sidebar
