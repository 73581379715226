import React from 'react';
import Telescope from '../../common/images/telescopio.svg';
import NotPublished from '../../common/images/not-found.svg';

import {
    MainWrapper,
    Text,
    Icon,
} from './styled';

const NotFound = ({ text = 'No hemos encontrado resultados para tu búsqueda.', type = 'telescope'}) => {
    const getIcon = () => {
        switch (type) {
            case 'telescope':
                return Telescope;
            case 'notPublished':
                return NotPublished
            default:
                return Telescope;
        }
    }

    return(
        <MainWrapper>
            <Icon src={getIcon()}/>
            <Text>{text}</Text>
        </MainWrapper>
    )
};

export default NotFound;