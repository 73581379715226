import styled from 'styled-components';
import { COLORS } from '../../common/utils/constants';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 500px;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    &:hover{
        background-color: ${COLORS.gray};
    }

    @media (max-width: 768px) {
        width: 320px;
    }
`;

export const ShadowBox = styled.div`
    box-shadow: 0px 0px 10px 0px ${COLORS.gray};
    margin-bottom: 15px;
    max-height: 280px;
    overflow: scroll;
    display: flex;
    justify-content: ${props => props.loadingProp ? 'center' : 'flex-start'};
    flex-direction: column;

    ${props => {
        if (props.withMinHeight){
            return`
                min-height: 280px;
                min-width: 520px;

                @media (max-width: 768px) {
                    min-width: 320px;
                }
            `
        }
    }}
`

export const CardInfo = styled.div`
    font-size: 15px;
`

export const RadioButton = styled.input``

export const CardImage = styled.img`
    width: 30px;
`

export const CardSelectWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 60px;
    justify-content: space-between;
`

export const FullscreenOverlay = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0,0,0,0.7);
  z-index: 3;
`;
