import styled from 'styled-components';
import { COLORS } from '../../common/utils/constants';
import { Link as LinkComp } from 'react-router-dom'

export const FirstContentWrapper = styled.div`
  height: 750px;
  display: flex;
  position: relative;
`;

export const MaxWidthWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`;

export const ImageCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #fff;
  opacity: 0.58;
`;

export const QuestionWrapper = styled.div`
  width: 500px;
  padding: 0;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  font-weight: 500;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LandingImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  z-index: 1;
  position: absolute;
`;

export const LandingTitle = styled.p`
  font-size: 42px;
  font-weight: 700;
  width: 800px;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 40px 0;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 25px;
    margin-bottom: 20px;
  }
`;

export const TextHighlight = styled.span`
    color: ${props => props.blue ? COLORS.darkBlue : COLORS.primaryOrange};
`;

export const PlaceWrapper = styled.div`
  margin-top: 10px;
  padding: 10px 30px;
  font-weight: 700;
  font-size: 14px;

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const BudgetWrapper = styled.div`
  margin-top: 10px;
  padding: 10px 30px;
  font-weight: 700;
  font-size: 14px;

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const SearchButtonWrapper = styled.div`
  width: 180px;
  margin-top: 15px;
  padding: 10px 30px;

  @media (max-width: 768px) {
    box-sizing: border-box;
    padding: 10px 20px;
    width: 100%;
  }
`;

export const NumbersWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Number = styled.span`
  font-weight: 300;
`;

export const RangeWrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 0 0 0 10px;
`;

export const LinkWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 20px 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const Link = styled(LinkComp)`
    cursor: pointer;
    display: inline;
    padding: 4px 0;
    text-decoration: none;
    color: ${COLORS.black};
    :hover {
      border-bottom: 2px solid ${COLORS.primaryOrange};
    }
`;

export const HousingLandingWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 30px 20px 20px 20px;
  }
`;

export const DestacadosWrapper = styled.div`
  width: 100%;

  .itemsWrapper {
    height: 460px;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .rightChevronWrapper {
    right: -20px;
  }

  @media (max-width: 768px) {
    .itemsWrapper {
      box-sizing: border-box;
    }
    .rightChevronWrapper {
      right: 0;
    }
  }
}
`

export const PromotionBackground = styled.div`
  background-color: #DFEAFE;
  transform: skew(.312rad);
  width: 50%;
  height: 100%;
  position: absolute;
  border-radius: 50px;
  z-index: 1;

  @media (max-width: 768px) {
    height: 50%;
    width: 100%;
  }
`

export const PromotionWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 400px;
  justify-content: center;
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const PromotionContent = styled.div`
  z-index: 2;
  font-size: 30px;
  font-weight: 800;

  @media (max-width: 768px) {
    font-size: 25px;
    padding: 40px 10px;
  }
`

export const PromotionList = styled.ol`
  font-weight: 500;
  line-height: 1.5;
  font-size: 22px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

export const Arrow = styled.img`
  transform: ${props => props.inverted ? 'rotate(180deg)' : 'rotate(0deg)'}
`

export const SelectionWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: solid 1px #4F4F4F;
  justify-content: flex-start;
  padding: 0 30px;
  box-sizing: border-box;
`;

export const Selection = styled.p`
  cursor: pointer;
  font-size: 18px;
  color: #1E202A;
  margin-right: 30px;

  &.active, &:hover {
    border-bottom: 2px solid ${COLORS.primaryOrange};
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const WhyElement = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 10px;
  ${props => props.even ? 'flex-direction: row-reverse;' : ''}
  margin-bottom: 30px;
  border-radius: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

export const WhyImage = styled.img`
  width: 600px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Bold = styled.span`
  font-weight: 700;
  color: ${COLORS.primaryOrange};
`

export const WhyText = styled.p`
  font-weight: 500;
  font-size: 22px;
  ${props => props.even ? 'margin-right' : 'margin-left'}: -100px;

  @media (max-width: 768px) {
    margin: 20px 0 0 0;
    font-size: 18px;
    ${props => props.even ? 'text-align: right' : ''};
  }
`
