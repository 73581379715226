import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { sendMessage } from '../../common/utils/api-helpers';
import { useAlert } from 'react-alert';
import Load from '../loader';
import InputField from '../InputField';
import Button from '../Button';
import {
  MainWrapper,
  ContactDataWrapper,
  Title,
  Data,
  PersonalInfoWrapper,
  Image,
  Form,
  CloseButton,
} from './styled';

const closeIcon = require('../../common/images/close_blue.png')

const InterestedForm = ({ data, image, closeModal, id }) => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await sendMessage(id, data);
      if (res.status === 200){
        alert.success('Mensaje enviado con éxito!');
        closeModal();
      } else {
        alert.error('Ocurrió un error, intenta de nuevo más tarde');
      }
      setLoading(false);
    } catch(e) {
      alert.error('Ocurrió un error, intenta de nuevo más tarde');
      console.log(e);
    }
  };

  return(
    <MainWrapper>
      {loading ? (
        <Load />
      ) : (
        <>
          <CloseButton onClick={closeModal} src={closeIcon}/>
          <ContactDataWrapper>
            <Title>Datos de contacto</Title>
            <PersonalInfoWrapper>
              {data.nombre && data.apellido &&(
                <Data>{`${data.nombre} ${data.apellido}`}</Data>
              )}
              {data.telefono && (
                <Data>{data.telefono}</Data>
              )}
            </PersonalInfoWrapper>
            <Image src={image}/>
          </ContactDataWrapper>
          <ContactDataWrapper>
            <Title>Enviar un mensaje</Title>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <InputField
                name="nombre"
                type="text"
                placeholder="Nombre"
                reference={register({ required: true })}
                internalError={errors.nombre}
                suffixError={"Debes introducir este campo"}
                withMargin
              />
              <InputField
                name="email"
                type="email"
                placeholder="Correo"
                reference={register({ required: true })}
                internalError={errors.email}
                suffixError={"Debes introducir este campo"}
                withMargin
              />
              <InputField
                name="telefono"
                type="text"
                placeholder="Teléfono"
                reference={register({ required: true })}
                internalError={errors.telefono}
                suffixError={"Debes introducir este campo"}
                withMargin
              />
              <InputField
                name="mensaje"
                placeholder="Mensaje"
                reference={register({ required: true })}
                internalError={errors.mensaje}
                suffixError={"Debes introducir este campo"}
                isTextArea
                withMargin
              />
              <Button type="submit">
                Enviar
              </Button>
            </Form>
          </ContactDataWrapper>
        </>
      )}
    </MainWrapper>
  );
};

export default InterestedForm;
