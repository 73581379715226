import React from "react";
import { withRouter } from "react-router"
import { useAlert } from 'react-alert';
import {
  MainWrapper,
  UserTable,
  TableData,
  TableHeader,
  TableRow,
  MainTitle,
  FilterWrapper,
  SortWrapper,
  SortLabel,
  LoaderWrapper,
  TableWrapper,
  Input
} from '../styled';
import usePropertiesFilter from './usePropertiesFilters';
import Select from 'react-select';
import SelectComponent from '../../../components/SelectComponent'
import NotFound from '../../../components/not-found';
import TwoStepsButton from '../../../components/TwoStepsButton'
import Load from '../../../components/loader'
import Pagination from '../../../components/pagination';
import { USERSLIMIT } from '../../../common/utils/constants';
import { getAddress } from '../../../common/utils/helpers'
import {
  changePropertyStatus,
  changePropertyDestacado,
  changePropertyPatrocinado
} from '../../../common/utils/api-helpers';

const selectStyle = {
  control: (provided) => ({
      ...provided,
      border: '1px solid #000',
      fontSize: '14px',
      cursor: 'pointer',
      border: '1px solid #C6C6C6',
  }),
  input: (provided) => ({
      ...provided,
      fontSize: '14px',
      fontFamily: 'Montserrat, sans-serif'
  }),
  option: (provided) => ({
      ...provided,
      cursor: 'pointer'
  })
};

const AdminPropertiesList = (props) => {
  const alert = useAlert();
  const [{
    propertyList,
    sponsoredFilter,
    destacadoFilter,
    statusFilter,
    email,
    loading,
    inmuebleID,
    totalPages,
    offset,
    actualPage,
    dataChange,
  }, dispatch] = usePropertiesFilter();

  const changeStatus = async (inmuebleID, newStatus) => {
    try {
      dispatch({ type: 'LOADING', payload: { loading: true } });
      await changePropertyStatus(inmuebleID, newStatus);
      dispatch({ type: 'DATA_CHANGE', payload: { dataChange: !dataChange } });
      dispatch({ type: 'LOADING', payload: { loading: true } });
      alert.success(newStatus ? 'Anuncio activado' : 'Anuncio desactivado');
    } catch (e) {
      alert.error('Ocurrió un error, intenta de nuevo más tarde');
      dispatch({ type: 'LOADING', payload: { loading: false } });
    }
  }

  const changeDestacado = async (inmuebleID, destacado) => {
    try {
      dispatch({ type: 'LOADING', payload: { loading: true } });
      await changePropertyDestacado(inmuebleID, destacado);
      dispatch({ type: 'DATA_CHANGE', payload: { dataChange: !dataChange } });
      dispatch({ type: 'LOADING', payload: { loading: true } });
      alert.success('Destacado cambiado');
    } catch (e) {
      alert.error('Ocurrió un error, intenta de nuevo más tarde');
      dispatch({ type: 'LOADING', payload: { loading: false } });
    }
  }

  const changePatrocinado = async (inmuebleID, patrocinado) => {
    try {
      dispatch({ type: 'LOADING', payload: { loading: true } });
      await changePropertyPatrocinado(inmuebleID, patrocinado);
      dispatch({ type: 'DATA_CHANGE', payload: { dataChange: !dataChange } });
      dispatch({ type: 'LOADING', payload: { loading: true } });
      alert.success('Patrocinado cambiado');
    } catch (e) {
      alert.error('Ocurrió un error, intenta de nuevo más tarde');
      dispatch({ type: 'LOADING', payload: { loading: false } });
    }
  }

  return (
    <>
      <MainWrapper>
        <MainTitle>Lista de inmuebles</MainTitle>
        <FilterWrapper>
          <SortWrapper>
            <SortLabel>Patrocinados:</SortLabel>
            <Select
              value={sponsoredFilter}
              defaultValue={sponsoredFilter}
              styles={selectStyle}
              onChange={(event) => dispatch({
                type: 'CHANGE_FILTER',
                payload: { sponsoredFilter: event, destacadoFilter, statusFilter, email, inmuebleID }
              })}
              options={[
                { value: '-1', label: "Todos" },
                { value: '0', label: "No" },
                { value: '1', label: "Sí" },
              ]}
            />
          </SortWrapper>
          <SortWrapper>
            <SortLabel>Destacados:</SortLabel>
            <Select
              value={destacadoFilter}
              defaultValue={destacadoFilter}
              onChange={(event) => dispatch({
                type: 'CHANGE_FILTER',
                payload: { destacadoFilter: event, sponsoredFilter, statusFilter, email, inmuebleID }
              })}
              options={[
                { value: '-1', label: "Todos" },
                { value: '0', label: "No destacados" },
                { value: '1', label: "Destacados búsqueda" },
                { value: '2', label: "Destacados home" },
              ]}
            />
          </SortWrapper>
          <SortWrapper>
            <SortLabel>Status:</SortLabel>
            <Select
              value={statusFilter}
              defaultValue={statusFilter}
              onChange={(event) => dispatch({
                type: 'CHANGE_FILTER',
                payload: { statusFilter: event, sponsoredFilter, destacadoFilter, email, inmuebleID }
              })}
              options={[
                { value: '-1', label: "Todos" },
                { value: '0', label: "Innactivo" },
                { value: '1', label: "Activo" },
              ]}
            />
          </SortWrapper>
          <SortWrapper>
            <SortLabel>Email usuario:</SortLabel>
            <Input
              value={email}
              onChange={(event) => dispatch({
                type: 'CHANGE_FILTER',
                payload: { email: event.target.value, sponsoredFilter, destacadoFilter, statusFilter, inmuebleID }
              })}
            />
          </SortWrapper>
          <SortWrapper>
            <SortLabel>ID anuncio:</SortLabel>
            <Input
              value={inmuebleID}
              onChange={(event) => dispatch({
                type: 'CHANGE_FILTER',
                payload: { inmuebleID: event.target.value, sponsoredFilter, destacadoFilter, statusFilter, email }
              })}
            />
          </SortWrapper>
        </FilterWrapper>
        {loading && (
          <LoaderWrapper>
            <Load />
          </LoaderWrapper>
        )}
        {!loading && propertyList && propertyList.length > 0 && (
          <TableWrapper>
            <UserTable>
              <thead>
                <TableRow>
                  <TableHeader className="id">
                    InmuebleID
                </TableHeader>
                  <TableHeader>
                    Título
                </TableHeader>
                  <TableHeader>
                    Dirección
                </TableHeader>
                  <TableHeader>
                    Status
                </TableHeader>
                  <TableHeader className="id">
                    Patrocinado
                </TableHeader>
                  <TableHeader>
                    Destacado
                </TableHeader>
                  <TableHeader>
                    Correo Usuario
                </TableHeader >
                  <TableHeader/>
                </TableRow>
              </thead>
              <tbody>
                {propertyList.map((property) => (
                  <TableRow key={property.inmuebleID}>
                    <TableData className="id">
                      {property.inmuebleID}
                    </TableData>
                    <TableData>
                      {property.titulo}
                    </TableData>
                    <TableData>
                      {getAddress(property.calle, property.numeroExterior, property.colonia, property.ciudad)}
                    </TableData>
                    <TableData>
                      {property.nombreStatus}
                    </TableData>
                    <TableData className="id">
                      <SelectComponent
                        value={property.patrocinado}
                        onChange={(event) => changePatrocinado(property.inmuebleID, event.target.value)}
                        options={[
                          { value: 1, label: "Si" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </TableData>
                    <TableData>
                      <SelectComponent
                        value={property.destacado}
                        onChange={(event) => changeDestacado(property.inmuebleID, event.target.value)}
                        options={[
                          { value: 0, label: "No destacado" },
                          { value: 1, label: "Destacado búsqueda" },
                          { value: 2, label: "Destacado home" },
                        ]}
                      />
                    </TableData>
                    <TableData>
                      {property.email}
                    </TableData>
                    <TableData>
                      {property.statusID ? (
                        <TwoStepsButton
                          firstStepText="Desactivar"
                          cancelText="Cancelar"
                          confirmationText="Confirmar"
                          className="red"
                          onClick={() => changeStatus(property.inmuebleID, 0)}
                        />
                      ) : (
                        <TwoStepsButton
                          firstStepText="Activar"
                          cancelText="Cancelar"
                          confirmationText="Confirmar"
                          className="green"
                          onClick={() => changeStatus(property.inmuebleID, 1)}
                        />
                      )}
                    </TableData>
                  </TableRow>
                ))}
              </tbody>
            </UserTable>
          </TableWrapper>
        )}
        {!loading && propertyList && propertyList.length === 0 && (
          <NotFound />
        )}
      </MainWrapper>
      <Pagination
        totalPages={totalPages}
        actualPage={actualPage}
        offset={offset}
        propertyLimit={USERSLIMIT}
        changePage={(newOffset, newPage) => dispatch({
          type: 'CHANGE_PAGE',
          payload: { offset: newOffset, actualPage: newPage }
        })}
      />
    </>
  );
};

export default withRouter(AdminPropertiesList);
