import { useState, useEffect } from 'react'

export const numberWithCommas = (x) => {
  return x.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const removeCommas = (x) => {
  return x.toString().replaceAll(",", "")
}

export const useWindowSize = () => {
  const isClient = typeof window === 'object'

  function getSize () {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined

    }

  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false

    }

    function handleResize () {
      setWindowSize(getSize())

    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return windowSize

}

export const getAddress = (calle, numeroExterior, colonia, ciudad) => {
  let direccion;
  if (calle) {
    direccion = `${calle}`
  }
  if(numeroExterior) {
    direccion = `${direccion} ${numeroExterior}`
  }
  if (colonia) {
    direccion = `${direccion}, ${colonia}`
  }
  if (ciudad) {
    direccion = `${direccion}, ${ciudad}`
  }

  return direccion;
}
