import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Sidebar from "react-sidebar";
import { userActions } from '../store/user/reducer'
import AuthService from '../common/utils/auth.service'
import { TABLET_WIDTH } from '../common/utils/constants'
import { useWindowSize } from '../common/utils/helpers'
import SidebarContent from './Sidebar'

const HeaderWrapper = styled.div`
  background-color: #FFF;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  height: 80px;
  position: relative;
  width: 100%;
  z-index: 900;
  border-bottom: 1px solid #dadada;

  @media (max-width: 768px) {
    height: 60px;
  }
`

const LogoWrapper = styled(Link)`
  color: #FFF;
  cursor: pointer;
`

const MenuWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 10px;
  color: #022169;
  font-weight: 500;
  padding: 0;
`

const MenuOption = styled.li`
  list-style: none;
  cursor: pointer;
  border: solid transparent;
  border-width: 2px 0;
  padding: 10px;
  transition: all 0.1s linear;

  &:hover {
    border-color: #FF7F32;
    color: #FF7F32;
  }
`

const LoginOption = styled.a`
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 2px solid transparent;
  padding-bottom: 5px;
  margin-top: 5px;

  &:hover {
    border-color: #022169;
    color: #022169;
  }
`

const LoginOptionWrapper = styled.div`
  display: ${props => props.isLogged ? 'block' : 'grid'};
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
`

const LogoImage = styled.img`
  margin-top: 0px;
  height: 60px;

  @media (max-width: 768px) {
    height: 40px;
  }
`

const Hamburguer = styled.img`
  width: 30px;
  position: absolute;
  left: 20px;
  height: 100%;
`

const DownArrow = styled.img`
  width: 20px;
  margin-left: 5px;
  transition: all 0.5s ease-in-out;

  &.open {
    transform: rotate(180deg);
  }
`

const Dropdown = styled.div`
  width: 150px;
  position: absolute;
  margin-top: 20px;
  background: #FFF;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  height: ${props => props.showDrop ? '162px' : '0'};
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  top: 50%;
  right: 0;
  border: ${props => props.showDrop ? '1px solid #dadada' : '0 solid #dadada'};
  border-radius: 5px;

  &.admin {
    height: ${props => props.showDrop ? '290px' : '0'};
  }
`

const DropdownOption = styled.div`
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;

  :hover {
    background: #DFEAFE;
  }
`

const MaxWidthWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 0 10px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

const DropdownDiv = styled.div`
  border-bottom: 1px solid #dadada;
  margin: 5px 0;
`

const logo = require('../common/images/LogoSide.svg')
const onlyLogo = require('../common/images/IconLogo.png')
const hamburguer = require('../common/images/hamburger.svg')
const downArrow = require('../common/images/downArrow.svg')

const Header = ({ user, setUser }) => {
  const [isPhone, setIsPhone] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [showDrop, setShowDrop] = useState(false)
  const history = useHistory()
  const size = useWindowSize()
  const isAdmin = user && user.usuario.rol === 'admin';

  useEffect(() => {
    setIsPhone(size.width < TABLET_WIDTH ? true : false)
  }, [size])

  useEffect(() => {
    document.body.style.overflow = sidebarOpen ? 'hidden' : 'unset';
  }, [sidebarOpen])

  const logout = () => {
    setUser(null);
    AuthService.logout()
    window.location.reload()
  }

  return (
    <>
      {isPhone && (
        <Sidebar
          sidebar={(
            <SidebarContent
              user={user}
              isAdmin={isAdmin}
              close={() => setSidebarOpen(false)}
              logout={logout}
            />
          )}
          open={sidebarOpen}
          onSetOpen={setSidebarOpen}
          styles={{
            root: {
              visibility: sidebarOpen ? 'visible' : 'hidden',
            },
            sidebar: {
              background: "white",
              zIndex: "1000"
            }
          }}
        />
      )}
      <HeaderWrapper>
        <MaxWidthWrapper>
          {isPhone && (
            <Hamburguer
              src={hamburguer}
              onClick={() => setSidebarOpen(!sidebarOpen)} />

          )}
          <LogoWrapper to='/'>
            <LogoImage src={isPhone ? onlyLogo : logo} />
          </LogoWrapper>
          {!isPhone && (
            <>
              <MenuWrapper>
                <MenuOption
                  onClick={() => user ? history.push('/registrar-propiedad') : history.push('/login')}
                >
                  REGISTRAR PROPIEDAD
              </MenuOption>
              <MenuOption
                  onClick={() => history.push('/contacto')}
                >
                  CONTACTO
              </MenuOption>
              </MenuWrapper>
              <LoginOptionWrapper isLogged={user}>
                {!user && <LoginOption
                  onClick={() => {
                    history.push('/register')
                  }}
                >
                  Registrarme
              </LoginOption>}
                <LoginOption
                  onClick={() => {
                    if (showDrop) {
                      setShowDrop(false);
                    } else if (user) {
                      setShowDrop(true);
                    } else {
                      history.push('/login')
                    }
                  }}>
                  {user ? user.usuario.nombre : 'Iniciar sesión'}
                  {user && (
                    <DownArrow
                      src={downArrow}
                      className={showDrop && "open"}
                    />
                  )}
                  <Dropdown showDrop={showDrop} className={isAdmin && "admin"}>
                    {isAdmin && (
                      <>
                        <DropdownOption onClick={() => {
                          history.push('/admin/usuarios')
                        }}>
                          Lista usuarios
                      </DropdownOption>
                        <DropdownOption onClick={() => {
                          history.push('/register')
                        }}>
                          Crear usuario
                      </DropdownOption>
                        <DropdownOption onClick={() => {
                          history.push('/admin/inmuebles')
                        }}>
                          Lista inmuebles
                      </DropdownOption>
                        <DropdownDiv />
                      </>
                    )}
                    <DropdownOption onClick={() => {
                      history.push('/user/inmuebles')
                    }}>
                      Mis anuncios
                  </DropdownOption>
                    <DropdownOption onClick={() => {
                      history.push('/perfil')
                    }}>
                      Mi información
                  </DropdownOption>
                    <DropdownOption onClick={() => {
                      history.push('/favoritos')
                    }}>
                      Mis favoritos
                  </DropdownOption>
                    <DropdownDiv />
                    <DropdownOption onClick={logout}>
                      Cerrar sesión
                  </DropdownOption>
                  </Dropdown>
                </LoginOption>
              </LoginOptionWrapper>
            </>
          )}
        </MaxWidthWrapper>
      </HeaderWrapper>
    </>

  )

}

const mapStateTopProps = ({ currentUser }) => {
  const { user } = currentUser
  return { user }
}

const mapDispatchToProps = dispatch => ({
  setUser: userActions.setUset(dispatch)
})

export default connect(mapStateTopProps, mapDispatchToProps)(Header)
