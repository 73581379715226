import React from 'react'
import MultiImageUpload from '../../components/MultiImageUpload'

const Images = ({registerAd, changeStep, savedAdId, setSavedAdId, isEdit }) => (
  <MultiImageUpload
    changeStep={changeStep}
    registerAd={registerAd}
    savedAdId={savedAdId}
    setSavedAdId={setSavedAdId}
    isEdit={isEdit}
  />
)

export default Images
