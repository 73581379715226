import styled from 'styled-components';
import { COLORS } from '../../common/utils/constants';

export const MainWrapper = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: white;
    width: 750px;
    height: 350px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 50px;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 80%;
        height: 505px;
        overflow-y: scroll;
        padding: 30px;
        top: 55%;
    }
`;

export const ContactDataWrapper = styled.div`
    width: 100%;
    z-index: 5;
    height: 350px;
`

export const Title = styled.div`
  font-size: 30px;
  color: ${COLORS.primaryOrange};
  font-weight: 500;
`

export const Data = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #7a7979;
    margin-bottom: 10px;
`

export const PersonalInfoWrapper = styled.div`
    margin-top: 20px;
    width: 70%;
    margin-bottom: 35px;
`;

export const Image = styled.img`
    width: 330px;
    height: 210px;

    @media (max-width: 768px) {
        display: none;
    }
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

export const CloseButton = styled.img`
    position: absolute;
    color: white;
    z-index: 4;
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 35px;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;
