import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  visibility: hidden;
  position: absolute;
  top: 59px;
  flex-direction: column;
  right: 15px;
  z-index: -5;
  background-color: white;
  border: 1px solid #BABFC9;
  padding: 10px;
  width: 450px;
  transition: all 0.5s;
  opacity: 0;
  transform: translateY(-20px);

  &.show {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    box-sizing border-box;
    width: 100%;
    padding: 20px;
    top: 214px;
    right: 0;
  }
`;

export const FieldWrapper = styled.div`
  margin-top: 10px;
  &:first-child:{
    margin-top: 0;
  }
`;

export const TwoFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const TwoFieldElement = styled.div`
  width: calc(50% - 10px);
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`
