import React, { useState, useEffect } from 'react'
import { withRouter, useLocation } from 'react-router'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'
import Button from '../../components/Button'
import { Row, Col } from 'react-flexbox-grid'
import PropertyResume from '../../components/PropertyResume'
import NotFound from '../../components/not-found';
import InterestedForm from '../../components/interested-form';
import AmenitiesBar from '../../components/AmenitiesBar'
import { numberWithCommas } from '../../common/utils/helpers'
import { mapStyle } from '../../common/utils/mapStyle'
import Load from '../../components/loader';
import FavoriteButton from '../../components/favorite-button';
import {
  PropertyDetailContainer,
  PropertySummary,
  GalleryPreview,
  PriceTag,
  GalleryImage,
  GalleryMinis,
  GalleryBig,
  SectionTitle,
  DescriptionWrapper,
  DetailsContainer,
  SectionSubtitle,
  RelatedWrapper,
  RelatedPropertiesWrapper,
  DescriptionInnerWrapper,
  Address,
  Highlight,
  LoaderWrapper,
  RelatedPropertyWrapper,
  FullScreenWrapper,
  FullScreenButton,
  FullscreenOverlay,
  GalleryWrapper,
  MoreImageOverlay,
  CloseButton,
  FavoriteWrapper
} from './Properties.styled'
import usePropertyDetail from './usePropertyDetail'
import { inmueble } from '../../common/utils/api-helpers';

const galleryImage = require('../../common/images/property2.png')
const closeIcon = require('../../common/images/close.png')
const markerIcon = require('../../common/images/circle.svg')

const PropertyDetail = props => {
  const query = new URLSearchParams(useLocation().search);
  const [imagesKeys, setImagesKeys] = useState([]);
  const [mainImageUrl, setMainImageUrl] = useState(null);
  const id = props.match.params.inmuebleId

  const [{
    inmuebleData,
    address,
    availableDate,
    loading,
    relatedAds,
    contactData,
    fullscreenModal,
    moreImagesToDisplay,
    galleryIndex,
    notPublished
  }, dispatch] = usePropertyDetail({ id });

  useEffect(() => {
    if(inmuebleData && inmuebleData.urls) {
      const imagesArray = {...inmuebleData.urls};
      const imagesKeys = Object.keys(imagesArray);
      let mainImg = null;
      if(imagesArray[inmuebleData.mainImage]) {
        mainImg = imagesArray[inmuebleData.mainImage];
        delete imagesArray[inmuebleData.mainImage];
      } else {
        mainImg = imagesArray[imagesKeys[0]];
        delete imagesArray[imagesKeys[0]];
      }
      setImagesKeys(Object.keys(imagesArray));
      setMainImageUrl(mainImg)
    }
  }, [inmuebleData])

  const propertyDetail = (id) => props.history.push({
    pathname: `/detalle/${id}`,
  });

  const _mapLoaded = (map) => map.setOptions({styles: mapStyle});

  const showFullscreenGallery = (index = 0) => {
    if (imagesKeys && imagesKeys.length > 0) {
      dispatch({ type: 'FULLSCREEN_MODAL', payload: { fullscreenModal: 1, galleryIndex: index }})
    }
  };

  const removeFullscreenModal = () => {
    dispatch({ type: 'FULLSCREEN_MODAL', payload: { fullscreenModal: 0, galleryIndex: 0 }})
  }

  return (
  loading ? (
    <LoaderWrapper className={'detail'}>
      <Load />
    </LoaderWrapper>
  ) : (
    <>
      {inmuebleData && (
        <>
          {fullscreenModal !== 0 && (
            <>
              <FullscreenOverlay onClick={removeFullscreenModal}/>
              {fullscreenModal === 1 && (
                <GalleryWrapper>
                  <CloseButton onClick={removeFullscreenModal} src={closeIcon}/>
                  <ImageGallery
                    items={Object.keys(inmuebleData.urls).map(key => ({original: inmuebleData.urls[key], thumbnail: inmuebleData.urls[key] }))}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    startIndex={galleryIndex}
                  />
                </GalleryWrapper>
              )}
              {fullscreenModal === 2 && (
                <InterestedForm
                  data={contactData}
                  image={mainImageUrl}
                  closeModal={removeFullscreenModal}
                  id={id}
                />
              )}
            </>
          )}
          <PropertyDetailContainer>
            <Row>
              <DetailsContainer>
                <Row>
                  <Col lg={12}>
                    <GalleryPreview>
                      {imagesKeys.length > 1 && (
                        <GalleryMinis>
                          {imagesKeys.slice(0,3).map((key, indx) => {
                            if (indx === 2 && moreImagesToDisplay) {
                              return(
                                <>
                                  <MoreImageOverlay onClick={() => showFullscreenGallery(indx)}>
                                    {`+${moreImagesToDisplay}`}
                                  </MoreImageOverlay>
                                  <GalleryImage
                                    src={inmuebleData.urls[key]}
                                    className={'mini'}
                                    onClick={() => showFullscreenGallery(indx)}
                                  />
                                </>
                              )
                            }
                            return(
                              <GalleryImage
                                src={inmuebleData.urls[key]}
                                className={'mini'}
                                onClick={() => showFullscreenGallery(indx)}
                              />
                            );
                          })}
                        </GalleryMinis>
                      )}
                      <GalleryBig
                        hasOtherImages={imagesKeys.length > 1}
                        onClick={() => showFullscreenGallery(Object.keys(inmuebleData.urls).indexOf(inmuebleData.mainImage))}
                      >
                        <GalleryImage
                          big
                          src={mainImageUrl}
                        />
                        {imagesKeys.length > 0 && (
                          <FullScreenWrapper>
                            <FullScreenButton>
                              {'Ver en pantalla completa'}
                            </FullScreenButton>
                          </FullScreenWrapper>
                        )}
                      </GalleryBig>
                    </GalleryPreview>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={8} xl={9}>
                    <DescriptionWrapper>
                      <FavoriteWrapper>
                        <FavoriteButton
                          inmuebleID={inmuebleData.inmuebleID}
                          defaultProp={inmuebleData.favorito}
                          noMargin
                        />
                      </FavoriteWrapper>
                      <SectionTitle>{inmuebleData.titulo}</SectionTitle>
                      <Address>Ubicación: <Highlight>{address}</Highlight></Address>
                      <Map
                        google={props.google}
                        zoom={17}
                        containerStyle={{
                          width: '100%',
                          height: '200px',
                          position: 'relative',
                          marginBottom: '20px'
                        }}
                        mapTypeControl={false}
                        streetViewControl={false}
                        fullscreenControl={false}
                        initialCenter={{ lat: inmuebleData.latitude, lng: inmuebleData.longitude }}
                        onReady={(_, map) => _mapLoaded(map)}
                      >
                        <Marker
                          icon={markerIcon}
                          position={{lat: inmuebleData.latitude, lng: inmuebleData.longitude}}
                        />
                      </Map>
                      <AmenitiesBar
                        habitaciones={inmuebleData.numeroHabitaciones}
                        banos={inmuebleData.numeroBanos}
                        estacionamientos={inmuebleData.numeroEstacionamiento}
                        mascotas={inmuebleData.mascotas}
                        areaConstruida={inmuebleData.areaConstruida}
                        antiguedad={inmuebleData.antiguedad}
                        tipoInmueble={inmuebleData.tipoInmuebleID}
                        mediosBanos={inmuebleData.numeroMediosBanos}
                        detailed
                      />
                      <DescriptionInnerWrapper>
                        <SectionSubtitle>Descripción</SectionSubtitle>
                        {inmuebleData.descripcion}
                      </DescriptionInnerWrapper>
                    </DescriptionWrapper>
                    <DescriptionWrapper>
                      <Row>
                        <Col xs={12} md={12} lg={12} xl={6}>
                          {inmuebleData.periodoMinimo > 0 && (
                            <>
                              Periodo mínimo de renta: {`${inmuebleData.periodoMinimo} ${inmuebleData.periodoMinimo > 1 ? 'meses': 'mes'}`}
                            </>
                          )}
                          {inmuebleData.depositoGarantia > 0 && (
                            <>
                              Depósito en garantía: <Highlight>{`$${numberWithCommas(inmuebleData.depositoGarantia)}`}</Highlight>
                              <br/>
                            </>
                          )}
                          Ocupación a partir de: <Highlight>{availableDate || 'No especificado'}</Highlight>
                        </Col>
                        <Col xs={12} md={12} lg={12} xl={6}>
                          {inmuebleData.precioMantenimiento > 0 && (
                            <>
                              Mantenimiento: <Highlight>${numberWithCommas(inmuebleData.precioMantenimiento)} MXN</Highlight>
                            </>
                          )}
                        </Col>
                      </Row>
                    </DescriptionWrapper>
                    {inmuebleData.amenidades && (
                      <DescriptionWrapper>
                        <Row>
                          <Col xs={12} md={12}>
                            <SectionSubtitle>Amenidades</SectionSubtitle>
                            {inmuebleData.amenidades.map((amenidad, index) => ( <div key={index}>{amenidad}</div>))}
                          </Col>
                        </Row>
                      </DescriptionWrapper>
                    )}
                  </Col>
                  <Col xs={12} md={12} lg={4} xl={3}>
                    <Row>
                      <Col md={5} lg={12}>
                        <PropertySummary>
                          <PriceTag>
                            {`$${numberWithCommas(inmuebleData.precio)}`} <span style={{ fontSize: '14px' }}>{inmuebleData.precioNegociable ? '(Negociable) MXN': 'MXN'}</span>
                          </PriceTag>
                          {inmuebleData.depositoGarantia > 0 && (
                            <p>Deposito: {`$${numberWithCommas(inmuebleData.depositoGarantia)}`}</p>
                          )}
                          <p>Se puede ocupar a partir de:</p>
                          <p style={{ fontWeight: '600' }}>{availableDate || 'No especificado'}</p>
                          <Button
                            style={{  margin: '20px 0 auto', width: '100%', padding: '10px 45px'}}
                            onClick={() => dispatch({ type: 'FULLSCREEN_MODAL', payload: { fullscreenModal: 2, galleryIndex: 0 }})}
                          >
                            Me interesa
                          </Button>
                        </PropertySummary>
                      </Col>
                    </Row>
                    {relatedAds && relatedAds.length > 0 && (
                      <Row>
                        <Col lg={12}>
                          <RelatedWrapper>
                            <SectionSubtitle>Propiedades Similares</SectionSubtitle>
                            <RelatedPropertiesWrapper>
                              {relatedAds.map(el => (
                                <RelatedPropertyWrapper>
                                  <PropertyResume
                                    key={el.inmuebleID}
                                    size="related"
                                    precio={el.precio}
                                    habitaciones={el.numeroHabitaciones}
                                    banos={el.numeroBanos}
                                    estacionamientos={el.numeroEstacionamiento}
                                    mascotas={el.mascotas}
                                    areaConstruida={el.areaConstruida}
                                    onClick={() => propertyDetail(el.inmuebleID)}
                                    image={el.mainImageURL || galleryImage}
                                    calle={el.calle}
                                    numeroExterior={el.numeroExterior}
                                    colonia={el.colonia}
                                    ciudad={el.ciudad}
                                    inmuebleID={el.inmuebleID}
                                    isFavorite={el.favorito}
                                  />
                                </RelatedPropertyWrapper>
                              ))}
                            </RelatedPropertiesWrapper>
                          </RelatedWrapper>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </DetailsContainer>
            </Row>
          </PropertyDetailContainer>
        </>
      )}
      {notPublished && (
        <NotFound text={'No encontramos el anuncio que buscas'} type="notPublished" />
      )}
      {!inmuebleData && !notPublished && (
        <NotFound text={'Ocurrió un error al obtener los datos del inmueble. Intenta mas tarde'}/>
      )}
    </>
  ))
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAJpM6-TLaZxpqrQH0KAtqlKuIBcmKs7ag'
})(withRouter(PropertyDetail))
