import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../common/utils/constants";
import { ReactSVG } from 'react-svg'

export const Button = styled.button`
     border: none;
     color: white;
     border-radius: 5px;
     padding: 5px;
     cursor: pointer;
     font-weight: 500;
     font-size: 14px;

     &.confirm {
        text-align: center;
        background-color:  #28a745;
        width: 45%;
        margin: 0 5px;

        &:hover {
            background: #518C4A;
        }
     }

     &.cancel {
        text-align: center;
        background-color: ${COLORS.primaryBlue};
        width: 45%;

        &:hover {
            background-color: #183463;
        }
     }
 
     &.red {
         text-align: center;
         background-color: #dc3545;
         width: 100%;

         &:hover {
            background-color: #B5492B;
         }
      }

      &.green {
        text-align: center;
        background-color: #28a745;
        width: 100%;

        &:hover {
            background: #518C4A;
        }
     }
`;

const OptionIcon = styled(ReactSVG)`
  width: 25px;
  cursor: pointer;
  margin-right: 10px;

  div {
    display: flex;
  }

  svg {
    width: 25px;
    height: 25px;
    fill: ${props => props.isMain ? 'red' : ''};
  }
`;

const Option = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 0;
`;

const OptionText = styled.div`
  margin-left: 10px;
`;


const TwoStepsButton = ({ onClick, firstStepText, confirmationText, cancelText, className, icon }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);

    return (
        <>
            { !showConfirmation ? (
                <>
                    {icon ? (
                        <Option onClick={() => setShowConfirmation(true)}>
                            <OptionIcon
                                src={icon}
                            />
                            {firstStepText}
                        </Option>
                    ) : (
                        <Button
                            className={className}
                            onClick={() => setShowConfirmation(true)}
                        >
                            {firstStepText}
                        </Button>
                    )}
                </>
            ) : (
                <>
                    {icon ? (
                        <Option>
                            <OptionText onClick={onClick}>
                                {confirmationText}
                            </OptionText>
                            <OptionText onClick={() => setShowConfirmation(false)}>
                                {cancelText}
                            </OptionText>
                        </Option>
                    ) : (
                        <>
                            <Button
                                className="confirm"
                                onClick={onClick}
                            >
                                {confirmationText}
                            </Button>
                            <Button
                                className="cancel"
                                onClick={() => setShowConfirmation(false)}
                            >
                                {cancelText}
                            </Button>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default TwoStepsButton;