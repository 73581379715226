import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-flexbox-grid'
import InputField from '../../components/InputField'
import Counter from '../../components/Counter'
import Button from '../../components/Button'
import ToggleButton from '../../components/ToggleButton'
import { numberWithCommas, removeCommas } from '../../common/utils/helpers'
import { obtenerUsuarios } from '../../common/utils/api-helpers'
import Calendar from 'react-calendar'
import Select from 'react-select'
import Load from '../../components/loader';
import {
  AmenitiesButtonWrapper,
  DescriptionCounter,
  InputLabel,
  FieldWrapper,
  CalendarWrapper,
  PriceTitle,
} from './RegisterProperty.styled'
import { propertiesActions } from '../../store/properties/reducer'
import { useAlert } from 'react-alert'
import { useForm, Controller } from "react-hook-form";
import 'react-calendar/dist/Calendar.css';

const AdDetails = ({ changeStep, adInfo, setAdInfo, setAdInfoNoField, generalInfo, loading, user }) => {
  const { register, handleSubmit, errors, reset, control, getValues } = useForm();
  const [charCounter, setCharCounter] = useState(adInfo.description.length)
  const [showCalendar, setShowCalendar] = useState(false)
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [users, setUsers] = useState([])
  const alert = useAlert();

  const submit = (data) => {
    setAdInfoNoField(data);
    changeStep();
  }

  useEffect(() => {
    if (user && (user.usuario.rol === 'broker' || user.usuario.rol === 'admin')) {
      setLoadingUsers(true);
      obtenerUsuarios()
        .then((res) => {
          const usersArray = res.data.map((el) => {
            return { 'label': el.email, 'value': el.usuarioID };
          });
          setLoadingUsers(false);
          setUsers(usersArray);
        })
        .catch(() => {
          setLoadingUsers(false);
          alert.error('Ocurrió un error, intenta de nuevo más tarde');
        });
    }
  }, [alert, user]);

  useEffect(() => reset(adInfo), [adInfo, reset]);

  return (
    <Grid fluid style={{ padding: 0 }}>
      {(loading || loadingUsers) && (
        <Load marginTop={150} />
      )}
      {(!loading && !loadingUsers) && (
        <form onSubmit={handleSubmit(submit)}>
          <Row>
            <Col md={4}>
              <InputLabel>Titulo del anuncio *</InputLabel>
              <InputField
                placeholder='Hermoso departamento...'
                name="title"
                type="text"
                reference={register({
                  required: { value: true, message: "Debes introducir este campo" },
                  minLength: { value: 10, message: "Mínimo 10 caracteres" },
                })}
                internalError={errors.title}
                suffixError={errors.title && errors.title.message}
              />
              <InputLabel style={{ marginTop: 30 }}>
                Descripcion del anuncio *
              </InputLabel>
              <InputField
                placeholder='Da una descripcion atractiva de tu inmueble para mostrar en el anuncio.'
                name="description"
                type="text"
                reference={register({
                  required: { value: true, message: "Debes introducir este campo" },
                  maxLength: { value: 1000, message: "Máximo 1000 caracteres" },
                  minLength: { value: 10, message: "Mínimo 10 caracteres" },
                })}
                internalError={errors.description}
                suffixError={errors.description && errors.description.message}
                onChange={value => setCharCounter(value.length)}
                isTextArea
              />
              <DescriptionCounter>{charCounter}/1000</DescriptionCounter>
            </Col>
            <Col md={4}>
              <PriceTitle>
                <InputLabel>Precio *</InputLabel>
                <ToggleButton
                  onChange={(value) => setAdInfo('negotiable', value)}
                  label={'¿Es negociable?'}
                />
              </PriceTitle>
              <Controller
                render={({ onChange, value }) => (
                  <InputField
                    onChange={(value) => onChange(numberWithCommas(removeCommas(value)))}
                    placeholder='5,000'
                    suffix='MXN'
                    value={value}
                    type="text"
                    internalError={errors.price}
                    suffixError={errors.price && errors.price.message}
                  />
                )}
                name="price"
                rules={{ required: { value: true, message: "Debes introducir este campo" } }}
                control={control}
              />
              {generalInfo.categoryType === 1 && (
                <>
                  <FieldWrapper style={{ marginTop: 60 }}>
                    <Controller
                      render={({ onChange, value }) => (
                        <InputField
                          onChange={(value) => onChange(numberWithCommas(removeCommas(value)))}
                          placeholder='Deposito de garantia *'
                          suffix='MXN'
                          value={value}
                          type="text"
                          internalError={errors.deposit}
                          suffixError={errors.deposit && errors.deposit.message}
                        />
                      )}
                      name="deposit"
                      rules={{ required: { value: true, message: "Debes introducir este campo" } }}
                      control={control}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Controller
                      render={({ onChange, value }) => (
                        <InputField
                          onChange={(value) => onChange(numberWithCommas(removeCommas(value)))}
                          placeholder='Mantenimiento mensual *'
                          suffix='MXN'
                          value={value}
                          type="text"
                          internalError={errors.manteinance}
                          suffixError={errors.manteinance && errors.manteinance.message}
                        />
                      )}
                      name="manteinance"
                      rules={{ required: { value: true, message: "Debes introducir este campo" } }}
                      control={control}
                    />
                  </FieldWrapper>
                  <Controller
                    render={({ onChange, value }) => (
                      <Counter
                        onChange={value => onChange(value)}
                        label='Periodo minimo de renta (meses)'
                        valueProp={value}
                      />
                    )}
                    name='miniumPeriod'
                    control={control}
                  />
                </>
              )}
            </Col>
            <Col md={4}>
              <InputLabel>Fecha de disponibilidad</InputLabel>
              <InputField
                onFocus={() => {
                  setShowCalendar(true);
                }}
                value={adInfo.availability.toLocaleDateString('mx-ES', { dateStyle: 'full' })}
                required
              />
              {showCalendar && (
                <CalendarWrapper>
                  <Calendar
                    onChange={(value) => {
                      setAdInfo('availability', new Date(value))
                      setShowCalendar(false);
                    }}
                  />
                </CalendarWrapper>
              )}
              {user && (user.usuario.rol === 'broker' || user.usuario.rol === 'admin') && (
                <FieldWrapper style={{ marginTop: 60 }}>
                  <Select
                    placeholder='Propietario anuncio'
                    onChange={(value) => setAdInfo('propertyUser', value.value)}
                    options={users}
                    defaultValue={adInfo.propertyUser}
                    isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: '1px solid #000',
                        borderRadius: '0'
                      }),
                      container: (provided) => ({
                        ...provided,
                        zIndex: '10'
                      })
                    }}
                  />
                </FieldWrapper>

              )}
            </Col>
            <AmenitiesButtonWrapper style={{ marginTop: 100 }}>
              <Button
                width='150px'
                secondary
                onClick={() => {
                  setAdInfoNoField(getValues());
                  changeStep(false)
                }}
              >
                Regresar
              </Button>
              <Button
                type='submit'
                width='150px'
                margin='0 0 0 10px'
              >
                Continuar
              </Button>
            </AmenitiesButtonWrapper>
          </Row>
        </form>
      )}
    </Grid>
  )
}

const mapStateToProps = ({ properties, currentUser }) => {
  const { adInfo, generalInfo } = properties
  const { user } = currentUser
  return { adInfo, user, generalInfo }
}

const mapDispatchToProps = dispatch => ({
  setAdInfo: propertiesActions.setAdInfo(dispatch),
  setAdInfoNoField: propertiesActions.setAdInfoNoField(dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdDetails)
