import React from 'react';
import errorImage from '../../common/images/error.png';
import doneImage from '../../common/images/listo.png';
import {
    Image,
    MainWrapper,
    BigText,
    SmallText,
    Code
} from './styled';

const MembershipDone = ({ error }) => (
    <MainWrapper>
        {error && (
            <>
                <BigText>¡Ocurrió un error!</BigText>
                <SmallText>
                    Guarda este código <Code>{error}</Code> y comunicate con nosotros para que te podamos ayudar
                </SmallText>
                <Image src={errorImage} />
            </>
        )}
        {!error && (
            <>
                <BigText>¡Todo listo, tu membresía ha sido contratada!</BigText>
                <SmallText>Recibirás un correo con toda la información de tu membresia</SmallText>
                <Image src={doneImage} />
            </>
        )}
    </MainWrapper>
);

export default MembershipDone;