import axios from 'axios'

export const inmuebles = (
  precioInicial, precioFinal, north, south, west, east, categoria, limit, offset,
  tipoInmuebleID, numeroHabitaciones, numeroBanos, numeroMediosBanos, numeroEstacionamiento,
  mascotas, areaConstruida, antiguedad, orderBy
  ) => {
    if(mascotas !== null){
      mascotas = mascotas ? 1 : 0
    }
  return axios.get('/inmuebles', {
    params:{
      precioInicial,
      precioFinal,
      north,
      south,
      west,
      east,
      categoria,
      limit,
      offset,
      tipoInmuebleID,
      numeroHabitaciones,
      numeroBanos,
      numeroMediosBanos,
      numeroEstacionamiento,
      mascotas,
      areaConstruida,
      antiguedad,
      orderBy
    }
  })
}

export const inmueblesMeta = (
  precioInicial, precioFinal, north, south, west, east, categoria, limit, offset,
  tipoInmuebleID, numeroHabitaciones, numeroBanos, numeroMediosBanos, numeroEstacionamiento,
  mascotas, areaConstruida, antiguedad, orderBy
  ) => {
  return axios.get('/inmuebles/meta', {
    params:{
      precioInicial,
      precioFinal,
      north,
      south,
      west,
      east,
      categoria,
      limit,
      offset,
      tipoInmuebleID,
      numeroHabitaciones,
      numeroBanos,
      numeroMediosBanos,
      numeroEstacionamiento,
      mascotas,
      areaConstruida,
      antiguedad,
      orderBy
    }
  })
}

export const inmueble = (inmuebleID) => {
  return axios.get('/inmueble', {
    params:{
      inmuebleID
    }
  })
}

export const amenidades = () => {
  return axios.get('/amenidades');
}

export const obtenerUsuarios = () => {
  return axios.get('/usuarios');
}

export const destacados = () => {
  return axios.get('/destacados')
}

export const uploadImages = (photos, id) => {
  return axios.post(
    `/inmueble/fotos/${id}`,
    photos
  )
}

export const registerAd = (data) => {
  return axios.post(
    '/inmuebles',
    data
  )
};


export const inmueblesPatrocinados = (
  precioInicial, precioFinal, north, south, west, east, categoria, offset,
  ) => {
  return axios.get('/inmuebles/destacados', {
    params:{
      precioInicial,
      precioFinal,
      north,
      south,
      west,
      east,
      categoria,
      offset,
    }
  })
}

export const inmueblesPatrocinadosMeta = (
  precioInicial, precioFinal, north, south, west, east, categoria, offset,
  ) => {
  return axios.get('/inmuebles/destacados/meta', {
    params:{
      precioInicial,
      precioFinal,
      north,
      south,
      west,
      east,
      categoria,
      offset,
    }
  })
}

export const inmueblesContact = (id) => axios.get(`/inmuebles/${id}/contacto/telefono`);

export const sendMessage = (id, data) => axios.post(
  `/inmuebles/${id}/contacto/mensaje`,
  data
);

export const usuarios = (tipoUsuarioID, activo, nombre, orderBy, limit, offset) => {
  let internalName = nombre;
  if (internalName === '') {
    internalName = null;
  }

  let internalActive = activo;
  if (internalActive === '-1'){
      internalActive = null;
  }

  let internalUser = tipoUsuarioID;
  if (internalUser === '-1'){
      internalUser = null;
  }

  return (axios.get('/admin/usuarios', {
    params:{
      tipoUsuarioID: internalUser,
      activo: internalActive,
      nombre: internalName,
      orderBy,
      limit,
      offset,
    }
  }));
};

export const usuariosMeta = (tipoUsuarioID, activo, nombre, orderBy, limit, offset) => {
  let internalName = nombre;
  if (internalName === '') {
    internalName = null;
  }

  let internalActive = activo;
  if (internalActive === '-1'){
      internalActive = null;
  }

  let internalUser = tipoUsuarioID;
  if (internalUser === '-1'){
      internalUser = null;
  }

  return ( axios.get('/admin/usuarios/meta', {
    params:{
      tipoUsuarioID: internalUser,
      activo: internalActive,
      nombre: internalName,
      orderBy,
      limit,
      offset,
    }
  }))
};

export const userStatus = (usuarioID, activo) => {
  return axios.post(
    `/admin/usuarios/${usuarioID}/status`,
    { activo }
  )
};

export const crearUsuarioPlataforma = (usuario) => {
  return axios.post(
    '/usuarios',
    { ...usuario }
  )
};

export const crearUsuarioAdmin = (usuario) => {
  return axios.post(
    '/admin/usuarios ',
    { ...usuario }
  )
};

export const editarUsuarioAdmin = (usuario, usuarioID) => {
  return axios.put(
    `/admin/usuarios/${usuarioID}`,
    { ...usuario }
  )
};


export const resetPasswordEmail = (email) => {
  return axios.post(
    '/usuarios/password/reset',
    { email }
  )
};

export const resetPasswordToken = (token, password) => {
  return axios.put(
    `/usuarios/password/reset/${token}`,
    { password }
  )
};

export const userProperties = (inmuebleID, patrocinado, destacado, statusID, limit, offset) => {
  let internalInmueble = inmuebleID;
  if (internalInmueble === '') {
    internalInmueble = null;
  }

  let internalSponsor = patrocinado;
  if (internalSponsor === '-1'){
    internalSponsor = null;
  }

  let internalDestacado = destacado;
  if (internalDestacado === '-1'){
    internalDestacado = null;
  }

  let internalStatus = statusID;
  if (internalStatus === '-1'){
    internalStatus = null;
  }

  return (axios.get('/user/inmuebles', {
    params:{
      inmuebleID: internalInmueble,
      patrocinado: internalSponsor,
      destacado: internalDestacado,
      statusID: internalStatus,
      limit,
      offset,
    }
  }));
};

export const userPropertiesMeta = (inmuebleID, patrocinado, destacado, statusID, limit, offset) => {
  let internalInmueble = inmuebleID;
  if (internalInmueble === '') {
    internalInmueble = null;
  }

  let internalSponsor = patrocinado;
  if (internalSponsor === '-1'){
    internalSponsor = null;
  }

  let internalDestacado = destacado;
  if (internalDestacado === '-1'){
    internalDestacado = null;
  }

  let internalStatus = statusID;
  if (internalStatus === '-1'){
    internalStatus = null;
  }

  return (axios.get('/user/inmuebles/meta', {
    params:{
      inmuebleID: internalInmueble,
      patrocinado: internalSponsor,
      destacado: internalDestacado,
      statusID: internalStatus,
      limit,
      offset,
    }
  }));
};

export const adminProperties = (inmuebleID, patrocinado, destacado, statusID, email, limit, offset) => {
  let internalInmueble = inmuebleID;
  if (internalInmueble === '') {
    internalInmueble = null;
  }

  let internalEmail = email;

  if (internalEmail === ''){
    internalEmail = null;
  }

  let internalSponsor = patrocinado;
  if (internalSponsor === '-1'){
    internalSponsor = null;
  }

  let internalDestacado = destacado;
  if (internalDestacado === '-1'){
    internalDestacado = null;
  }

  let internalStatus = statusID;
  if (internalStatus === '-1'){
    internalStatus = null;
  }

  return (axios.get('/admin/inmuebles', {
    params:{
      inmuebleID: internalInmueble,
      email: internalEmail,
      patrocinado: internalSponsor,
      destacado: internalDestacado,
      statusID: internalStatus,
      limit,
      offset,
    }
  }));
};

export const adminPropertiesMeta = (inmuebleID, patrocinado, destacado, statusID, email, limit, offset) => {
  let internalInmueble = inmuebleID;
  if (internalInmueble === '') {
    internalInmueble = null;
  }

  let internalEmail = email;
  if (internalEmail === ''){
    internalEmail = null;
  }

  let internalSponsor = patrocinado;
  if (internalSponsor === '-1'){
    internalSponsor = null;
  }

  let internalDestacado = destacado;
  if (internalDestacado === '-1'){
    internalDestacado = null;
  }

  let internalStatus = statusID;
  if (internalStatus === '-1'){
    internalStatus = null;
  }

  return (axios.get('/admin/inmuebles/meta', {
    params:{
      inmuebleID: internalInmueble,
      email: internalEmail,
      patrocinado: internalSponsor,
      destacado: internalDestacado,
      statusID: internalStatus,
      limit,
      offset,
    }
  }));
};

export const changePropertyStatus = (inmuebleID, statusID) => {
  return axios.put(
    `/admin/inmuebles/${inmuebleID}/status`,
    { statusID }
  )
};

export const changePropertyDestacado = (inmuebleID, destacado) => {
  return axios.put(
    `/admin/inmuebles/${inmuebleID}/destacado`,
    { destacado }
  )
}

export const changePropertyPatrocinado = (inmuebleID, patrocinado) => {
  return axios.put(
    `/admin/inmuebles/${inmuebleID}/patrocinado`,
    { patrocinado }
  )
}

export const changeUserPropertyStatus = (inmuebleID, statusID) => {
  return axios.put(
    `/user/inmuebles/${inmuebleID}/status`,
    { statusID }
  )
};

export const getMembershipTypes = () => axios.get(`/membresias/catalogo`);

export const getMembershipFrecuency = () => axios.get(`/membresias/frecuencia`);

export const getUserCards = () => axios.get(`/tarjetas`);

export const registerCard = (token_id, deviceIdHiddenFieldName) => {
  return axios.post(
    `/tarjetas`,
    {
      token_id,
      deviceIdHiddenFieldName,
    }
  )
};

export const registerMembership = (frecuenciaMembresiaID, tipoMembresiaID, amount, tarjetaID, deviceIdHiddenFieldName, inmuebleID) => {
  return axios.post(
    `/membresia`,
    {
      frecuenciaMembresiaID,
      tipoMembresiaID,
      amount,
      tarjetaID,
      deviceIdHiddenFieldName,
      inmuebleID: `${inmuebleID}`
    }
  )
};

export const addFavorite = (inmuebleID) => axios.post('/favoritos', { inmuebleID });

export const deleteFavorite = (inmuebleID) => axios.put('/favoritos',{ inmuebleID });

export const getFavorite = () => axios.get('/favoritos');

export const validateTokenEndpoint = () => axios.get('/validate');

export const getInfoEditAd = (inmuebleID) => axios.get('/inmueble/edicion', {
    params: {
    inmuebleID
  }
});

export const editAd = (data) => axios.put(
  '/inmueble/edicion',
  data
);

export const deleteImages = (mainImage, inmuebleImages, inmuebleID) => axios.delete(
  `/inmueble/fotos/${inmuebleID}`,
  {
    data: {
      inmuebleImages,
      mainImage
    }
  },
);

export const updateMainImage = (mainImage, inmuebleID) => axios.put(
  `/inmueble/fotos/${inmuebleID}`,
  {
    mainImage
  },
);

export const getImages = (inmuebleID) => axios.get(`/inmueble/fotos/${inmuebleID}`);

export const getUsuarioInfo = () => axios.get('/usuarios/informacion');

export const updateUsuarioInfo = (info) => axios.put('/usuarios/informacion', { ...info });

export const updatePassword = (password) => axios.put('/usuarios/password', { ...password })

export const sendContacto = (data) => axios.post('/usuarios/contacto', { ...data })