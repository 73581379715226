import { useReducer, useEffect } from 'react';
import { debounce } from 'debounce'
import { useAlert } from 'react-alert';
import {
  usuarios,
  usuariosMeta,
} from '../../../common/utils/api-helpers';
import { USERSLIMIT } from '../../../common/utils/constants';


const loading = (state, { loading }) => ({
    ...state,
    loading,
});

const requestData = (state, { usersList, totalPages }) => ({
    ...state,
    usersList,
    totalPages,
});

const changePage = (state, { offset, actualPage }) => ({
    ...state,
    offset,
    actualPage,
});

const statusChange = (state, { statusChange }) => ({
  ...state,
  statusChange,
});

const changeFilter = (state, { userTypeFilter, activeFilter, nameFilter, orderByFilter }) => ({
    ...state,
    userTypeFilter,
    activeFilter,
    nameFilter,
    orderByFilter,
    offset: 0
});

const reducerActions = {
    LOADING: loading, 
    REQUEST_DATA: requestData,
    CHANGE_FILTER: changeFilter,
    CHANGE_PAGE: changePage,
    STATUS_CHANGE: statusChange,
}

const reducer = (state, action) => reducerActions[action.type](state, action.payload);

const getUsers = debounce(async(userTypeFilter, activeFilter, nameFilter, orderByFilter, offset, alert, dispatch) => {
  try {
    dispatch({ type: 'LOADING', payload: { loading: true }});

    const data = await usuarios(userTypeFilter, activeFilter, nameFilter, orderByFilter, USERSLIMIT, offset);

    const metaData = await usuariosMeta(userTypeFilter, activeFilter, nameFilter, orderByFilter, USERSLIMIT, offset);

    if (data.data && metaData.data && metaData.data.hasOwnProperty('total')) {
      dispatch({
        type: 'REQUEST_DATA',
        payload: { usersList: data.data, totalPages: Math.ceil(metaData.data.total/USERSLIMIT) }
      });
    }else{
      alert.error('Ocurrió un error, intenta de nuevo más tarde');
    }
  } catch(err) {
    alert.error('Ocurrió un error, intenta de nuevo más tarde');
    console.log(err);
  } finally {
    dispatch({ type: 'LOADING', payload: { loading: false }});
  }
}, 500);

const useUserFilters = () => {
    const alert = useAlert();
    const initialState = {
        usersList: [],
        totalPages: 0,
        loading: false,
        userTypeFilter: { value: '-1', label: "Todos" },
        activeFilter: { value: '-1', label: "Todos" },
        nameFilter: '',
        orderByFilter: { value: 1, label: "Nombre A-Z" },
        offset: 0,
        actualPage: 1,
        statusChange: false,
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        getUsers(
            state.userTypeFilter.value, state.activeFilter.value, state.nameFilter,
            state.orderByFilter.value, state.offset, alert, dispatch
        );
      }, [
          state.userTypeFilter.value, state.activeFilter.value, state.nameFilter,
          state.orderByFilter.value, state.offset, state.statusChange, alert, dispatch
    ]);

    return [state, dispatch];
}

export default useUserFilters;
