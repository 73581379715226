import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { store } from './store/configureStore'
import TagManager from 'react-gtm-module'

const options = {
  position: 'top center',
  timeout: 5000,
  offset: '90px',
  transition: 'scale'
}

const tagManagerArgs = {
  gtmId: 'GTM-TC6QWCM'
}

TagManager.initialize(tagManagerArgs)

const Root = () => (
    <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...options}>
            <App />
        </AlertProvider>
    </Provider>
)

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
