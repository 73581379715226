/* eslint-disable no-undef*/
import { useState, useEffect } from 'react';

const useOpenPay = () => {
    const [deviceSessionId, setDeviceSessionId] = useState('');

    useEffect(() => {
        OpenPay.setId(process.env.REACT_APP_OPENPAY_ID);
        OpenPay.setApiKey(process.env.REACT_APP_OPENPAY_API_KEY);
        OpenPay.setSandboxMode(process.env.REACT_APP_OPENPAY_SANDBOX);
        setDeviceSessionId(OpenPay.deviceData.setup("payment-form", "deviceIdHiddenFieldName"));
      }, [])

      return { deviceSessionId };
};

export default useOpenPay;