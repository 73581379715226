import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

const FooterWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 80px;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  background: #022169;
  position: relative;
  z-index: 900;
`

const FooterLogo = styled.img`
  height: 60px;
`

const FooterText = styled.p`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  margin: 0 0 5px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`

const FooterSocial = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`

const SocialIcon = styled.img`
  height: 20px;
  margin: 0 15px;
  cursor: pointer;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`

/*const TyC = styled(Link)`
  color: #FF7F32;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 5px;
  text-decoration: none;
`*/

//const instagramImage = require('../common/images/Instagram.svg')
const facebookImage = require('../common/images/Facebook.svg')
//const twitterImage = require('../common/images/Twitter.svg')
const logoNeg = require('../common/images/logo-neg.svg')

const Footer = () => {
  const history = useHistory()

  return (
    <FooterWrapper relative={history.location.pathname === '/'}>
      <FooterLogo src={logoNeg} />
      <TextWrapper>
        {/*<TyC to='/terminos-y-condiciones'>Términos y condiciones</TyC>
        <TyC to='/politicas-privacidad'>Políticas de privacidad</TyC>*/}
        <FooterText>® Flash Home 2020. Hecho con ♡ en CDMX.</FooterText>
      </TextWrapper>
      <FooterSocial>
        <SocialIcon onClick={() => {
          window.open('https://www.facebook.com/FlasHome-102763391861973', '_blank');
        }}src={facebookImage}/>
        {/*  <SocialIcon src={twitterImage}/>
        <SocialIcon src={instagramImage}/> */}
      </FooterSocial>
    </FooterWrapper>
  )
}

export default Footer
