import styled from 'styled-components';
import { ReactSVG } from 'react-svg'

export const Icon = styled(ReactSVG)`
  height: 100px;
  width: 100px;
  margin-bottom: 33px;    
  svg {
    fill: #BABFC9;
    height: 100px;
    width: 100px;
  }
`
export const MainWrapper = styled.div`
    widht: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 200px 0 ;
`;

export const Text = styled.div`
    font-size: 25px;
    color: #BABFC9;
    @media (max-width: 768px) {
      text-align: center;
    }
`;
