import styled from 'styled-components';
import { COLORS } from '../../common/utils/constants';

export const MainWrapper = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 3;
    background-color: white;
    width: 750px;
    height: 350px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 50px;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 90%;
        height: 400px;
        overflow-y: scroll;
        padding: 10px;
        top: 55%;
    }
`;

export const CardDataWrapper = styled.div`
    width: 100%;
    z-index: 5;
    height: 340px;
`

export const Title = styled.div`
  font-size: 30px;
  color: ${COLORS.primaryOrange};
  font-weight: 500;
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

export const CloseButton = styled.img`
    position: absolute;
    color: white;
    z-index: 4;
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 35px;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;

export const ExpirationWrapper = styled.div`
    display: flex;
`

export const Separator = styled.div`
    width: 50px;

    @media (max-width: 768px) {
        width: 10px;
    }
`

export const CardImage = styled.img``;

export const CardTitle = styled.div`
    padding-left: 8px;
`;

export const CardWrapper = styled.div`
    display: flex;
    margin-top: 30px;
`;

export const Card = styled.div`

    &:first-of-type {
        border-right: 1px solid ${COLORS.darkBlue};
        padding-right: 5px;
    }

    &:nth-of-type(2) {
        margin-left: 5px;
    }
`