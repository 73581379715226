import styled from 'styled-components';
import { COLORS } from '../../common/utils/constants';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MembershipInfo = styled.div``;

export const CardInfo = styled.div``;

export const MembershipText = styled.p``;

export const MembershipTextHighlight = styled.strong`
    color: ${COLORS.primaryOrange};
`;