import styled from 'styled-components';

export const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  min-height: calc(100vh - 160px);
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const LoginFormWrapper = styled.form`
  background: #FFF;
  padding: 30px;
  width: 600px;
  height: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
      width: 100%;
      padding: 0 22px 22px;
  }
`;

export const LoginForm = styled.div`
  width: 100%;
  margin-top: 50px;
  min-height: 130px;

  @media (max-width: 768px) {
      margin-top: 24px;
  }
`

export const LoginTitle = styled.p`
  font-size: 30px;
`;

export const LoginLabel = styled.p`
  font-size: 15px;
  font-weight: bold;
`;

export const LoginText = styled.p``

export const InputContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  @media (max-width: 768px) {
      margin-top: 24px;
  }
`

export const ToLoginText = styled.p`
  text-align: right;
  @media (max-width: 768px) {
      text-align: center;
  }
`

export const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const LoginLink = styled.span`
    color: rgb(15,137,255);
    cursor: pointer;
`
