import React, { useEffect, useState }  from 'react'
import { connect } from 'react-redux'
import { propertiesActions } from '../../store/properties/reducer'
import { amenidades } from '../../common/utils/api-helpers';
import Checkbox from '../../components/Checkbox'
import { 
    AmenitiesWrapper,
    AmenitiesButtonWrapper,
} from './RegisterProperty.styled'
import Load from '../../components/loader';
import Button from '../../components/Button'
import { useAlert } from 'react-alert'

const Amenities = ({ changeStep, amenities, setAmenities }) => {
    const [amenitiesData, setAmenitiesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        const getData = async() => {
            setLoading(true);
            try {
                const res = await amenidades();
                const amenitiesArray = res.data.result.map((el) => {
                    return {'label': el.descripcion, 'value': el.amenidadID };
               });
               setLoading(false);
               setAmenitiesData(amenitiesArray);
            } catch (e) {
                setLoading(false);
                alert.error('Ocurrió un error, intenta de nuevo más tarde');
            }
        };
        getData();
    }, [alert]);


    const changeAmenity = (value, checked) => {
        let selectedAmenities = amenities.slice()
        if(!checked) {
            selectedAmenities.splice(selectedAmenities.indexOf(value), 1)
            setAmenities(selectedAmenities)
        } else {
            selectedAmenities.push(value)
            setAmenities(selectedAmenities)
        }
    }

    return (
        <>
            <AmenitiesWrapper className={loading && 'loading'}>
                { amenitiesData.map(amenity => (
                    <Checkbox 
                        value={amenity.value} 
                        onChange={(checked) => changeAmenity(amenity.value, checked)}
                        label={amenity.label}
                        key={amenity.value}
                        checked={amenities.includes(amenity.value)}
                    />
                ))} 
                {loading && (
                    <Load />
                )}
            </AmenitiesWrapper>
            <AmenitiesButtonWrapper>
                <Button
                    width='150px'
                    secondary
                    onClick={() => changeStep(false)}
                >
                    Regresar
                </Button>
                <Button
                    width='150px'
                    margin='0 0 0 10px'
                    onClick={() => changeStep()}>
                    Continuar
                </Button>
            </AmenitiesButtonWrapper>
        </>
    )
}

const mapStateToProps = ({ properties }) => {
    const { amenities } = properties
    return { amenities }
}

const mapDispatchToProps = dispatch => ({
    setAmenities: propertiesActions.setAmenities(dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Amenities)