import React from 'react';
import {
    MainWrapper,
    MembershipInfo,
    MembershipTextHighlight,
    MembershipText,
    CardInfo,
} from './styled';
import CardSelect from '../card-select';

const MembershipPay = ({
    membership, 
    frecuency,
    card,
    setCard,
}) => {

    return(
        <MainWrapper>
            <MembershipInfo>
               <MembershipText>
                    {`Estas por contratar la membresia `}
                    <MembershipTextHighlight>
                        {membership.nombre}
                    </MembershipTextHighlight>
                    {` por `}
                    <MembershipTextHighlight>
                        {`${frecuency.descripcion}. `}
                    </MembershipTextHighlight>
                </MembershipText> 
            </MembershipInfo>
            <CardInfo>
                <MembershipText>
                    {'Selecciona la tarjeta con la que quieres hacer el pago:'}
                </MembershipText>
                <CardSelect
                    card={card}
                    setCard={setCard}
                />
            </CardInfo>
        </MainWrapper>
    )
};

export default MembershipPay;