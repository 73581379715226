import { useReducer, useEffect } from 'react';
import { debounce } from 'debounce'
import { useAlert } from 'react-alert';
import {
  inmuebles,
  inmueblesMeta,
  inmueblesPatrocinados,
} from '../../common/utils/api-helpers';
import { PROPERTYLIMIT } from '../../common/utils/constants';

export const changeValues = (state, history) => {
  let search = '?';
  if(state.hasOwnProperty('min') ){
      search = search + `min=${state.min}&`
  } if(state.max){
      search = search + `max=${state.max}&`
  } if(state.categoryType){
      search = search + `categoryType=${state.categoryType}&`
  } if(state.lat){
      search = search + `lat=${state.lat}&`
  } if(state.lng){
      search = search + `lng=${state.lng}&`
  } if(state.address){
      search = search + `address=${state.address}&`
  } if(state.offset){
      search = search + `offset=${state.offset}&`
  } if(state.actualPage){
      search = search + `actualPage=${state.actualPage}&`
  } if(state.propertyType){
      search = search + `propertyType=${state.propertyType}&`
  } if(state.bedrooms){
      search = search + `bedrooms=${state.bedrooms}&`
  } if(state.bathrooms){
      search = search + `bathrooms=${state.bathrooms}&`
  } if(state.halfBathrooms !== null){
    search = search + `halfBathrooms=${state.halfBathrooms}&`
  } if(state.parkingLots){
      search = search + `parkingLots=${state.parkingLots}&`
  } if(state.pets){
      search = search + `pets=${state.pets}&`
  } if(state.area){
      search = search + `area=${state.area}&`
  } if(state.age){
      search = search + `age=${state.age}&`
  } if(state.orderBy){
    search = search + `orderBy=${state.orderBy}`
}
  
  history.push({
      pathname: '/lista-propiedades',
      search: search
  })
};

const loading = (state, { loading }) => ({
    ...state,
    loading,
});

const requestData = (state, { inmueblesList, totalPages }) => ({
    ...state,
    inmueblesList,
    totalPages,
});

const changeMarker = (state, { hoveredMarker }) => ({
    ...state,
    hoveredMarker,
});

const change_bounds = (state, { bounds }) => ({
    ...state,
    offset: 0,
    actualPage: 1,
    bounds,
});

const change_filter = (state, { showFilter }) => ({
    ...state,
    showFilter,
});

const reducerActions = {
    LOADING: loading, 
    REQUEST_DATA: requestData,
    CHANGE_MARKER: changeMarker,
    CHANGE_BOUNDS: change_bounds,
    CHANGE_FILTER: change_filter,
}

const reducer = (state, action) => reducerActions[action.type](state, action.payload);

const getInmuebles = debounce(async(min, max, bounds, offset, categoryType, 
  propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots,
  pets, area, age, orderBy, lat, lng, actualPage, alert, dispatch) => {
  try {
    const isMobile = window.innerWidth < 768;
    let internalBounds;
    const addLocation = process.env.REACT_APP_MOBILE_SEARCH_BOUNDS || 0.06;
    if (isMobile) {
      internalBounds = {
        northEastLat: Number(lat) + addLocation,
        southWestLat: Number(lat) - addLocation,
        northEastLng: Number(lng) + addLocation,
        southWestLng: Number(lng) - addLocation,
      }
    } else {
      internalBounds = {
        northEastLat: bounds.getNorthEast().lat(),
        southWestLat: bounds.getSouthWest().lat(),
        northEastLng: bounds.getNorthEast().lng(),
        southWestLng: bounds.getSouthWest().lng(),
      }
    }

    dispatch({ type: 'LOADING', payload: { loading: true }});

    const data = await inmuebles(
      min, max, internalBounds.northEastLat, internalBounds.southWestLat,
      internalBounds.southWestLng, internalBounds.northEastLng, categoryType,
      PROPERTYLIMIT, offset, propertyType, bedrooms, bathrooms, halfBathrooms, 
      parkingLots, pets, area, age, orderBy);

    const metaData = await inmueblesMeta(
      min, max, internalBounds.northEastLat, internalBounds.southWestLat,
      internalBounds.southWestLng, internalBounds.northEastLng, categoryType,
      PROPERTYLIMIT, offset, propertyType, bedrooms, bathrooms, halfBathrooms,
      parkingLots, pets, area, age, orderBy);

    /*let sponsoredData = await inmueblesPatrocinados(
      min, max, internalBounds.northEastLat, internalBounds.southWestLat,
      internalBounds.southWestLng, internalBounds.northEastLng, categoryType,
      actualPage * 3 - 3);*/
    
  
    if (data.data && metaData.data && metaData.data.hasOwnProperty('total')) {
      let finalData = data.data
      /*if (sponsoredData.data && sponsoredData.data.length > 0) {
        sponsoredData.data.forEach((sponsoredElement) => {
          sponsoredElement.fromSponsoredList = true;
        });
        finalData = [ ...sponsoredData.data, ...finalData];
      }*/
      dispatch({
        type: 'REQUEST_DATA',
        payload: { inmueblesList: finalData, totalPages: Math.ceil(metaData.data.total/PROPERTYLIMIT) }
      });
    }else{
      alert.error('Ocurrió un error, intenta de nuevo más tarde');
    }
  } catch(err) {
    alert.error('Ocurrió un error, intenta de nuevo más tarde');
  } finally {
    dispatch({ type: 'LOADING', payload: { loading: false }});
  }
}, 350);

const usePropertyFilters = (
  {min, max, offset, categoryType, propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots, pets, area, age, orderBy, lat, lng, actualPage}
  ) => {
    const alert = useAlert();
    const initialState = {
        inmueblesList: [],
        hoveredMarker: null,
        bounds: null,
        totalPages: 0,
        loading: false,
        showFilter: false
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        if(state.bounds) {
          getInmuebles(
            min, max, state.bounds, offset, categoryType, 
            propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots,
            pets, area, age, orderBy, lat, lng, actualPage, alert, dispatch, 
          );
        }
      }, [
        min, max, state.bounds, offset, categoryType, 
        propertyType, bedrooms, bathrooms, halfBathrooms, parkingLots,
        pets, area, age, orderBy, lat, lng, actualPage, alert, dispatch])

    return [state, dispatch];
}

export default usePropertyFilters;
