import styled from 'styled-components';
import { COLORS } from '../../common/utils/constants';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: ${props => props.isSmall ? '400px': '450px'};
    padding: 15px;

    ${props => {
        if (props.index === 1){
            return`box-shadow: -5px 0px 10px 0px ${COLORS.gray};`
        } else if (props.index === 2) {
            return`box-shadow: 0px 0px 10px 0px ${COLORS.gray};`
        } else{
            return`box-shadow: 5px 0px 10px 0px ${COLORS.gray};`
        }
    }};

    @media (max-width: 768px) {
        box-shadow: 0px 0px 10px 0px ${COLORS.gray};
        margin-bottom: 10px;
        height: 450px;
    }
`;

export const CardTitle = styled.h2`
    margin: 30px 0;
`

export const FrecuencyTitle = styled.h4`
    margin: 20px 0;
`

export const TitleSeparator = styled.div`
    height: 4px;
    width: 50px;
    background-color: ${COLORS.primaryOrange}
`

export const PriceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 30px;
`

export const FinalPriceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 10px;
`

export const Price = styled.div`
    font-size: 50px;
    color: black;
    font-weight: bold;
`

export const PriceSubtitle = styled.div`
    font-size: 13px;
    margin-bottom: 10px;
    margin-left: 5px;
`

export const Description = styled.div`
    display: flex;
    height: 160px;
    margin-top: 30px;
    margin-bottom: 15px;
    overflow-y: scroll;
`

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
`

export const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow: scroll;
`

export const RadioButtonLabel = styled.label`
    font-size: ${props => props.isSmall ? '15px' : '20px'};
    margin-left: 5px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`
