import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactSVG } from 'react-svg'
import { COLORS } from '../common/utils/constants'

const SwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
`

const Span = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.isChecked ? COLORS.darkBlue : '#ccc'};
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
    ${props => props.isChecked && (`
      -webkit-transform: translateX(24px);
      -ms-transform: translateX(24px);
      transform: translateX(24px);`
    )}
}
`

const Input = styled.input`
  opacity: 0;
    width: 0;
    height: 0;

    &:focus {
        box-shadow: 0 0 1px #2196F3;
    }

    ${props => props.isChecked && (`
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px);`
    )}
`

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;

  &.filter {
      padding-left: 13px;

      @media (max-width: 768px) {
          padding-left: 10px;
      }
  }
`

const ToggleIcon = styled(ReactSVG)`
  width: 25px;
  height: 25px;
  margin-right: 10px;

  svg {
    width: 25px;
    height: 25px;
  }

  &.filter {
    margin-right: 20px;
  }

  path {
    stroke: #000;
  }
`

const ToggleLabel = styled.p`
  margin-right: 15px;
  color: #000;
  font-size: 14px;
`

const RightWrapper = styled.div`
  &.filter {
    padding-right: 13px;

    @media (max-width: 768px) {
        padding-right: 10px;
      }
  }
`


const ToggleButton = ({ label, icon, onChange = () => {}, valueProp = false, toggleClass = '' }) => {
    const [ isChecked, setIsChecked ] = useState(valueProp)

    useEffect(() => {
        onChange(isChecked)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecked]);

    useEffect(() => {
      setIsChecked(valueProp);
    }, [valueProp]);

    return (
        <ToggleWrapper>
            <LeftWrapper className={toggleClass}>
                {icon && <ToggleIcon className={toggleClass} src={icon} />}
                <ToggleLabel className={toggleClass} >{label}</ToggleLabel>
            </LeftWrapper>
            <RightWrapper className={toggleClass}>
                <SwitchWrapper className="switch">
                    <Input
                        type="checkbox"
                        onChange={(e) => setIsChecked(e.target.checked)}
                        isChecked={isChecked}
                    />
                    <Span isChecked={isChecked} className="slider round"></Span>
                </SwitchWrapper>
            </RightWrapper>
        </ToggleWrapper>
    )
}

export default ToggleButton;
