import TagManager from 'react-gtm-module'

export const formSubmission = (parameters) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'formSubmission',
      formId: 'searchPropertyLanding',
      searchProperties: 'busqueda de propiedades',
      parameters: parameters
    }
  }

  TagManager.dataLayer(tagManagerArgs)
}
