import { useReducer, useEffect } from 'react';
import { useAlert } from 'react-alert';
import moment from 'moment';
import { inmuebles, inmueble, inmueblesContact } from '../../common/utils/api-helpers';
import { getAddress } from '../../common/utils/helpers';

const loading = (state, { loading }) => ({
  ...state,
  loading,
});

const requestData = (state, { inmuebleData, address, moreImagesToDisplay, availableDate, relatedAds, contactData }) => ({
  ...state,
  inmuebleData,
  address,
  moreImagesToDisplay,
  availableDate,
  relatedAds,
  contactData,
  loading: false,
});

const notPublished = (state,) => ({
    ...state,
    notPublished: true,
    loading: false,
});

const fullscreenModal = (state, { fullscreenModal, galleryIndex  }) => {
  if(fullscreenModal === 1 || fullscreenModal === 2) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }
  return ({
    ...state,
    fullscreenModal,
    galleryIndex
  })
}

const reducerActions = {
    LOADING: loading,
    REQUEST_DATA: requestData,
    FULLSCREEN_MODAL: fullscreenModal,
    NOT_PUBLISHED: notPublished,
  }

const reducer = (state, action) => reducerActions[action.type](state, action.payload);

const usePropertyDetail = ({ id }) => {
    const alert = useAlert();
    const initialState = {
        inmuebleData: null,
        loading: false,
        address: "",
        availableDate: null,
        relatedAds: [],
        fullscreenModal: 0,
        moreImagesToDisplay: 0,
        galleryIndex: 0,
        contactData: {},
        notPublished: false,
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const changePrice = 10000;
    const addLocation = 0.5;
    const relatedAdsToShow = 5;

    useEffect(() => {
        async function getData() {
            try {
                dispatch({ type: 'LOADING', payload: { loading: true }});
                const data = await inmueble(id);
                if (data.not_published) {
                    dispatch({ type: 'NOT_PUBLISHED'});
                } else {
                    const inmuebleData = data.data.result[0]
                    const address = getAddress(inmuebleData.address.calle, inmuebleData.address.numeroExterior, inmuebleData.address.colonia, inmuebleData.address.ciudad);

                    let moreImagesToDisplay;
                    if (inmuebleData.urls && inmuebleData.urls.length > 3) {
                        moreImagesToDisplay = inmuebleData.urls.length - 3;
                    }

                    let availableDate;
                    if (inmuebleData.disponibilidad) {
                        availableDate = moment(inmuebleData.disponibilidad).format('DD/MM/YYYY');
                    }

                    const relatedData = await inmuebles(
                        inmuebleData.precio - changePrice, inmuebleData.precio + changePrice,
                        inmuebleData.latitude + addLocation, inmuebleData.latitude - addLocation,
                        inmuebleData.longitude - addLocation, inmuebleData.longitude + addLocation,
                        inmuebleData.categoria, relatedAdsToShow, 0, null, inmuebleData.numeroHabitaciones - 1,
                        inmuebleData.numeroBanos - 1, 0,  inmuebleData.numeroEstacionamiento - 1,
                        null, null, null, 0
                    );
                    let relatedAds = [];
                    if (relatedData.data && relatedData.data.length > 0) {
                        relatedAds = relatedData.data.filter(el => el.inmuebleID !== parseInt(id));
                    }

                    const contactResult = await inmueblesContact(id);
                    let contactData = {};
                    if(contactResult.data && contactResult.data.result && contactResult.data.result.length > 0){
                        contactData = contactResult.data.result[0];
                    }

                    dispatch({ type: 'REQUEST_DATA', payload: { inmuebleData, address, moreImagesToDisplay, availableDate, relatedAds, contactData }});
                }
            } catch(e) {
                alert.error(`Ocurrió un error, intenta de nuevo más tarde${id}}`);
                dispatch({ type: 'NOT_PUBLISHED'});
                console.log(e);
            }
        };
        getData();
      }, [id, alert])

  return [state, dispatch];
}

export default usePropertyDetail;
