import React, { useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { debounce } from 'debounce'
import Heart from '../../common/images/heart.svg';
import HeartSiluette from '../../common/images/heartSiluette.svg';
import {
    MainWrapper
} from './styled';
import {
    addFavorite,
    deleteFavorite,
} from '../../common/utils/api-helpers';

const FavoriteButton = ({ inmuebleID, defaultProp, noMargin = false }) => {
    const [isFavorite, setIsFavorite] = useState(defaultProp);
    const classNameMargin = useMemo(() => (noMargin ? 'noMargin' : ''), [noMargin]);
    const changeFavorite = debounce(async () => {
        try {
            if (isFavorite) {
                await deleteFavorite(inmuebleID);
            } else {
                await addFavorite(inmuebleID);
            }
            console.log(isFavorite);
            setIsFavorite(!isFavorite);
        } catch (err) {
            console.log(err);
        }
    }, 500, true);

    return(
        <MainWrapper
            className={isFavorite ? `isFavorite ${classNameMargin}` : classNameMargin}
            onClick={changeFavorite}
        >
            <ReactSVG src={isFavorite ? Heart : HeartSiluette}/>
        </MainWrapper>
    );
};

export default FavoriteButton;