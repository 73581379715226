/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { useAlert } from 'react-alert'
import { userActions } from '../../store/user/reducer'
import Button from '../../components/Button'
import Loader from '../../components/loader';
import InputField from '../../components/InputField'
import { resetPasswordToken } from '../../common/utils/api-helpers';

import {
  LoginWrapper,
  LoginForm,
  LoginTitle,
  LoginText,
  LoginFormWrapper,
  InputContainer,
  ButtonsWrapper,
} from './styled'

const passwordIcon = require('../../common/images/password.svg')

const ResetPassword = (props) => {
  const [ password, setPassword ] = useState('');
  const [ passwordConfirm, setPasswordConfirm ] = useState('');
  const [ passwordError, setPasswordError ] = useState('');
  const [ passwordConfirmError, setPasswordConfirmError ] = useState('');
  const [ wrongPassword, setWrongPassword ] = useState(false);
  const [ signTried, setSignTried ] = useState(false)
  const [ loading, setLoading ] = useState(false);
  const alert = useAlert();

  const resetPassword = async() => {
    if(password === '' || passwordConfirm === '' ) {
      alert.error('Debes llenar todos los campos');
      setSignTried(true);
    }else{
      if (password !== passwordConfirm) {
        alert.error('Las contraseñas no coinciden');
        setWrongPassword(true);
        setSignTried(true);
      } else {
        setLoading(true);
        try {
          await resetPasswordToken(props.match.params.token, password);
          setLoading(false);
          alert.success('Contraseña actualizada con éxito')
          props.history.push('/login');
        } catch(err) {
          console.log(err)
          alert.error('Ocurrió un error, intenta de nuevo más tarde')
        }
      }
    }
  }

  return (
    <LoginWrapper>
      {loading && (
        <Loader />
      )}
      {!loading && (
        <LoginFormWrapper>
          <LoginTitle>Cambiar contraseña</LoginTitle>
          <LoginText>Introduce tu nueva contraseña</LoginText>
          <LoginForm>
            <InputContainer>
              <InputField
                icon={passwordIcon}
                value={password}
                error={signTried && (!password || wrongPassword)}
                onChange={p => setPassword(p)}
                placeholder="Contraseña"
                type="password"
                minChar={6}
                suffixError="Mínimo 6 caracteres"
                internalError={passwordError}
                setInternalError={setPasswordError}
              />
            </InputContainer>
            <InputContainer>
              <InputField
                icon={passwordIcon}
                value={passwordConfirm}
                error={signTried && (!password || wrongPassword)}
                onChange={p => setPasswordConfirm(p)}
                placeholder="Confirmar Contraseña"
                type="password"
                internalError={passwordConfirmError}
                setInternalError={setPasswordConfirmError}
              />
            </InputContainer>
          </LoginForm>
          <ButtonsWrapper>
            <Button
              width='45%'
              onClick={resetPassword}
              disabled={passwordConfirmError || passwordError}
              className={(passwordConfirmError || passwordError) && 'disabled'}
            >
              Cambiar contraseña
            </Button>
          </ButtonsWrapper>
        </LoginFormWrapper>
      )}
    </LoginWrapper>
  )
}

const mapDispatchToProps = dispatch => ({
  setUser: userActions.setUset(dispatch)
})

export default connect(null, mapDispatchToProps)(withRouter(ResetPassword))
