import { handleActions } from 'redux-actions'

export const types = {
    SET_GENERAL_INFO: 'GENERAL_INFO',
    SET_GENERAL_INFO_NO_FIELD: 'GENERAL_INFO_NO_FIELD',
    SET_AMENITIES: 'SET_AMENITIES',
    SET_AD_INFO: 'SET_AD_INFO',
    SET_AD_INFO_NO_FIELD: 'SET_AD_INFO_NO_FIELD',
    RESET_DATA: 'RESET_DATA',
    RESET_DATA_VALUES: 'RESET_DATA_VALUES',
}

const initialValues = {
    generalInfo: {
        lat: 19.432446,
        lng: -99.133204,
        propertyType: 0,
        age: '',
        area: '',
        groundArea: '',
        bedrooms: 0,
        bathrooms: 0,
        halfBathrooms: 0,
        parkingLots: 0,
        pets: false,
        categoryType: 1,
        street_number: '',
        route: '',
        neighborhood: '',
        locality: '',
        postal_code: '',
    },
    amenities: [],
    adInfo: {
        title: '',
        price: '',
        availability: new Date(),
        description: '',
        deposit: '',
        manteinance: '',
        miniumPeriod: 0,
        negotiable: false,
        propertyUser: null,
    }
}

export const propertiesActions = {
    setGeneralInfo: dispatch => (fieldName, fieldValue) => {
        dispatch({ type: types.SET_GENERAL_INFO, fieldName, fieldValue })
    },
    setGeneralInfoNoField: dispatch => (values) => {
        dispatch({ type: types.SET_GENERAL_INFO_NO_FIELD, values })
    },
    setAdInfo: dispatch => (fieldName, fieldValue) => {
        dispatch({ type: types.SET_AD_INFO, fieldName, fieldValue })
    },
    setAdInfoNoField: dispatch => (values) => {
        dispatch({ type: types.SET_AD_INFO_NO_FIELD, values })
    },
    setAmenities: dispatch => (amenities) => {
        dispatch({ type: types.SET_AMENITIES, amenities })
    },
    resetData: dispatch => () => {
        dispatch({ type: types.RESET_DATA })
    },
    resetDataValues: dispatch => (values) => {
        dispatch({ type: types.RESET_DATA_VALUES, values })
    }
}

export const propertiesReducer = 
    handleActions({
        [types.SET_GENERAL_INFO]: (state, action) => {
            const prevData = { ...state.generalInfo }
            prevData[action.fieldName] = action.fieldValue
            return ({
                ...state,
                generalInfo: prevData
            })
        },
        [types.SET_GENERAL_INFO_NO_FIELD]: (state, action) => {
            const newData = { ...state.generalInfo, ...action.values }
            return ({
                ...state,
                generalInfo: newData
            })
        },
        [types.SET_AD_INFO]: (state, action) => {
            const prevData = { ...state.adInfo }
            prevData[action.fieldName] = action.fieldValue
            return ({
                ...state,
                adInfo: prevData
            })
        },
        [types.SET_AD_INFO_NO_FIELD]: (state, action) => {
            const newData = { ...state.adInfo, ...action.values }
            return ({
                ...state,
                adInfo: newData
            })
        },
        [types.SET_AMENITIES]: (state, action) => ({
            ...state,
            amenities: action.amenities
        }),
        [types.RESET_DATA]: () => ({
            ...initialValues,
        }),
        [types.RESET_DATA_VALUES]: (_, action) => ({
                ...action.values,
        })
    }, initialValues)