import React, { useState, useEffect } from 'react'
import Button from './Button'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { connect } from 'react-redux'
import { uploadImages, getImages, deleteImages, updateMainImage } from '../common/utils/api-helpers'
import { useAlert } from 'react-alert';
import Load from '../components/loader';
import TwoStepsButton from '../components/TwoStepsButton';
import { COLORS } from '../common/utils/constants';
import { propertiesActions } from '../store/properties/reducer'
const trash = require('../common/images/trash.svg')
const star = require('../common/images/star.svg')

const ImagesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`

const ImagesSectionWrapper = styled.label`
  float: left;
  clear: both;
  width: 100%;
  min-height: 200px;
  padding: 2rem 1.5rem;
  text-align: center;
  background: #fff;
  border-radius: 7px;
  border: 3px solid #eee;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
`

const UploadInfoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ImagePreview = styled.img`
  width: 250px;
  object-fit: cover;

  @media (max-width: 1024px) {
    width: 100%;
    max-height: 350px;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const ImagesInput = styled.input`
  display: none;
`

const ImagesForm = styled.form`
  position: relative;
  width: 100%;
  min-height: 300px;
`

const ButtonFiles = styled.div`
  background-color: ${COLORS.darkBlue};
  padding: 15px 25px;
  color: #FFF;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 200px;
  margin: 15px 0 0 0;
`

const Option = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 0;
`

const OptionIcon = styled(ReactSVG)`
  width: 25px;
  cursor: pointer;
  margin-right: 10px;

  div {
    display: flex;
  }

  svg {
    width: 25px;
    height: 25px;
    fill: ${props => props.isMain ? 'red' : ''};
  }
`

const ImagePreviewWrapper = styled.div`
  width: calc(50% - 20px);
  position: relative;
  margin-top: 10px;
  display: flex;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
   width: 100%;
  }
`

const OptionsWrapper = styled.div`
  height: 50px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 0px;
  width: calc(100% - 250px);
  justify-content: center;
`

const MultiImageUpload = ({ registerAd, changeStep, resetData, savedAdId, setSavedAdId, isEdit }) => {
  const [ files, setFiles ] = useState([])
  const [ main, setMain ] = useState(0)
  const [ loading, setLoading ] = useState(false);
  const alert = useAlert();
  const history = useHistory();

  useEffect(() => {
    setLoading(true)
    async function registerAdFunction() {
        const adId = await registerAd();
        setSavedAdId(adId);
    }
    if (!savedAdId) {
      registerAdFunction()
    }
    setLoading(false)
  }, [registerAd])

  useEffect(() => {
    async function downloadImages() {
      if (isEdit) {
        try {
          setLoading(true);
          const res = await getImages(isEdit);
          setFiles(res.data.urls);
          setMain(res.data.mainImage);
        } catch(e) {
          const adId = await registerAd();
          setSavedAdId(adId);
          alert.error('Ocurrió un error, intenta de nuevo más tarde');
        }
        setLoading(false);
      }
    }
    downloadImages();
  }, [isEdit]);

  const uploadMainImage = async (key) => {
    setLoading(true)
    await updateMainImage(key, isEdit || savedAdId)
    setMain(key)
    setLoading(false)
  }

  const uploadMultipleFiles = async (e) => {
    setLoading(true)
    const filesObj = [...e.target.files];
    let filesArray = [];
    await Promise.all(filesObj.map(async file => {
      if(file.size <= 2000000 && (filesObj.length + files.length) <= 20) {
        const formData = new FormData()
        formData.append("photos", file)
        const imagesRes = await uploadImages(formData, isEdit || savedAdId);
        filesArray.push({
          url: URL.createObjectURL(file),
          key: imagesRes.data.filesInfo[0].Key.split("/")[1]
        });
      } else {
        alert.error('Asegurate que tus imagenes pesen menos de 2mb y sean hasta 20 imagenes');
      }
    }));

    if(!main) {
      uploadMainImage(filesArray[0].key)
      setMain(filesArray[0].key)
    }
    
    setFiles([...files, ...filesArray])
    setLoading(false)
  }

  const uploadFiles = async (e) => {
    try {
      setLoading(true);
      e.preventDefault()
      resetData();
      history.push(`/detalle/${savedAdId}`)
      //changeStep(4);
    } catch (err) {
      console.error(err);
      alert.error('Ocurrió un error, intenta de nuevo más tarde');
    } finally {
      setLoading(false);
    }
  }

  const deleteImage = async (index, isMain) => {
    if (files.length === 1) {
      alert.error('Debes subir una nueva imagen predeterminada primero');
    } else {
      let arrayFiles = files.slice();
      await deleteImages(main, [arrayFiles[index].key], isEdit || savedAdId);
      arrayFiles.splice(index, 1)
      setFiles(arrayFiles)
      if(isMain && arrayFiles.length === 1){
        await uploadMainImage(arrayFiles[0].key);
      }
    }
  };

  if (loading) {
    return (
      <ImagesForm>
        <Load marginTop={150} />
      </ImagesForm>
    );
  }

  return (
    <ImagesForm>
      <ImagesInput type="file" id="file-upload" onChange={uploadMultipleFiles} multiple accept="image/*" maxFileSize="2000"/>
      <ImagesSectionWrapper htmlFor='file-upload'>
        <UploadInfoWrapper>
          Selecciona uno o mas archivos
          <ButtonFiles>Seleccionar archivos</ButtonFiles>
        </UploadInfoWrapper>
      </ImagesSectionWrapper>
      <ImagesWrapper>
        {files.map((image, index) => {
          const isMainImage = image.key === main;
          return(
            <ImagePreviewWrapper key={image.url}>
              <ImagePreview src={image.url} alt="..." />
              <OptionsWrapper>
                <Option
                  onClick={() => {
                    /*if (isEdit) {
                      setEditMain(null);
                    } */
                    uploadMainImage(image.key)
                  }}
                >
                  <OptionIcon
                    isMain={isMainImage}
                    src={star}
                  />
                  {isMainImage ? 'Predeterminada' : 'Elegir como predeterminada'}
                </Option>
                <TwoStepsButton
                  firstStepText="Eliminar"
                  confirmationText="Confirmar"
                  cancelText="Cancelar"
                  icon={trash}
                  onClick={() => deleteImage(index, isMainImage)}
                />
              </OptionsWrapper>
          </ImagePreviewWrapper>
          );
        })}
      </ImagesWrapper>
      <ButtonWrapper>
        <Button
          width='200px'
          secondary
          onClick={() => changeStep(false)}
        >
          Regresar
        </Button>
        <Button
          onClick={uploadFiles}
          width='200px'
          margin='0 0 0 10px'
        >
          Finalizar
        </Button>
      </ButtonWrapper>
    </ImagesForm>
  )
}

const mapDispatchToProps = dispatch => ({
  resetData: propertiesActions.resetData(dispatch)
})

export default connect(null, mapDispatchToProps)(MultiImageUpload)

