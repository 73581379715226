import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../common/utils/constants'

const Check = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`
const Span = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${props => props.checked ? COLORS.darkBlue : "#eee"};
  border-radius: 5px;

  &:after {
    content: "";
    position: absolute;
    display: ${props => props.checked ? 'block' : 'none'};
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const Label = styled.label`
  display: flex;
  height: 25px;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  color: #000;

  &:hover ${Span} {
      background-color: #ccc;
  }
`

const Checkbox = ({ label, onChange = () => {}, value, checked }) => (
      <Label checked={checked}>
          {label}
          <Check
              type="checkbox"
              value={value}
              onChange={() => onChange(!checked)}
          />
          <Span checked={checked}></Span>
      </Label>
  );

export default Checkbox
