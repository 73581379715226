import React from 'react';
import ReactPaginate from 'react-paginate';
import { PaginationWrapper } from './styled';

const PagePagination = ({ totalPages, actualPage, offset, propertyLimit, changePage }) => (
    <PaginationWrapper>
        <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Siguiente'}
            breakLabel={'...'}
            pageCount={totalPages}
            marginPagesDisplayed={1}
            forcePage={actualPage-1}
            pageRangeDisplayed={window.innerWidth < 768 ? 2 : 5}
            onPageChange={(data) => {
                const selected = data.selected + 1;
                let finalOffset;
                if(selected === actualPage) return;
                if(selected < actualPage ){
                    finalOffset = offset - (actualPage - selected) * propertyLimit;
                } else {
                    finalOffset = offset + (selected - actualPage) * propertyLimit;
                }
                changePage(finalOffset, selected);
            }}
            containerClassName={'pagination'}
            activeClassName={'active'}
        />
    </PaginationWrapper>
);

export default PagePagination;