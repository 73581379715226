import React, { useState } from 'react'
import styled from 'styled-components'
import AmenitiesBar from './AmenitiesBar'
import { numberWithCommas, getAddress } from '../common/utils/helpers'
import { COLORS } from '../common/utils/constants';
import FavoriteButton from './favorite-button';
const defaultImage = require('../common/images/defaultLogo.svg')

const handleWidth = size => {
  switch(size){
    case "list":
      return "calc(33% - 15px)"

    default:
      return "100%"
  }
}

const handleHeight = size => {
  switch(size){
    case "mini":
      return "280px"

    case "related":
      return "150px"

    case "list":
      return "150px"

    case "home":
      return "300px"

    case "homePhone":
      return "100%"

    default:
      return "300px"
  }
}

const handlePadding = size => {
  switch(size) {
    case "list":
      return "0 0 15px 15px"
    default:
      return "0"
  }
}

const PropertyWrapper = styled.div`
  width: ${props => handleWidth(props.size)};
  margin: ${props => handlePadding(props.size)};
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.2);
  position: relative;

  @media (max-width: 768px) {
    margin-right: 0;
    width: calc(50% - 15px);
  }

  @media (max-width: 450px) {
    width: 100%;
  }

  &.hovered, &:hover {
    transform: scale(1.02);
  }

  ${props => {
    if (props.size !== 'list') {
      return `
        &:hover{
          box-shadow: 3px 4px 10px -3px rgba(0,0,0,0.75);
          transform: translate3d(-1px, -1px, 20px);
        }
      `;
    }
  }}
`

const PropertyImage = styled.img`
  width: 100%;
  height: ${props => handleHeight(props.size)};
  ${props => !props.hasError && 'object-fit: cover;'};
`

const AddressWrapper = styled.p`
  margin: 0 0 10px 0;

  &.hovered{
    color: ${COLORS.primaryBlue}
  }
  @media (max-width: 768px) {
    max-height: 38px;
    overflow-y: hidden;
  }
`

const PriceWrapper = styled.p`
  color: ${COLORS.darkBlue};
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0;
`

const PropertyInfoWrapper = styled.div`
  padding: 10px 10px;
  @media (max-width: 768px) {
    margin-right: 10px;
    width: 90%;
  }
`

const SponsoredText = styled.div`
  color: white;
  background-color: ${COLORS.primaryOrange};
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  padding: 5px 5px;
  margin-left: 10px;
  width: 75px;
  margin-top: 5px;
`;

const PropertyResume = ({
  precio,
  size,
  image,
  habitaciones,
  banos,
  estacionamientos,
  mascotas,
  areaConstruida,
  calle,
  numeroExterior,
  colonia,
  ciudad,
  fromSponsoredList,
  inmuebleID,
  isFavorite,
  hovered,
  onClick
}) => {
  const direccion = getAddress(calle, numeroExterior, colonia, ciudad);
  const [imgError, setImgError] = useState(false)

  return (
    <PropertyWrapper className={hovered && 'hovered'} size={size}>
      <FavoriteButton
        inmuebleID={inmuebleID}
        defaultProp={isFavorite}
      />
      <PropertyImage
        onError={(e) => {
          e.target.onerror = null
          e.target.src = defaultImage
          setImgError(true)
        }}
        hasError={imgError}
        size={size}
        src={image}
        onClick={onClick}
      />
      {fromSponsoredList && (
        <SponsoredText>
           Patrocinado
        </SponsoredText>
      )}
      <PropertyInfoWrapper onClick={onClick}>
        <AmenitiesBar
          habitaciones={habitaciones}
          banos={banos}
          estacionamientos={estacionamientos}
          mascotas={mascotas}
          areaConstruida={areaConstruida}
        />
        <AddressWrapper className={hovered && 'hovered'}>{direccion}</AddressWrapper>
        <PriceWrapper>${numberWithCommas(precio)} MXN</PriceWrapper>
      </PropertyInfoWrapper>
    </PropertyWrapper>
  )
}

export default PropertyResume
