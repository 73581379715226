import React, { useState } from 'react';
import Select from 'react-select';
import Counter from '../Counter';
import ToggleButton from '../ToggleButton';
import InputField from '../InputField'
import Button from '../Button';
import {
  MainWrapper,
  FieldWrapper,
  TwoFieldWrapper,
  TwoFieldElement,
} from './styled';

const bed = require('../../common/images/bed.svg')
const bath = require('../../common/images/bath.svg')
const toilet = require('../../common/images/toilet.svg')
const pet = require('../../common/images/pet.svg')
const car = require('../../common/images/car.svg')
const areaIcon = require('../../common/images/area.svg')
const clock = require('../../common/images/clock.svg')

const Filters = ({ onSubmit, initialParams, show }) => {
  const defaultValues = {
    propertyType: null,
    bedrooms: 1,
    bathrooms: 1,
    halfBathrooms: 0,
    parkingLots: 0,
    pets: false,
    age: '',
    area: '',
  }

  const [state, setState] = useState({
    propertyType: initialParams.propertyType || defaultValues.propertyType,
    bedrooms: initialParams.bedrooms || defaultValues.bedrooms,
    bathrooms: initialParams.bathrooms || defaultValues.bathrooms,
    halfBathrooms: initialParams.halfBathrooms || defaultValues.halfBathrooms,
    parkingLots: initialParams.parkinLots || defaultValues.parkingLots,
    pets: initialParams.pets && true,
    age: initialParams.age || defaultValues.age,
    area: initialParams.area || defaultValues.area
  })

  const options = [
    { value: 1, label: 'Casa' },
    { value: 2, label: 'Departamento' },
  ]

  return (
    <MainWrapper className={show && 'show'}>
      <FieldWrapper>
        <Select
          placeholder='Tipo de inmueble'
          onChange={(value) => setState({...state, propertyType: value.value})}
          options={options}
          value={options[state.propertyType - 1] || ''}
          styles={{
            control: (provided) => ({
              ...provided,
              border: '1px solid #000',
              borderRadius: '0',
              fontSize: '14px'
            }),
            input: (provided) => ({
              ...provided,
              fontSize: '14px',
              fontFamily: 'Montserrat, sans-serif'
            }),
          }}
        />
      </FieldWrapper>
      <TwoFieldWrapper>
        <TwoFieldElement>
          <InputField
            icon={clock}
            suffix='años'
            value={state.age}
            onChange={(value) => setState({...state, age: value})}
            placeholder='Antigüedad'
          />
        </TwoFieldElement>
        <TwoFieldElement>
          <InputField
            icon={areaIcon}
            suffix='m2'
            value={state.area}
            onChange={(value) => setState({...state, area: value})}
            placeholder='Área construida'
          />
        </TwoFieldElement>
      </TwoFieldWrapper>
      <FieldWrapper>
        <Counter
          onChange={(value) => setState({...state, bedrooms: value})}
          label='Recámaras'
          icon={bed}
          valueProp={state.bedrooms}
          counterClass='filter'
        />
      </FieldWrapper>
      <FieldWrapper>
        <Counter
          onChange={(value) => setState({...state, bathrooms: value})}
          label='Baños'
          icon={bath}
          valueProp={state.bathrooms}
          counterClass='filter'
        />
      </FieldWrapper>
      <FieldWrapper>
        <Counter
          onChange={(value) => setState({...state, halfBathrooms: value})}
          label='Medios Baños'
          icon={toilet}
          valueProp={state.halfBathrooms}
          counterClass='filter'
        />
      </FieldWrapper>
      <FieldWrapper>
        <Counter
          onChange={(value) => setState({...state, parkingLots: value})}
          label='Estacionamiento'
          icon={car}
          valueProp={state.parkingLots}
          counterClass='filter'
        />
      </FieldWrapper>
      <FieldWrapper>
        <ToggleButton
          onChange={(value) => setState({...state, pets: value})}
          label={'Mascotas'}
          icon={pet}
          valueProp={state.pets}
          toggleClass='filter'
        />
      </FieldWrapper>
      <TwoFieldWrapper>
        <TwoFieldElement>
          <Button
            secondary
            onClick={() => {
              onSubmit(null, 1, 1, 0, 1, null, null, null);
              setState(defaultValues);
            }}
          >
            Borrar filtros
          </Button>
        </TwoFieldElement>
        <TwoFieldElement>
          <Button
            onClick={() => onSubmit(state.propertyType, state.bedrooms, state.bathrooms, state.halfBathrooms, state.parkingLots, state.pets, state.area, state.age)}
          >
            Aplicar
          </Button>
        </TwoFieldElement>
      </TwoFieldWrapper>
    </MainWrapper>
  );
};

export default Filters;
