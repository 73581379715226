import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import {
    MainWrapper,
    CardWrapper,
    CardInfo,
    RadioButton,
    CardImage,
    CardSelectWrapper,
    ShadowBox,
    FullscreenOverlay,
} from './styled';
import Loader from '../loader';
import CardForm from '../card-form';
import visa from '../../common/images/visa.png';
import master from '../../common/images/master.png';
import carnet from '../../common/images/carnet.png';
import american from '../../common/images/american.png';
import orangeCard from '../../common/images/orangeCard.png';
import {
    getUserCards
} from '../../common/utils/api-helpers';

const CardSelect = ({ card, setCard }) => {
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState([]);
    const [fullScreenModal, setFullScreenModal] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        async function getCards() {
            try{
                setLoading(true);
                const cards = await getUserCards();
                setCards(cards.data.result);
            } catch (err) {
                alert.error('Ocurrió un error, intenta de nuevo más tarde');
            } finally {
                setLoading(false);
            }
        }
        if (!fullScreenModal) {
            getCards();
        }
    }, [fullScreenModal, alert]);

    const getCardLogo = (marca) => {
        switch (marca) {
        case 'visa':
            return visa;
        case 'mastercard':
            return master;
        case 'carnet':
            return carnet;
        case 'card':
            return orangeCard;
        default:
            return american;
        }
    };

    return(
        <MainWrapper>
            {fullScreenModal && (
                <>
                <FullscreenOverlay onClick={() => setFullScreenModal(false)}/>
                  <CardForm
                    closeModal={() => setFullScreenModal(false)}
                  />
              </>
            )}
            <ShadowBox>
                <CardWrapper onClick={() => setFullScreenModal(true)}>
                    <CardInfo>
                        {'Agregar una nueva tarjeta'}
                    </CardInfo>
                    <CardImage src={getCardLogo('card')}/>
                </CardWrapper>
           </ShadowBox>
           <ShadowBox withMinHeight loadingProp={loading}>
               {!loading && cards.length > 0 && cards.map(cardList => (
                    <CardWrapper 
                        onClick={() => setCard({ tarjetaID: cardList.tarjetaID})}
                        key={cardList.tarjetaID}
                    >
                            <CardInfo>
                                {`xxxx xxxx xxxx ${cardList.terminacion}`}
                            </CardInfo>
                            <CardSelectWrapper>
                                <CardImage src={getCardLogo(cardList.marca)}/>
                                <RadioButton
                                    type="radio"
                                    id={cardList.tarjetaID}
                                    name="card"
                                    value={cardList.tarjetaID}
                                    checked={cardList.tarjetaID === card.tarjetaID}
                                    onChange={() => {}}
                                />
                            </CardSelectWrapper>
                    </CardWrapper>
               ))}
               {!loading && cards.length === 0 && (
                   <CardWrapper>
                        <CardInfo>
                            {'No tienes tarjetas registradas'}
                        </CardInfo>
                    </CardWrapper>
               )}
               {loading && (
                   <Loader />
               )}
            </ShadowBox>
        </MainWrapper>
    )
};

export default CardSelect;